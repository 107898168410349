
<div>
  <transition name="fade" mode="out-in">
    <div v-if="brandLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ brand.name }}</h5>
        <router-link v-if="$can('update', 'Brand')" :to="{ name: 'edit_brand', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <b-tabs content-class="mt-3">
        <!-- Infos tab -->
        <b-tab title="Infos" active>
          <table class="table">
            <tr>
              <th class="table-col-shrink">{{ $t('attributes.brand.id') }}</th>
              <td>{{ brand.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.name') }}</th>
              <td>{{ brand.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.display_name') }}</th>
              <td>{{ brand.displayName }}</td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.region') }}</th>
              <td>
                <region-flag :code="brand.region.code" />
                {{ regionName(brand.region.code) }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.parent') }}</th>
              <td>
                <template v-if="brand.parent">
                  <router-link :to="{ name: 'brand', params: { id: brand.parent.id } }">
                    <region-flag v-if="brand.parent.region.code === 'INTERNATIONAL'" :code="brand.parent.region.code" />
                    {{ brand.parent.name }}
                  </router-link>
                </template>
                <template v-else>-</template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.children') }}</th>
              <td>
                <template v-if="brand.childrenCount">
                  {{ brand.childrenCount}}
                </template>
                <template v-else>-</template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.active') }}</th>
              <td>
                <dot :active="brand.active" />
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.is_brand') }}</th>
              <td>
                <dot :active="brand.isBrand" />
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.prospect') }}</th>
              <td>
                <dot :active="brand.prospect" />
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.img') }}</th>
              <td>
                <template v-if="brand.img.url">
                  <img :src="brand.img.url" class="border" />
                </template>
                <template v-else>-</template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.brand.tags') }}</th>
              <td>
                <template v-if="brand.tags.length > 0">
                  <ul class="list-unstyled">
                    <li v-for="tag in brand.tags" :key="tag.id">
                      <router-link :to="{ name: 'tag', params: { id: tag.id } }">
                        {{ getTagName(tag) }}
                      </router-link>
                    </li>
                  </ul>
                </template>
                <template v-else>-</template>
              </td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.brand.amazon_tracking_id') }}</th>
              <td><code>{{ brand.amazonTrackingId }}</code></td>
            </tr>
          </table>
        </b-tab>

        <!-- Tree view tab -->
        <b-tab title="Tree view" @click.once="brandTreeInitialized = true">
          <tree
            v-if="brandTreeInitialized"
            :node="brand.rootBrand"
            class="tree"
            :load-children="handleOpenTreeNode"
          >
            <template v-slot:default="{ node }">
              <router-link :to="{ name: 'brand', params: { id: node.id } }" :class="{ 'font-weight-bold': node.id == id }">
                <dot :active="node.active" />
                <region-flag v-if="internationalRoot" :code="node.region.code" />
                {{ node.name }}
              </router-link>
            </template>
          </tree>
        </b-tab>

        <!-- Tags view -->
        <b-tab title="Tags view">
          <div class="table-responsive">
            <table class="table" :aria-busy="tagsLoading">
              <thead>
                <tr>
                  <th style="width: 300px;">{{ $t('attributes.brand.tags') }}</th>
                  <th> {{ $t('attributes.tag.brands') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tag in tags" :key="tag.id">
                  <td>
                    <router-link :to="{ name: 'tag', params: { id: tag.id } }">
                      {{ getTagName(tag) }}
                    </router-link>
                  </td>
                  <td>
                    <a @click="toggleTagBrands(tag.id)" class="btn btn-light">
                      <div class="gutters-xs">
                        {{ tag.brands.length }}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa icon-will-rotate fa-w-6" :class="{ 'icon-rotate': expandBrands[tag.id] }" fill="currentColor"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>
                      </div>
                    </a>
                    <transition-expand>
                      <ul v-if="expandBrands[tag.id]" class="list-unstyled mt-2 mb-0">
                        <li v-for="brand in tag.brands" :key="brand.id">
                          <router-link :to="{ name: 'brand', params: { id: brand.id } }">
                            <region-flag :code="brand.region.code" />
                            {{ brand.name }}
                          </router-link>
                        </li>
                      </ul>
                    </transition-expand>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <!-- History tab -->
        <b-tab title="History">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.version.event') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.item') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.id') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.name') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.changeset') }}</th>
                  <th>{{ $t('attributes.version.description') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.author') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.createdAt') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="brand.versions.length === 0">
                  <td colspan="9" class="text-center alert-warning">
                    {{ $t('shared.warnings.no_version') }}
                  </td>
                </tr>
                <tr v-else v-for="version in brand.versions" :key="version.id">
                  <td>
                    <span class="badge" :class="eventBadgeClass(version.event)">{{ version.event }}</span>
                  </td>
                  <td>{{ version.itemType }}</td>
                  <td class="text-nowrap">
                    {{ version.itemId }}
                  </td>
                  <td class="text-nowrap">
                    <template v-if="version.item">
                      {{ version.item.name }}
                    </template>
                  </td>
                  <td>
                    <template v-if="version.event === 'update'">
                      <ul class="list-inline text-nowrap mb-0">
                        <li
                          v-for="(values, key) in version.changeset"
                          :key="`${version.id}-${key}`"
                          v-b-popover.hover.bottom="`${values[0]} → ${values[1]}`"
                          class="list-inline-item">
                          <code>{{ key }}</code>
                        </li>
                      </ul>
                    </template>
                  </td>
                  <td class="text-nowrap">{{ version.description }}</td>
                  <td class="text-nowrap">
                    <router-link v-if="version.author" :to="{ name: 'user', params: { id: version.author.id } }">
                      {{ version.author.email }}
                    </router-link>
                  </td>
                  <td><small class="text-nowrap">{{ version.createdAt }}</small></td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </transition>
</div>
