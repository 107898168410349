<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="tagLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else>
        <form @submit.prevent="submit">
          <div class="form-group row">
            <label
              for="inputKey"
              class="col-sm-2 col-form-label"
            >{{ $t('attributes.tag.key') }}</label>
            <div class="col-lg-4 col-sm-4 col-12">
              <input
                v-model="tag.key"
                type="text"
                class="form-control"
                :class="{ 'is-invalid' : tagSubmitError && tagSubmitError.key }"
                id="inputKey"
                :placeholder="$t('attributes.tag.key')"
              />
              <div
                v-if="tagSubmitError && tagSubmitError.key"
                class="invalid-feedback"
              >{{ tErrors('tag', 'key', tagSubmitError.key) }}</div>
            </div>
          </div>

          <!-- Tag language datas -->
          <div class="form-group row" v-for="(languageDataAttributes, index) in tag.tagLanguageDatasAttributes" :key="languageDataAttributes.tagLanguage.id">
            <label :for="`input-name-${languageDataAttributes.tagLanguage.code}`" class="col-sm-2 col-form-label">
              <div class="row">
                <div class="col-6">
                  <template v-if="index === 0">
                    {{ $t('attributes.tag.name') }}
                  </template>
                </div>
                <div class="col-6">
                  <span class="badge badge-light">{{ languageDataAttributes.tagLanguage.code }}</span>
                </div>
              </div>
            </label>
            <div class="col-lg-4 col-sm-4 col-12">
              <input
                v-model="languageDataAttributes.name"
                type="text"
                class="form-control"
                :id="`input-name-${languageDataAttributes.tagLanguage.code}`"
                :placeholder="`${$t('attributes.tag.name')} ${languageDataAttributes.tagLanguage.code}`"
                :class="{ 'is-invalid' : tagSubmitError && tagSubmitError['tagLanguageDatas.name'] }"
              />
                <div v-if="tagSubmitError && tagSubmitError['tagLanguageDatas.name']" class="invalid-feedback">{{ tErrors('tag', 'language_data', tagSubmitError['tagLanguageDatas.name']) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label
              for="multiselect-brands"
              class="col-sm-2 col-form-label"
            >{{ $t('attributes.tag.brands') }}</label>
            <div class="col-lg-4 col-sm-4 col-12">
              <key-multiselect
                v-model="tag.brandIds"
                :options="brands"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                label="name"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false"
                :options-limit="10000"
                :loading="brandsLoading"
                id="multiselect-brands"
              >
                <template v-slot:option="{ option }">
                  <region-flag :code="option.region.code" />
                  {{ option.name }}
                </template>
                <template slot="selection" slot-scope="{ values }">
                  <span v-if="values.length">{{ $tc('multiselect.selection', values.length) }}</span>
                </template>
              </key-multiselect>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="tagSubmitLoading">
              <md-spinner
                v-if="tagSubmitLoading"
                md-indeterminate
                :diameter="20"
                :stroke-width="5"
                class="btn-spinner"
              />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { MdSpinner, KeyMultiselect, RegionFlag },
  props: ['action'],
  data: function() {
    return {
      tag: {
        tagLanguageDatasAttributes: [],
        key: null,
        brandIds: []
      },
      tagLoading: false,
      tagError: null,
      tagLanguages: [],
      tagLanguagesLoading: false,
      tagLanguagesError: null,
      brands: [],
      brandsLoading: false,
      brandsError: null,
      tagSubmitLoading: false,
      tagSubmitError: null
    }
  },
  methods: {
    // Load data
    loadData: async function({ tag = true, tagLanguages = true } = {}) {
      this.brandsLoading = true
      this.brandsError = null

      if (tag) {
        this.tagLoading = true
        this.tagError = null
      }

      if (tagLanguages) {
        this.tagLanguagesLoading = true
        this.tagLanguagesError = null
      }

      const query = gql`
        query tagForm($tag: Boolean = true, $tagId: Int = null, $tagLanguages: Boolean = true) {
          tag(id: $tagId) @include(if: $tag) {
            key
            brands {
              id
              name
              region {
                id
                code
              }
            }
            tagLanguageDatas {
              id
              name
              tagLanguage {
                id
                code
              }
            }
          }
          brands {
            id
            name
            region {
              id
              code
            }
          }
          tagLanguages @include(if: $tagLanguages) {
            id
            code
          }
        }
      `

      const variables = {
        tag,
        tagId: tag ? parseInt(this.$route.params.id) : undefined,
        tagLanguages
      }

      try {
        const { data } = await client.query({ query, variables })

        this.brands = Object.freeze(data.brands)

        if (tag) {
          this.tag.key = data.tag.key
          this.tag.brandIds = data.tag.brands.map(brand => brand.id)
          this.tag.tagLanguageDatasAttributes = data.tag.tagLanguageDatas.map(tagLanguageData => {
            return {
              id: tagLanguageData.id,
              name: tagLanguageData.name,
              tagLanguage: tagLanguageData.tagLanguage
            }
          })
        }

        if (tagLanguages) {
          data.tagLanguages.forEach(tagLanguage => {
            this.tag.tagLanguageDatasAttributes.push({
              tagLanguage,
              name: null
            })
          })
        }
      } catch (error) {
        this.tagError = this.brandsError = this.tagLanguagesError = error
      } finally {
        this.brandsLoading = false
        this.tagLoading = false
        this.tagLanguagesLoading = false
      }
    },
    submit: async function() {
      this.tagSubmitLoading = true
      this.tagSubmitError = null

      const variables = {
        input: {
          attributes: {
            key: this.tag.key,
            brandIds: this.tag.brandIds,
            tagLanguageDatasAttributes: this.tag.tagLanguageDatasAttributes.map(tagLanguageDataAttribute => {
              return {
                id: tagLanguageDataAttribute.id,
                name: tagLanguageDataAttribute.name,
                tagLanguageId: tagLanguageDataAttribute.tagLanguage.id
              }
            })
          }
        }
      }

      if (this.action === 'new') {
        const mutation = gql`
          mutation($input: CreateTagInput!) {
            createTag(input: $input) {
              tag {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.createTag.errors) {
            this.tagSubmitError = data.createTag.errors
          } else {
            this.$router.push({ name: 'tag', params: { id: data.createTag.tag.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.tag.create'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.tagSubmitError = error
          throw error
        } finally {
          this.tagSubmitLoading = false
        }
      } else if (this.action === 'edit') {
        variables.input.id = this.$route.params.id

        const mutation = gql`
          mutation($input: UpdateTagInput!) {
            updateTag(input: $input) {
              tag {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.updateTag.errors) {
            this.tagSubmitError = data.updateTag.errors
          } else {
            this.$router.push({ name: 'tag', params: { id: this.$route.params.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.tag.update'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.tagSubmitError = error
          throw error
        } finally {
          this.tagSubmitLoading = false
        }
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData({ tagLanguages: false })
    } else {
      this.loadData({ tag: false })
    }
  }
}
</script>
