<template>
  <div>
    <div class="d-flex flex-wrap justify-content-end align-items-center gutters mb-4">
      <router-link :to="{ name: 'new_validation_data_source' }" class="btn btn-success">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
        {{ $t('shared.actions.new_validation_data_source') }}
      </router-link>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="validationDataSourcesLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="validationDataSourcesLoading">
          <thead>
            <tr>
              <th class="table-col-shrink">{{ $t('attributes.validation_data_source.id') }}</th>
              <th>{{ $t('attributes.validation_data_source.name') }}</th>
              <th class="table-col-shrink text-nowrap">{{ $t('attributes.validation_data_source.retailers_count') }}</th>
              <th class="table-col-shrink text-nowrap">{{ $t('attributes.validation_data_source.validation_jobs_count') }}</th>
              <th class="table-col-shrink text-nowrap text-center">{{ $t('attributes.validation_data_source.last_job_date') }}</th>
              <th class="table-col-shrink text-nowrap">{{ $t('attributes.validation_data_source.last_job_status') }}</th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="validationDataSourcesError" key="1">
              <tr>
                <td colspan="7" class="text-center alert-danger">
                  <template v-if="validationDataSourcesError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internal_server_error') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else key="2">
              <tr v-if="!validationDataSourcesLoading && validationDataSources.length === 0">
                <td colspan="7" class="text-center alert-warning">
                  {{ $t('shared.warnings.no_validation_data_sources') }}
                </td>
              </tr>
              <template v-else v-for="validationDataSource in validationDataSources">
                <tr :key="validationDataSource.id">
                  <th>{{ validationDataSource.id }}</th>
                  <td>{{ validationDataSource.name }}</td>
                  <td class="text-center">{{ validationDataSource.retailersCount }}</td>
                  <td class="text-center">{{ validationDataSource.validationJobsCount }}</td>
                  <td class="text-center text-nowrap">
                    <small>{{ new Date(validationDataSource.lastJobDate).toLocaleString() }}</small>
                  </td>
                  <td class="text-center">
                    <dot :active="validationDataSource.lastJobStatus" />
                  </td>
                  <td>
                    <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                      <template v-slot:button-content>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-w-14" fill="currentColor"><path d="M64 360C94.93 360 120 385.1 120 416C120 446.9 94.93 472 64 472C33.07 472 8 446.9 8 416C8 385.1 33.07 360 64 360zM64 200C94.93 200 120 225.1 120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200zM64 152C33.07 152 8 126.9 8 96C8 65.07 33.07 40 64 40C94.93 40 120 65.07 120 96C120 126.9 94.93 152 64 152z"/></svg>
                      </template>
                      <router-link :to="{ name: 'validation_data_source', params: { id: validationDataSource.id } }" class="dropdown-item">
                        {{ $t('shared.actions.show') }}
                      </router-link>
                      <router-link v-if="$can('update', 'RetailerDataSource')" :to="{ name: 'edit_validation_data_source', params: { id: validationDataSource.id } }" class="dropdown-item">
                        {{ $t('shared.actions.edit') }}
                      </router-link>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
            </tbody>
          </transition>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import { BDropdown } from 'bootstrap-vue'
import Dot from '../shared/Dot.vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { MdSpinner, BDropdown, Dot },
  data: function() {
    return {
      validationDataSources: [],
      validationDataSourcesLoading: false,
      validationDataSourcesError: null,
      showDetails: {}
    }
  },
  methods: {
    // Load validation data sources
    loadData: async function() {
      this.validationDataSourcesLoading = true
      this.validationDataSourcesError = null

      const query = gql`
        query {
          validationDataSources {
            id
            name
            lastJobDate
            lastJobStatus
            retailersCount
            validationJobsCount
          }
        }
      `

      try {
        const { data } = await client.query({ query })

        this.validationDataSources = Object.freeze(data.validationDataSources)
      } catch (error) {
        this.validationDataSourcesError = error
        throw error
      } finally {
        this.validationDataSourcesLoading = false
      }
    },
    toggleDetails: function(date) {
      const showDetails = {
        ...this.showDetails
      }
      showDetails[date] = !showDetails[date]
      this.showDetails = showDetails
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
