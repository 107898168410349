
<div>
  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="regionsLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="regionsLoading">
        <thead>
          <tr>
            <th class="table-col-shrink">{{ $t('attributes.region.id') }}</th>
            <th class="text-center">{{ $t('attributes.region.code') }}</th>
            <th class="text-center">{{ $t('attributes.region.languages') }}</th>
            <th class="text-center">{{ $t('attributes.region.flag') }}</th>
            <th class="text-center">{{ $t('attributes.region.translation') }}</th>
            <th class="text-center">{{ $t('attributes.region.currency') }}</th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="regionsError" key="1">
            <tr>
              <td colspan="6" class="text-center alert-danger">
                <template v-if="regionsError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internal_server_error') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else key="2">
            <tr v-if="!regionsLoading && regions.length === 0">
              <td colspan="6" class="text-center alert-warning">
                {{ $t('shared.warnings.no_region') }}
              </td>
            </tr>
            <tr v-else v-for="region in regions" :key="region.id">
              <th>{{ region.id }}</th>
              <td class="text-center">{{ region.code }}</td>
              <td class="text-center">{{ region.languages.map(language => language.code).join(', ') }}</td>
              <td class="text-center"><region-flag :code="region.code" /></td>
              <td class="text-center">{{ regionName(region.code) }}</td>
              <td class="text-center">{{ currency(region.code) }}</td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>
</div>
