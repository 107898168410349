import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'

import Login from './views/devise/Login.vue'
import NewPassword from './views/devise/NewPassword.vue'
import EditPassword from './views/devise/EditPassword.vue'
import Layout from './views/layouts/Layout.vue'
import HomeIndex from './views/home/index.vue'
import UsersIndex from './views/users/index.vue'
import UsersShow from './views/users/show.vue'
import UsersForm from './views/users/form.vue'
import BrandsIndex from './views/brands/index.vue'
import BrandsShow from './views/brands/show.vue'
import BrandsForm from './views/brands/form.vue'
import RetailersIndex from './views/retailers/index.vue'
import RetailersShow from './views/retailers/show.vue'
import RetailersForm from './views/retailers/form.vue'
import RetailersTagMonitoring from './views/retailers/TagMonitoring.vue'
import RetailerDataSourcesForm from './views/retailer_data_sources/form.vue'
import ConsolidationRetailOutletDataSourcesForm from './views/consolidation_retail_outlet_data_sources/form.vue'
import RetailerContactsForm from './views/retailer_contacts/form.vue'
import RetailerReportDeliveriesIndex from './views/retailer_report_deliveries/index.vue'
import ValidationDataSourcesIndex from './views/validation_data_sources/index.vue'
import ValidationDataSourcesShow from './views/validation_data_sources/show.vue'
import ValidationDataSourcesForm from './views/validation_data_sources/form.vue'
import RegionsIndex from './views/regions/index.vue'
import TagsIndex from './views/tags/index.vue'
import TagsShow from './views/tags/show.vue'
import TagsForm from './views/tags/form.vue'
import AppsIndex from './views/apps/index.vue'
import AppsForm from './views/apps/form.vue'
import AppsModelShow from './views/apps/model_show.vue'
import VersionsIndex from './views/versions/index.vue'

import store from './store'
import i18n from './i18n'
import { ability } from './store/ability'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    { path: '/login', name: 'login', component: Login, meta: { requireNoAuthentication: true } },
    { path: '/auth/password/new', name: 'new_password', component: NewPassword, meta: { requireNoAuthentication: true } },
    { path: '/auth/password/edit', name: 'edit_password', component: EditPassword, meta: { requireNoAuthentication: true } },
    {
      path: '',
      component: Layout,
      children: [
        { path: '/', component: HomeIndex, meta: { title: i18n.t('title.home') } },
        { path: '/brands', name: 'brands', component: BrandsIndex, meta: { title: i18n.t('title.brands.index'), resource: 'Brand' } },
        { path: '/brands/new', name: 'new_brand', component: BrandsForm, props: { action: 'new' }, meta: { title: i18n.t('title.brands.new'), resource: 'Brand', action: 'create' } },
        { path: '/brands/:id', name: 'brand', component: BrandsShow, props: true, meta: { title: i18n.t('title.brands.show'), resource: 'Brand' } },
        { path: '/brands/:id/edit', name: 'edit_brand', component: BrandsForm, props: { action: 'edit' }, meta: { title: i18n.t('title.brands.edit'), resource: 'Brand' } },
        { path: '/retailers', name: 'retailers', component: RetailersIndex, meta: { title: i18n.t('title.retailers.index'), resource: 'Retailer' } },
        { path: '/retailers/new', name: 'new_retailer', component: RetailersForm, props: { action: 'new' }, meta: { title: i18n.t('title.retailers.new'), resource: 'Retailer', action: 'create' } },
        { path: '/retailers_tag_monitoring', name: 'retailers_tag_monitoring', component: RetailersTagMonitoring, meta: { title: i18n.t('title.retailers.tagMonitoring'), resource: 'Retailer' } },
        { path: '/retailers/:id', name: 'retailer', component: RetailersShow, props: true, meta: { title: i18n.t('title.retailers.show'), resource: 'Retailer' } },
        { path: '/retailers/:id/edit', name: 'edit_retailer', component: RetailersForm, props: { action: 'edit' }, meta: { title: i18n.t('title.retailers.edit'), resource: 'Retailer' } },
        { path: '/retailers/:id/consolidation_retail_outlet_data_sources/new', name: 'new_consolidation_retail_outlet_data_source', component: ConsolidationRetailOutletDataSourcesForm, props: { action: 'new' }, meta: { title: i18n.t('title.consolidationRetailOutletDataSources.new'), resource: 'ConsolidationRetailOutletDataSource' } },
        { path: '/retailers/:id/consolidation_retail_outlet_data_sources/:consolidation_retail_outlet_data_source_id/edit', name: 'edit_consolidation_retail_outlet_data_source', component: ConsolidationRetailOutletDataSourcesForm, props: { action: 'edit' }, meta: { title: i18n.t('title.consolidationRetailOutletDataSources.edit'), resource: 'ConsolidationRetailOutletDataSource' } },
        { path: '/retailers/:id/retailer_data_sources/new', name: 'new_retailer_data_source', component: RetailerDataSourcesForm, props: { action: 'new' }, meta: { title: i18n.t('title.retailerDataSources.new'), resource: 'RetailerDataSource' } },
        { path: '/retailers/:id/retailer_data_sources/:retailer_data_source_id/edit', name: 'edit_retailer_data_source', component: RetailerDataSourcesForm, props: { action: 'edit' }, meta: { title: i18n.t('title.retailerDataSources.edit'), resource: 'RetailerDataSource' } },
        { path: '/retailers/:id/retailer_contacts/new', name: 'new_retailer_contact', component: RetailerContactsForm, props: { action: 'new' }, meta: { title: i18n.t('title.retailerContacts.new'), resource: 'RetailerContact', action: 'create' } },
        { path: '/retailers/:id/retailer_contacts/:retailer_contact_id/edit', name: 'edit_retailer_contact', component: RetailerContactsForm, props: { action: 'edit' }, meta: { title: i18n.t('title.retailerContacts.edit'), resource: 'RetailerContact' } },
        { path: '/retailer_report_deliveries', name: 'retailer_report_deliveries', component: RetailerReportDeliveriesIndex, meta: { title: i18n.t('title.retailerReportDeliveries.index'), resource: 'RetailerReportDelivery' } },
        { path: '/validation_data_sources', name: 'validation_data_sources', component: ValidationDataSourcesIndex, meta: { title: i18n.t('title.validationDataSources.index'), resource: 'ValidationDataSource' } },
        { path: '/validation_data_sources/new', name: 'new_validation_data_source', component: ValidationDataSourcesForm, props: { action: 'new' }, meta: { title: i18n.t('title.validationDataSources.new'), resource: 'ValidationDataSource', action: 'create' } },
        { path: '/validation_data_sources/:id', name: 'validation_data_source', component: ValidationDataSourcesShow, props: true, meta: { title: i18n.t('title.validationDataSources.show'), resource: 'ValidationDataSource' } },
        { path: '/validation_data_sources/:id/edit', name: 'edit_validation_data_source', component: ValidationDataSourcesForm, props: { action: 'edit' }, meta: { title: i18n.t('title.validationDataSources.edit'), resource: 'ValidationDataSource' } },
        { path: '/regions', name: 'regions', component: RegionsIndex, meta: { title: i18n.t('title.regions.index'), resource: 'Region' } },
        { path: '/tags', name: 'tags', component: TagsIndex, meta: { title: i18n.t('title.tags.index'), resource: 'Tag' } },
        { path: '/tags/new', name: 'new_tag', component: TagsForm, props: { action: 'new' }, meta: { title: i18n.t('title.tags.new'), resource: 'Tag', action: 'create' } },
        { path: '/tags/:id', name: 'tag', component: TagsShow, props: true, meta: { title: i18n.t('title.tags.show'), resource: 'Tag' } },
        { path: '/tags/:id/edit', name: 'edit_tag', component: TagsForm, props: { action: 'edit' }, meta: { title: i18n.t('title.tags.edit'), resource: 'Tag' } },
        { path: '/apps', name: 'apps', component: AppsIndex, meta: { title: i18n.t('title.apps.index'), resource: 'App' } },
        { path: '/apps/:id/edit', name: 'edit_app', component: AppsForm, props: { action: 'edit' }, meta: { title: i18n.t('title.apps.edit'), resource: 'App' } },
        { path: '/apps/:id/models/:model', name: 'show_app_model', component: AppsModelShow, meta: { title: i18n.t('title.apps.show_model'), resource: 'App' } },
        { path: '/versions', name: 'versions', component: VersionsIndex, meta: { title: i18n.t('title.home') } },
        { path: '/users', name: 'users', component: UsersIndex, meta: { title: i18n.t('title.users.index'), resource: 'User' } },
        { path: '/users/new', name: 'new_user', component: UsersForm, props: { action: 'new' }, meta: { title: i18n.t('title.users.new'), resource: 'User', action: 'create' } },
        { path: '/users/edit', name: 'edit_user_password', component: UsersForm, props: { action: 'edit_password' }, meta: { title: i18n.t('title.users.editPassword'), resource: 'User', action: 'edit_password' } },
        { path: '/users/:id', name: 'user', component: UsersShow, meta: { title: i18n.t('title.users.show'), resource: 'User' } },
        { path: '/users/:id/edit', name: 'edit_user', component: UsersForm, props: { action: 'edit' }, meta: { title: i18n.t('title.users.edit'), resource: 'User', action: 'update' } }
      ]
    },
    { path: '*', redirect: '/' }
  ]
})

// Navigation guards

// Check if user is logged in, otherwise redirect to login page
const authenticateUser = async (to, from, next) => {
  if (!store.getters['auth/user']) {
    await store.dispatch('auth/authenticate')
    if (!store.getters['auth/userError']) {
      next()
    } else {
      new Vue().$bvToast.toast(i18n.t('devise.failure.unauthenticated'), {
        variant: 'danger',
        noCloseButton: true,
        autoHideDelay: 3000
      })

      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
}

// Check if user can access ressource
const authorizeUser = (to, from, next) => {
  if (to.path !== '/' && to.meta.resource) {
    const canNavigate = to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

    if (!canNavigate) {
      new Vue().$bvToast.toast(i18n.t('errors.unauthorized.manage.all'), {
        variant: 'danger',
        noCloseButton: true,
        autoHideDelay: 3000
      })

      // If coming from nowhere, redirect to homepage, otherwise stay on previous page
      if (from.path === '/') {
        next('/')
      } else {
        next(false)
      }
    } else {
      next()
    }
  } else {
    next()
  }
}

// Check if user is not logged in, otherwise redirect to homepage
const requireNoAuthentication = async (to, from, next) => {
  if (store.getters['auth/user']) {
    next({ path: '/' })
  } else if (store.getters['auth/userError']) {
    next()
  } else {
    await store.dispatch('auth/authenticate')
    if (!store.getters['auth/userError']) {
      next({ path: '/' })
    } else {
      next()
    }
  }
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireNoAuthentication)) {
    return requireNoAuthentication(to, from, next)
  } else {
    return multiguard([authenticateUser, authorizeUser])(to, from, next)
  }
})

export default router
