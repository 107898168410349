
<div>
  <transition name="fade" mode="out-in">
    <div v-if="validationDataSourceLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">

      <form @submit.prevent="submit">

        <div class="form-group row">
          <label for="inputName" class="col-sm-2 col-form-label">{{ $t('attributes.validation_data_source.name') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="validationDataSource.name" type="text" class="form-control" :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError.name }" id="inputName" :placeholder="$t('attributes.validation_data_source.name')">
            <div v-if="validationDataSourceSubmitError &&  validationDataSourceSubmitError.name" class="invalid-feedback">{{ tErrors('validation_data_source', 'name', validationDataSourceSubmitError.name) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputName" class="col-sm-2 col-form-label">{{ $t('attributes.validation_data_source.script_filename') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="validationDataSource.scriptFilename" type="text" class="form-control" :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError.scriptFilename }" id="inputName" :placeholder="$t('attributes.validation_data_source.script_filename')">
            <div v-if="validationDataSourceSubmitError && validationDataSourceSubmitError.scriptFilename" class="invalid-feedback">{{ tErrors('validation_data_source', 'script_filename', validationDataSourceSubmitError.scriptFilename) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputName" class="col-sm-2 col-form-label">{{ $t('attributes.validation_data_source.retailer_field') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="validationDataSource.retailerField" type="text" class="form-control" :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError.retailerField }" id="inputName" :placeholder="$t('attributes.validation_data_source.retailer_field')">
            <div v-if="validationDataSourceSubmitError && validationDataSourceSubmitError.retailerField" class="invalid-feedback">{{ tErrors('validation_data_source', 'retailer_field', validationDataSourceSubmitError.retailerField) }}</div>
          </div>
        </div>

        <hr>

        <h5 class="mb-3">Retailer mappings</h5>

        <div class="mb-2">
          <div v-if="validationDataSource.validationDataSourceRetailerMappings.length === 0" class="mb-2">
            No retailer values were defined yet.
          </div>
          <div v-else class="mb-2">
            <ul class="list-unstyled">
              <li v-for="(retailerMapping, index) in validationDataSource.validationDataSourceRetailerMappings" :key="index">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <key-multiselect
                      v-model="retailerMapping.retailerId"
                      :options="retailerOptions"
                      :disabled="retailerMapping._destroy"
                      label="label"
                      track-by="id"
                      :placeholder="$t('shared.placeholders.select')"
                      :show-labels="false"
                      :loading="retailersLoading"
                      :options-limit="20"
                      :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerId'] }"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <region-flag :code="option.region.code" />
                        {{ option.name }}
                        <span class="badge badge-light">{{ option.service }}</span>
                      </template>
                      <template slot="option" slot-scope="{ option }">
                        <region-flag :code="option.region.code" />
                        {{ option.name }}
                        <span class="badge badge-light">{{ option.service }}</span>
                      </template>
                    </key-multiselect>
                    <div v-if="validationDataSourceSubmitError && validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerId']" class="invalid-feedback">{{ tErrors('validation_data_source', 'retailer_id', validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerId']) }}</div>
                  </div>
                  <div class="col-sm-4">
                    <input v-model="retailerMapping.retailerValue" type="text" class="form-control" :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerValue'] }" :placeholder="$t('attributes.validation_data_source.retailer_mapping_retailer_value')" :disabled="retailerMapping._destroy">
                    <div v-if="validationDataSourceSubmitError && validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerValue']" class="invalid-feedback">{{ tErrors('validation_data_source', 'retailer_value', validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerValue']) }}</div>
                  </div>
                  <div class="">
                    <template v-if="!retailerMapping._destroy">
                      <button @click.prevent="removeRetailerMapping(retailerMapping)" class="btn btn-danger">Remove retailer value</button>
                    </template>
                    <template v-else>
                      <button @click.prevent="restoreRetailerMapping(retailerMapping)" class="btn btn-success">Restore retailer value</button>
                    </template>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <button @click.prevent="addRetailerMapping" class="btn btn-success">Add a retailer value</button>
        </div>

        <hr>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="validationDataSourceSubmitLoading">
            <md-spinner v-if="validationDataSourceSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>

    </div>
  </transition>
</div>
