<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="appLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">

        <form @submit.prevent="submit">

          <div class="form-group row">
            <label for="inputName" class="col-sm-2 col-form-label">{{ $t('attributes.app.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="app.name" type="text" class="form-control" :class="{ 'is-invalid' : errors.name }" id="inputName" :placeholder="$t('attributes.app.name')">
              <div v-if="errors.name" class="invalid-feedback">{{ tErrors('app', 'name', errors.name) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ $t('attributes.app.sync_cfg') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <codemirror
                v-model="app.syncCfg"
                :options="{ mode: 'text/x-yaml', lineNumbers: true, tabSize: 2 }">
              </codemirror>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="submitLoading">
              <md-spinner v-if="submitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/yaml/yaml.js'
import 'codemirror/lib/codemirror.css'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { MdSpinner, codemirror },
  props: ['action'],
  data: function() {
    return {
      appLoading: false,
      appError: null,
      submitLoading: false,
      submitError: null,
      app: {
        name: null,
        syncCfg: null
      },
      errors: {}
    }
  },
  methods: {
    // Load app
    loadApp: async function() {
      this.appLoading = true

      const query = gql`
        query AppForm($id: Int!) {
          app(id: $id) {
            name
            syncCfg
          }
        }
      `

      const variables = { id: Number(this.$route.params.id) }

      try {
        const { data } = await client.query({ query, variables })

        this.app = cloneDeep(data.app)
      } catch (error) {
        this.appError = error
        throw error
      } finally {
        this.appLoading = false
      }
    },
    submit: async function() {
      this.submitLoading = true

      const mutation = gql`
        mutation($input: UpdateAppInput!) {
          updateApp(input: $input) {
            app {
              id
            }
            errors
          }
        }
      `

      const variables = {
        input: {
          attributes: {
            name: this.app.name,
            syncCfg: this.app.syncCfg
          },
          id: Number(this.$route.params.id)
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.updateApp.errors) {
          this.errors = data.updateApp.errors
        } else {
          this.$router.push({ name: 'apps' })
          this.$root.$bvToast.toast(this.$t('shared.success.app.update'), {
            variant: 'success',
            noCloseButton: true,
            autoHideDelay: 3000
          })
        }
      } catch (error) {
        this.submitError = error
        throw error
      } finally {
        this.submitLoading = false
      }
    }
  },
  created: function() {
    this.loadApp()
  }
}
</script>
