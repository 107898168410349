<template>
  <div>
    <!-- Form element -->
    <div @click="showModal">
      <slot name="element" v-bind="{ brand: value, brands: value }">
        <!-- Single -->
        <template v-if="!multiple">
          <div class="form-control">
            <div class="d-flex align-items-center justify-content-between">
              <div v-if="!value">
                {{ $t('shared.placeholders.select') }}
              </div>
              <div v-else>
                <region-flag :code="value.region.code" />
                {{ value.name }}
              </div>
              <div>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
              </div>
            </div>
          </div>
        </template>

        <!-- Multiple -->
        <template v-else>
          <div class="form-control h-auto">
            <div class="d-flex align-items-center justify-content-between gutter-x-sm">
              <div v-if="value.length === 0">
                {{ $t('shared.placeholders.select') }}
              </div>
              <div v-else style="min-width: 0;">
                <div class="d-flex flex-wrap gutter-xs">
                  <div
                    v-for="brand in value"
                    :key="brand.id"
                    class="btn-group">
                    <div class="btn btn-light btn-xs text-left">
                      <region-flag :code="brand.region.code" />
                      {{ brand.name }}
                    </div>
                    <div class="btn btn-light btn-xs px-2 d-flex align-items-center" @click.stop="unselectBrand(brand)">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
              </div>
            </div>
          </div>
        </template>
      </slot>
    </div>

    <!-- Modal -->
    <b-modal
      :id="`brand-modal-${_uid}`"
      size="lg"
      :title="$t('views.brandModalSelect.title')"
      hide-footer
      no-fade
      @shown="$refs.filterName.focus()">
      <!-- Filters -->
      <div class="vertical-gutters mb-3">
        <div>
          <label for="filter-name">{{ $t('attributes.brand.name') }}</label>
          <input
            v-model="filters.name"
            type="search"
            class="form-control"
            id="filter-name"
            ref="filterName"
            :placeholder="$t('attributes.brand.name')">
        </div>

        <div>
          <label for="filter-active">{{ $t('attributes.brand.active') }}</label>
          <select v-model="filters.active" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">Inactive</option>
            <option :value="true">Active</option>
          </select>
        </div>

        <div>
          <label for="filter-region">{{ $t('attributes.brand.region') }}</label>
          <key-multiselect
            v-model="filters.regionIds"
            :multiple="true"
            :close-on-select="false"
            :options="regionOptions"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="regionsLoading">
            <template slot="option" slot-scope="props">
              <region-flag :code="props.option.code" />
              {{ props.option.name }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <div class="btn-group" style="margin: 2.5px 5px;">
                <div class="btn btn-light btn-xs text-left">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </div>
                <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                </div>
              </div>
            </template>
          </key-multiselect>
        </div>
      </div>

      <hr>

      <!-- Brands -->
      <div v-if="!brandsLoading && brands.length === 0" class="alert alert-warning">
        {{ $t('shared.warnings.no_brand') }}
      </div>
      <template v-else>
        <h6>{{ $tc('views.brandModalSelect.results', brandsCount) }}</h6>

        <div class="list-group custom-scrollbar" style="overflow: auto; max-height: 246px;">
          <a
            v-for="{ brand, selected } in brandsWithStatus"
            :key="brand.id"
            href="javascript:void(0);"
            class="list-group-item list-group-item-action"
            :class="{ 'bg-light': selected }"
            @click="brandClick(brand)"
            :data-brand-id="brand.id">
            <div class="d-flex justify-content-between">
              <div>
                <dot :active="brand.active" />
                <region-flag :code="brand.region.code" />
                {{ brand.name }}
              </div>
              <div v-if="selected">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" class=""></path></svg>
              </div>
            </div>
          </a>

          <!-- Next page -->
          <template v-if="brandsHasNextPage">
            <div v-if="brandsLoading" class="list-group-item text-center">
              <md-spinner md-indeterminate></md-spinner>
            </div>
            <template v-else>
              <button type="button" class="list-group-item list-group-item-action active" @click="loadBrandsPage">
                {{ $t('views.brandModalSelect.loadMore') }}
              </button>
            </template>
          </template>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import MdSpinner from './MdSpinner.vue'
import RegionFlag from './RegionFlag.vue'
import Dot from './Dot.vue'
import KeyMultiselect from './KeyMultiselect.vue'
import { BModal, VBModal } from 'bootstrap-vue'
import debounce from 'lodash-es/debounce'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  name: 'BrandModalSelect',
  inheritAttrs: false,
  components: { MdSpinner, RegionFlag, Dot, KeyMultiselect, BModal },
  directives: { 'b-modal': VBModal },
  props: {
    value: [Object, Array],
    disabled: Boolean,
    multiple: {
      type: Boolean,
      default: false
    },
    languages: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      modalInitialized: false,
      brands: [],
      brandsLoading: false,
      brandsError: null,
      brandsCount: 0,
      brandsPage: 1,
      brandsPerPage: 20,
      brandsHasNextPage: false,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      filters: {
        name: null,
        active: null,
        regionIds: []
      }
    }
  },
  computed: {
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    },
    // Brands with selected status
    brandsWithStatus: function() {
      return this.brands.map(brand => {
        let selected
        if (!this.multiple) {
          selected = this.value && this.value.id === brand.id
        } else {
          selected = this.value.map(brand => brand.id).includes(brand.id)
        }

        return {
          brand,
          selected
        }
      })
    }
  },
  methods: {
    // Show modal
    showModal: function() {
      this.$root.$emit('bv::show::modal', `brand-modal-${this._uid}`)
      if (!this.modalInitialized) {
        this.modalInitialized = true
        this.loadRegions()
        this.loadBrandsPage()
      }
    },
    // Load regions
    loadRegions: async function() {
      this.regionsLoading = true
      this.regionsError = null

      const query = gql`
        query brandModalSelectRegions {
          regions {
            id
            code
          }
        }
      `

      try {
        const { data } = await client.query({ query })

        this.regions = Object.freeze(data.regions)
      } catch (error) {
        this.regionsError = error
        throw error
      } finally {
        this.regionsLoading = false
      }
    },
    // Load brands page
    loadBrandsPage: async function() {
      this.brandsLoading = true
      this.brandsError = null

      const query = gql`
        query brandModalSelectBrands ($page: Int!, $perPage: Int!, $name: String, $active: Boolean, $regionIds: [Int!], $sort: String, $sortDirection: String, $languages: Boolean!, $tags: Boolean!) {
          brandsPage(page: $page, perPage: $perPage, name: $name, active: $active, regionIds: $regionIds, sort: $sort, sortDirection: $sortDirection) {
            nodesCount
            hasNextPage
            nodes {
              id
              name
              active
              region {
                id
                code
                languages @include(if: $languages) {
                  id
                  code
                }
              }
              tags @include(if: $tags) {
                id
                key
              }
            }
          }
        }
      `

      const variables = {
        page: this.brandsPage,
        perPage: this.brandsPerPage,
        name: this.filters.name,
        active: this.filters.active,
        regionIds: this.filters.regionIds.length > 0 ? this.filters.regionIds : undefined,
        languages: this.languages,
        tags: this.tags
      }

      try {
        const { data } = await client.query({ query, variables })

        this.brands = this.brands.concat(data.brandsPage.nodes)
        this.brandsCount = data.brandsPage.nodesCount
        this.brandsHasNextPage = data.brandsPage.hasNextPage
        if (this.brandsHasNextPage) this.brandsPage++
      } catch (error) {
        this.brandsError = error
        throw error
      } finally {
        this.brandsLoading = false
      }
    },
    // Load brands debounced
    loadBrandsPageDebounced: debounce(function() {
      this.brands = []
      this.brandsPage = 1
      this.loadBrandsPage()
    }, 200),
    // Brand click
    brandClick: function(brand) {
      if (!this.multiple) {
        // Single: emit input & close
        if (this.value && this.value.id === brand.id) {
          this.$emit('input', null)
        } else {
          this.$emit('input', brand)
        }
        this.$root.$emit('bv::hide::modal', `brand-modal-${this._uid}`)
      } else {
        // Multiple: toggle brand & emit input
        let brands
        const index = this.value.map(brand => brand.id).indexOf(brand.id)

        if (index === -1) {
          brands = this.value.concat(brand)
        } else {
          brands = this.value.slice()
          brands.splice(index, 1)
        }
        this.$emit('input', brands)
      }
    },
    // Unselect a brand
    unselectBrand: function(brand) {
      const index = this.value.map(brand => brand.id).indexOf(brand.id)

      if (index === -1) {
        throw new Error(`Unselect brand #${brand.id} failed`)
      } else {
        const brands = this.value.slice()
        brands.splice(index, 1)
        this.$emit('input', brands)
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function() {
        this.loadBrandsPageDebounced()
      }
    }
  }
}
</script>
