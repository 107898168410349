<template>
  <span class="region-flag" :alt="regionName(code)" :title="regionName(code)" v-b-tooltip.hover>
    {{ flagEmoji(code) }}
  </span>
</template>

<script>
import flag from 'country-code-emoji'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: { 'b-tooltip': VBTooltip },
  props: ['code'],
  methods: {
    flagEmoji: function(code) {
      if (code === 'INTERNATIONAL') {
        return '🌍'
      } else {
        return flag(code)
      }
    }
  }
}
</script>

<style>
.region-flag {
  line-height: 0.8;
  font-size: 1.5rem;
  vertical-align: -.125em;
}
</style>
