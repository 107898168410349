<template>
  <div>
    <div class="d-flex flex-wrap justify-content-end align-items-center gutters mb-4">
      <router-link v-if="$can('create', 'Tag')" :to="{ name: 'new_tag' }" class="btn btn-success">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
        {{ $t('shared.actions.new_tag') }}
      </router-link>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="tagsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="tagsLoading">
          <thead>
            <tr>
              <th class="table-col-shrink">{{ $t('attributes.tag.id') }}</th>
              <th style="width: 230px;">{{ $t('attributes.tag.key') }}</th>
              <th>{{ $t('attributes.tag.name') }}</th>
              <th class="table-col-shrink">{{ $t('attributes.tag.brands') }}</th>
              <th class="table-col-shrink">{{ $t('attributes.tag.retailers') }}</th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="tagsError" key="1">
              <tr>
                <td colspan="6" class="text-center alert-danger">
                  <template v-if="tagsError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internal_server_error') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else key="2">
              <tr v-if="!tagsLoading && tags.length === 0">
                <td colspan="6" class="text-center alert-warning">{{ $t('shared.warnings.no_tag') }}</td>
              </tr>
              <tr v-else v-for="tag in tags" :key="tag.id">
                <th>{{ tag.id }}</th>
                <td>{{ tag.key }}</td>
                <td>{{ getTagName(tag) }}</td>
                <td class="text-center">{{ tag.brandsCount }}</td>
                <td class="text-center">{{ tag.retailersCount }}</td>
                <td>
                  <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                    <template v-slot:button-content>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-w-14" fill="currentColor"><path d="M64 360C94.93 360 120 385.1 120 416C120 446.9 94.93 472 64 472C33.07 472 8 446.9 8 416C8 385.1 33.07 360 64 360zM64 200C94.93 200 120 225.1 120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200zM64 152C33.07 152 8 126.9 8 96C8 65.07 33.07 40 64 40C94.93 40 120 65.07 120 96C120 126.9 94.93 152 64 152z"/></svg>
                    </template>
                    <router-link :to="{name: 'tag', params: { id: tag.id } }" class="dropdown-item">
                      {{ $t('shared.actions.show') }}
                    </router-link>
                    <router-link v-if="$can('update', 'Tag')" :to="{ name: 'edit_tag', params: { id: tag.id } }" class="dropdown-item">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Pagination from '../shared/Pagination.vue'
import { BDropdown } from 'bootstrap-vue'
import i18n from '../../i18n.js'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { MdSpinner, Pagination, BDropdown },
  data: function() {
    return {
      tags: [],
      tagsLoading: false,
      tagsError: null,
      totalRows: 0,
      perPage: 20
    }
  },
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions
        .map(region => {
          return {
            id: region.id,
            code: region.code,
            name: this.regionName(region.code)
          }
        })
        .sort(function(a, b) {
          return a.name.localeCompare(b.name)
        })
    }
  },
  methods: {
    // Load tags using url query params
    loadData: async function() {
      this.tagsLoading = true
      this.tagsError = null

      const query = gql`
        query tagsIndex($page: Int!, $perPage: Int!) {
          tagsPage(page: $page, perPage: $perPage) {
            nodesCount
            nodes {
              id
              key
              brandsCount
              retailersCount
              tagLanguageDatas {
                name
                tagLanguage {
                  code
                  id
                }
              }
            }
          }
        }
      `

      const variables = {
        page: Number(this.currentPage),
        perPage: Number(this.perPage)
      }

      try {
        const { data } = await client.query({ query, variables })

        this.totalRows = data.tagsPage.nodesCount
        this.tags = Object.freeze(data.tagsPage.nodes)
      } catch (error) {
        this.tagsError = error
        throw error
      } finally {
        this.tagsLoading = false
      }
    },
    // Get tag name for the user language
    getTagName: function(tag) {
      const languageData = tag.tagLanguageDatas.find(data => data.tagLanguage.code === i18n.language())
      return languageData.name
    }
  },
  created: function() {
    this.loadData()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.loadData()
    }
  }
}
</script>
