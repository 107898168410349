<template>
  <div class="d-flex h-100 align-items-center">
    <form class="form-signin" @submit.prevent="resetPassword">
      <div class="form-header d-flex flex-column align-items-center justify-content-center">
        <c2b-logo class="logo" />
        HUB
      </div>

      <input
        type="password"
        id="inputNewPassword"
        class="form-control"
        :class="{ 'is-invalid' : passwordSubmitError && passwordSubmitError.password }"
        :placeholder="$t('attributes.user.new_password')"
        v-model="password"
        required>

      <input
        type="password"
        id="inputNewPasswordConfirmation"
        class="form-control"
        :class="{ 'is-invalid' : passwordSubmitError && passwordSubmitError.passwordConfirmation }"
        :placeholder="$t('attributes.user.new_password_confirmation')"
        v-model="passwordConfirmation"
        required>

      <div v-if="passwordSubmitError" class="invalid-feedback d-block">
        <div v-if="passwordSubmitError && passwordSubmitError.resetPasswordToken">{{ tErrors('user', 'resetPasswordToken', passwordSubmitError.resetPasswordToken) }}</div>
        <div v-if="passwordSubmitError && passwordSubmitError.password">{{ tErrors('user', 'password', passwordSubmitError.password) }}</div>
        <div v-if="passwordSubmitError && passwordSubmitError.passwordConfirmation">{{ tErrors('user', 'password_confirmation', passwordSubmitError.passwordConfirmation) }}</div>
      </div>

      <button class="btn btn-primary btn-block position-relative" type="submit" :disabled="passwordSubmitLoading">
        <div v-if="passwordSubmitLoading" class="position-absolute">
          <md-spinner md-indeterminate :diameter="24" :stroke-width="5"></md-spinner>
        </div>
        {{ $t('devise.passwords.edit_action') }}
      </button>

      <div class="mt-4 text-center">
        <router-link :to="{ name: 'login' }">{{ $t('devise.links.login') }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import C2bLogo from '../shared/C2bLogo.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { C2bLogo, MdSpinner },
  data: function() {
    return {
      password: null,
      passwordConfirmation: null,
      passwordSubmitLoading: false,
      passwordSubmitError: null
    }
  },
  methods: {
    resetPassword: async function() {
      this.passwordSubmitLoading = true

      const mutation = gql`
        mutation ($input: UpdatePasswordWithTokenInput!) {
          updatePasswordWithToken(input: $input) {
            success
            errors
          }
        }
      `

      const variables = {
        input: {
          resetPasswordToken: this.$route.query.reset_password_token,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.updatePasswordWithToken.success) {
          this.$router.push({ name: 'login' })
          this.$root.$bvToast.toast(this.$t('devise.passwords.edit_notification'), {
            variant: 'success',
            noCloseButton: true,
            autoHideDelay: 3000
          })
        } else {
          this.passwordSubmitError = data.updatePasswordWithToken.errors
        }
      } catch (error) {
        this.passwordSubmitError = { data: error }
      } finally {
        this.passwordSubmitLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-header {
  font-size: 18px;
  margin-bottom: 10px;
}

.logo {
  width: auto;
  height: 40px;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input + input {
  margin-top: -1px;
}

.form-signin button[type="submit"] {
  margin-top: 10px;
}
</style>
