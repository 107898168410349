
<div>
  <transition name="fade" mode="out-in">
    <div v-if="brandLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">

      <form @submit.prevent="submit">
        <div class="form-group row">
          <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.brand.name') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="brand.name" type="text" class="form-control" :class="{ 'is-invalid' : brandSubmitError &&  brandSubmitError.name }" id="input-name" :placeholder="$t('attributes.brand.name')">
            <div v-if="brandSubmitError && brandSubmitError.name" class="invalid-feedback">{{ tErrors('brand', 'name', brandSubmitError.name) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="display-name-input" class="col-sm-2 col-form-label">{{ $t('attributes.brand.display_name') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="brand.displayName" type="text" class="form-control" :class="{ 'is-invalid' : brandSubmitError && brandSubmitError.displayName }" id="display-name-input" :placeholder="$t('attributes.brand.display_name')">
            <div v-if="brandSubmitError && brandSubmitError.displayName" class="invalid-feedback">{{ tErrors('brand', 'display_name', brandSubmitError.displayName) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.brand.active') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="brand.active" type="checkbox" class="custom-control-input" id="active-checkbox">
              <label class="custom-control-label" for="active-checkbox"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="region-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.brand.region') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <key-multiselect
              v-model="brand.regionId"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading"
              :class="{ 'is-invalid' : brandSubmitError && brandSubmitError.region }"
              :disabled="regionsError !== null"
              id="region-multiselect">
              <template slot="singleLabel" slot-scope="{ option }">
                <region-flag :code="option.code" />
                {{ option.name }}
              </template>
              <template slot="option" slot-scope="{ option }">
                <region-flag :code="option.code" />
                {{ option.name }}
              </template>
            </key-multiselect>
            <div v-if="brandSubmitError &&  brandSubmitError.region" class="invalid-feedback d-block">{{ tErrors('brand', 'region', brandSubmitError.region) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="parent-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.brand.parent') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <brand-modal-select
              v-model="brand.parent"
              languages
              :class="{ 'is-invalid' : brandSubmitError && brandSubmitError.parent }"
              id="multiselect-parent" />
            <div v-if="brandSubmitError && brandSubmitError.parent" class="invalid-feedback d-block">{{ tErrors('brand', 'parent', brandSubmitError.parent) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="is-brand-checkbox" class="col-sm-2">{{ $t('attributes.brand.is_brand') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="brand.isBrand" type="checkbox" class="custom-control-input" id="is-brand-checkbox">
              <label class="custom-control-label" for="is-brand-checkbox"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="prospect-checkbox" class="col-sm-2">{{ $t('attributes.brand.prospect') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="brand.prospect" type="checkbox" class="custom-control-input" id="prospect-checkbox">
              <label class="custom-control-label" for="prospect-checkbox"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="img-input" class="col-sm-2 col-form-label">{{ $t('attributes.brand.img') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <img v-if="action === 'edit' && brand.img && brand.img.url" :src="brand.img.url" class="border mb-2" />
            <b-form-file
              v-model="brand.imgFile"
              placeholder="Choose a file..."
              drop-placeholder="Drop file here..."
              id="img-input" />
          </div>
        </div>

        <div class="form-group row">
          <label for="tags-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.brand.tags') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <key-multiselect
              v-model="brand.tagIds"
              :options="tags"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              label="key"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="tagsLoading"
              :disabled="tagsError !== null"
              id="tags-multiselect">
            </key-multiselect>
          </div>
        </div>

        <div class="form-group row">
          <label for="input-amazon-tracking-id" class="col-sm-2 col-form-label">{{ $t('attributes.brand.amazon_tracking_id') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="brand.amazonTrackingId" type="text" class="form-control" :class="{ 'is-invalid' : brandSubmitError && brandSubmitError.amazonTrackingId }" id="input-amazon-tracking-id" :placeholder="$t('attributes.brand.amazon_tracking_id')">
            <div v-if="brandSubmitError && brandSubmitError.amazonTrackingId" class="invalid-feedback">{{ tErrors('brand', 'amazon_tracking_id', brandSubmitError.amazonTrackingId) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="textarea-description" class="col-sm-2 col-form-label">{{ $t('shared.description.label') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <textarea
              v-model="description"
              class="form-control"
              id="textarea-description"
              :placeholder="$t('shared.description.placeholder')" />
          </div>
        </div>

        <div class="alert alert-danger" v-if="brandSubmitError && brandSubmitError.status === 502">
          {{ $t('errors.bad_gateway') }}
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="brandSubmitLoading || brandSubmitError && brandSubmitError.status === 502">
            <md-spinner v-if="brandSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>

    </div>
  </transition>
</div>
