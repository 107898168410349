
<div>
  <transition name="fade" mode="out-in">
    <div v-if="userLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">

      <form @submit.prevent="submit">
        <template v-if="action === 'new' || action === 'edit'">
          <div class="form-group row">
            <label for="inputEmail" class="col-sm-2 col-form-label">{{ $t('attributes.user.email') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="user.email" type="email" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.email }" id="inputEmail" :placeholder="$t('attributes.user.email')">
              <div v-if="userSubmitError && userSubmitError.email" class="invalid-feedback">{{ tErrors('user', 'email', userSubmitError.email) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ $t('attributes.user.role') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <select v-model="user.role" class="custom-select">
                <option value="admin">Admin</option>
                <option value="manager">Manager</option>
                <option value="reader">Reader</option>
                <option value="user">User</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label for="checkboxActive" class="col-sm-2">{{ $t('attributes.user.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="user.active" type="checkbox" class="custom-control-input" id="checkboxActive">
                <label class="custom-control-label" for="checkboxActive"></label>
              </div>
            </div>
          </div>
        </template>

        <template v-if="action === 'edit_password'">
          <div class="form-group row">
            <label for="inputPassword" class="col-sm-2 col-form-label">{{ $t('attributes.user.new_password') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="user.password" type="password" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.password }" id="inputPassword" :placeholder="$t('attributes.user.new_password')">
              <div v-if="userSubmitError && userSubmitError.password" class="invalid-feedback">{{ tErrors('user', 'password', userSubmitError.password) }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputPasswordConfirmation" class="col-sm-2 col-form-label">{{ $t('attributes.user.password_confirmation') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="user.passwordConfirmation" type="password" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.passwordConfirmation }" id="inputPasswordConfirmation" :placeholder="$t('attributes.user.password_confirmation')">
              <div v-if="userSubmitError && userSubmitError.passwordConfirmation" class="invalid-feedback">{{ tErrors('user', 'password_confirmation', userSubmitError.passwordConfirmation) }}</div>
            </div>
          </div>
        </template>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="userSubmitLoading">
            <md-spinner v-if="userSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            <template v-else-if="action === 'edit_password'">{{ $t('shared.submit.update_password') }}</template>
          </button>
        </div>
      </form>

    </div>
  </transition>
</div>
