<template>
  <div class="d-flex h-100 align-items-center">
    <form class="form-signin" @submit.prevent="login">
      <div class="form-header d-flex flex-column align-items-center justify-content-center">
        <c2b-logo class="logo" />
        HUB
      </div>

      <input type="email" id="inputEmail" class="form-control"
        :class="error ? 'is-invalid' : ''"
        :placeholder="$t('attributes.user.email')"
        v-model="email" required autofocus>

      <input type="password" id="inputPassword" class="form-control"
        :class="error ? 'is-invalid' : ''"
        :placeholder="$t('attributes.user.password')"
        v-model="password" required>

      <div v-if="error" class="invalid-feedback">
        {{ $t(`devise.failure.${userError}`) }}
      </div>

      <div class="custom-control custom-checkbox mt-2">
        <input v-model="rememberMe" type="checkbox" class="custom-control-input" id="remember-me">
        <label class="custom-control-label" for="remember-me">{{ $t('attributes.user.remember_me') }}</label>
      </div>

      <button class="btn btn-lg btn-primary btn-block position-relative" type="submit" :disabled="userLoading">
        <div class="position-absolute" v-if="userLoading">
          <md-spinner md-indeterminate :diameter="32" :stroke-width="5"></md-spinner>
        </div>
        {{ $t('shared.actions.sign_in') }}
      </button>

      <div class="mt-4 text-center">
        <router-link :to="{ name: 'new_password' }">{{ $t('devise.links.new_password') }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import C2bLogo from '../shared/C2bLogo.vue'
import MdSpinner from '../shared/MdSpinner.vue'

export default {
  components: { C2bLogo, MdSpinner },
  data: function() {
    return {
      email: null,
      password: null,
      rememberMe: false
    }
  },
  computed: {
    ...mapGetters({
      userLoading: 'auth/userLoading',
      userError: 'auth/userError'
    }),
    error: function() {
      return this.userError && this.userError !== 'unauthenticated'
    }
  },
  methods: {
    ...mapActions({
      authLogin: 'auth/login'
    }),
    // Call devise sign_in
    login: async function() {
      await this.authLogin({
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      })

      if (!this.userError) {
        this.$router.push(this.$route.query.redirect || '/')
        this.$root.$bvToast.toast(this.$t('shared.success.sign_in'), {
          variant: 'success',
          noCloseButton: true,
          autoHideDelay: 3000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-header {
  font-size: 18px;
  margin-bottom: 10px;
}

.logo {
  width: auto;
  height: 40px;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input + input {
  margin-top: -1px;
}

.form-signin button[type="submit"] {
  margin-top: 10px;
}
</style>
