
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.99 64">
  <g fill="#f39335">
    <path d="M16.86,68.53H25.6a1.54,1.54,0,0,1,1.58,1.58v2s.19,1.16-1.58,1.77c-1.91.08-6.9,0-6.9,0s-8.27-.15-9.58,9.58a64.19,64.19,0,0,0,.12,13.91c.53,2.65,2.07,7.13,8.58,8.47H25a2,2,0,0,1,2.15,1.61,7,7,0,0,1,.13,1.94,2.3,2.3,0,0,1-2.17,2c-2.3,0-7.32,0-7.32,0S4.7,110.18,3.66,95.87V84.08S3.61,70.8,16.86,68.53Z" transform="translate(-3.66 -62.22)"/>
    <path d="M41.31,105.72s-.69,0-.72-.62,0-34.63,0-34.63a1.91,1.91,0,0,0-2-2H37.2a2,2,0,0,0-2.06,2.07c0,2.14,0,37.44,0,37.44a3.21,3.21,0,0,0,2.57,3.18c1.48,0,13.87.31,19.55,0,1.56-.06,2.18-.49,2.4-1.81a6,6,0,0,0,0-1.92,2.34,2.34,0,0,0-2.48-1.84C55.3,105.53,41.31,105.72,41.31,105.72Z" transform="translate(-3.66 -62.22)"/>
    <path d="M67,68.47h1.54a1.81,1.81,0,0,1,1.92,1.94c0,2.23.18,35.57,0,38.82A1.8,1.8,0,0,1,69.2,111a4.69,4.69,0,0,1-2.54.16,1.89,1.89,0,0,1-1.43-1.8c-.08-2.11-.08-38.86-.08-38.86S65.09,68.46,67,68.47Z" transform="translate(-3.66 -62.22)"/>
    <path d="M91.82,68.53h8.74a1.54,1.54,0,0,1,1.58,1.58v2s.19,1.16-1.58,1.77c-1.91.08-6.9,0-6.9,0s-8.27-.15-9.58,9.58a64.19,64.19,0,0,0,.12,13.91c.54,2.65,2.07,7.13,8.58,8.47H100a2,2,0,0,1,2.15,1.61,7,7,0,0,1,.13,1.94,2.3,2.3,0,0,1-2.17,2c-2.3,0-7.32,0-7.32,0s-13.12-1.19-14.16-15.5V84.08S78.57,70.8,91.82,68.53Z" transform="translate(-3.66 -62.22)"/>
    <path d="M110,68.58h1.54a1.81,1.81,0,0,1,1.92,1.94c0,2.23.18,35.57,0,38.82a1.79,1.79,0,0,1-1.23,1.77,4.7,4.7,0,0,1-2.53.17,1.91,1.91,0,0,1-1.44-1.81c-.08-2.11-.08-38.86-.08-38.86S108.08,68.58,110,68.58Z" transform="translate(-3.66 -62.22)"/>
    <path d="M113.3,87.57l1.22-.93s1.43-1.2,2.35-.09S132,106.12,133.23,108c.3.52.35.89-.23,1.73a6.66,6.66,0,0,1-1.94,1.62s-1.23.68-1.91-.13c-1-1.11-16.46-21.45-16.46-21.45S111.78,88.72,113.3,87.57Z" transform="translate(-3.66 -62.22)"/>
    <path d="M131,69.08l1.15,1s1.46,1.16.46,2.38S114.7,93,112.9,94.73c-.49.43-.86.58-1.82.2a6.17,6.17,0,0,1-2-1.58s-.88-1.08-.16-2C110,90.1,128.55,69,128.55,69A1.58,1.58,0,0,1,131,69.08Z" transform="translate(-3.66 -62.22)"/>
    <path d="M152.51,77.75a1.61,1.61,0,0,1,.14-2,11.87,11.87,0,0,1,8-2.48c4.14.18,8.71,1.56,8.86,9.49,0,1.49.34,9.43-8.4,10.06-2.53,0-5.57,1-5.63,5.57v.41a.27.27,0,0,0,.34.28h11.67a1.33,1.33,0,0,1,1.53,1.43,9.84,9.84,0,0,1,0,1.18,1.27,1.27,0,0,1-1.38,1.15c-1.3,0-14.63,0-14.63,0s-1.19.21-1.21-1.38,0-3.51,0-3.51.15-8.68,8.84-8.91c3.89,0,5.19-2.09,5.19-5.92s-1.21-5-2.88-5.72-5.11-.49-7.76,1.18A1.74,1.74,0,0,1,152.51,77.75Z" transform="translate(-3.66 -62.22)"/>
  </g>
  <g fill="#ea5631">
    <path d="M183.2,76.32a25.12,25.12,0,0,0-45.22.16C129.85,93.8,141,105.6,141,105.6l19.41,20.15a.32.32,0,0,0,.46,0C162.5,124.14,172,114.49,180,106a8.55,8.55,0,0,0,.76-.93C188.36,94.23,186.82,83.42,183.2,76.32Zm-22.52,33c-2.13,0-19-1.15-19.79-20.62C142,68,160.68,68.22,160.68,68.22c1.17-.07,18.61.88,19.68,20.44C179.6,108,162.81,109.28,160.68,109.28Z" transform="translate(-3.66 -62.22)"/>
    <path d="M212.14,89.11c4.57-4.71,5.06-7.67,5.21-11.18-.29-8.85-8.08-8.71-8.8-8.79s-9.68,0-9.68,0c-3.42,0-4,4-4,4L190.49,95c-.09.6-.56,1.71,1,1.91,2.07.25,3.23-.31,3.62-1a16.52,16.52,0,0,0,.86-3.15c.06-.23.21-.42.45-.41l6.82.21c2.67.09,7.15,0,7,5.44s-5.29,7.35-6,7.5-5.72.39-5.72.39-6.06,0-8.41,0-2.49,2.32-2.46,3.56,1.79,1.5,1.79,1.5,4,.09,12.91-.1a14.74,14.74,0,0,0,13-14.14C215.36,92.68,214.41,91.07,212.14,89.11Zm-7.49-1.83h-7.16a.36.36,0,0,1-.35-.43l2.52-12.23a.47.47,0,0,1,.47-.37l7.65.15s5.87.15,4.11,6.74S204.65,87.28,204.65,87.28Z" transform="translate(-3.66 -62.22)"/>
    <path d="M226.12,71.22A3,3,0,0,1,229,69c2.22.11,2,1.64,2,1.64s-4.68,23.45-4.75,24-1.9,8.43,4.76,10.88,14.17-3.37,15.47-10.19c1.46-6.67,5.35-24.16,5.35-24.16A2.87,2.87,0,0,1,254.73,69c2.2.17,1.91,1.53,1.85,1.88-.95,5.2-2.19,12-5.07,24.44-.84,4.37-5.44,15.34-18.62,15.83-13-.5-12.5-12.69-11.81-16.29C222.08,90,226.12,71.22,226.12,71.22Z" transform="translate(-3.66 -62.22)"/>
    <path d="M274.55,87.44l15-17.63c.13-.16,1.05-1.16,1.21-1.29.36-.31.9-.5,1.66.33,1.13,1.25,1.73,2,.63,3.39-1.29,1.7-17.15,21.05-17.15,21.05s-.34-.34-3.26,14.53c-.36,2-.59,2.83-2,3.05a4.66,4.66,0,0,1-2.55-.44,1.06,1.06,0,0,1-.52-1.07c.64-3.35,2.76-14.72,3-15.91a.34.34,0,0,0,0-.22c-.64-1.58-8.18-20-8.4-20.5a2.55,2.55,0,0,1,.63-3.08c1.16-1.09,2.94-2.09,3.83-.23s7,16.25,7.74,18A.12.12,0,0,0,274.55,87.44Z" transform="translate(-3.66 -62.22)"/>
  </g>
</svg>
