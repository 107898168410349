
<div>
  <transition name="fade" mode="out-in">
    <div v-if="retailerDataSourceLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">

      <form @submit.prevent="submit">
        <div class="form-group row">
          <div class="col-sm-2">{{ $t('attributes.retailer_data_source.retailer') }}</div>
          <div class="col-lg-4 col-sm-6 col-12">
            <template v-if="retailer">
              <region-flag :code="retailer.region.code" />
              {{ retailer.name }}
              <span class="badge badge-light">
                {{ retailer.service }}
              </span>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label for="selectLanguage" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_data_source.language') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <select v-model="retailerDataSource.languageId" class="custom-select" id="selectLanguage" :class="{ 'is-invalid' : retailerDataSourceSubmitError && retailerDataSourceSubmitError.language }">
              <template v-if="retailer">
                <option v-for="language in retailer.region.languages" :key="language.code" :value="language.id">{{ language.code }}</option>
              </template>
            </select>
            <div v-if="retailerDataSourceSubmitError && retailerDataSourceSubmitError.language" class="invalid-feedback">{{ tErrors('retailer_data_source', 'language', retailerDataSourceSubmitError.language) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="selectOrigin" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_data_source.origin') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <select v-model="retailerDataSource.origin" class="custom-select" id="selectOrigin">
              <option v-for="option in originOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputComment" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_data_source.comment') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="retailerDataSource.comment" type="text" class="form-control" :class="{ 'is-invalid' : retailerDataSourceSubmitError && retailerDataSourceSubmitError.comment }" id="inputComment" :placeholder="$t('attributes.retailer_data_source.comment')">
            <div v-if="retailerDataSourceSubmitError && retailerDataSourceSubmitError.comment" class="invalid-feedback">{{ tErrors('retailer_data_source', 'comment', retailerDataSourceSubmitError.comment) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputUrl" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_data_source.url') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="retailerDataSource.url" type="text" class="form-control" :class="{ 'is-invalid' : retailerDataSourceSubmitError && retailerDataSourceSubmitError.url }" id="inputUrl" :placeholder="$t('attributes.retailer_data_source.url')">
            <div v-if="retailerDataSourceSubmitError && retailerDataSourceSubmitError.url" class="invalid-feedback">{{ tErrors('retailer_data_source', 'url', retailerDataSourceSubmitError.url) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="checkboxTrustedEan" class="col-sm-2">{{ $t('attributes.retailer_data_source.trusted_ean') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="retailerDataSource.trustedEan" type="checkbox" class="custom-control-input" id="checkboxTrustedEan">
              <label class="custom-control-label" for="checkboxTrustedEan"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="checkboxTrustedUpc" class="col-sm-2">{{ $t('attributes.retailer_data_source.trusted_upc') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="retailerDataSource.trustedUpc" type="checkbox" class="custom-control-input" id="checkboxTrustedUpc">
              <label class="custom-control-label" for="checkboxTrustedUpc"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="checkboxExportFtpScrapedDistributionRo" class="col-sm-2">{{ $t('attributes.retailer_data_source.export_ftp_scraped_distribution_ro') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="retailerDataSource.exportFtpScrapedDistributionRo" type="checkbox" class="custom-control-input" id="checkboxExportFtpScrapedDistributionRo">
              <label class="custom-control-label" for="checkboxExportFtpScrapedDistributionRo"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="checkboxExportFtpScrapedFittingRo" class="col-sm-2">{{ $t('attributes.retailer_data_source.export_ftp_scraped_fitting_ro') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="retailerDataSource.exportFtpScrapedFittingRo" type="checkbox" class="custom-control-input" id="checkboxExportFtpScrapedFittingRo">
              <label class="custom-control-label" for="checkboxExportFtpScrapedFittingRo"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="overrideSourceUtmsCheckbox" class="col-sm-2">{{ $t('attributes.retailer_data_source.override_source_utms') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="retailerDataSource.overrideSourceUtms" type="checkbox" class="custom-control-input" id="overrideSourceUtmsCheckbox">
              <label class="custom-control-label" for="overrideSourceUtmsCheckbox"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="checkboxDluRoFittersExportable" class="col-sm-2">{{ $t('attributes.retailer_data_source.dlu_ro_fitters_exportable') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="retailerDataSource.dluRoFittersExportable" type="checkbox" class="custom-control-input" id="checkboxDluRoFittersExportable">
              <label class="custom-control-label" for="checkboxDluRoFittersExportable"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="checkboxActive" class="col-sm-2">{{ $t('attributes.retailer_data_source.active') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="retailerDataSource.active" type="checkbox" class="custom-control-input" id="checkboxActive">
              <label class="custom-control-label" for="checkboxActive"></label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="retailerDataSourceSubmitLoading">
            <md-spinner v-if="retailerDataSourceSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>

    </div>
  </transition>
</div>
