import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  namespaced: true,
  state: {
    user: null,
    userLoading: false,
    userError: null
  },
  getters: {
    user: state => state.user,
    userLoading: state => state.userLoading,
    userError: state => state.userError
  },
  mutations: {
    setUser: function(state, user) {
      state.user = user
    },
    setUserLoading: function(state, userLoading) {
      state.userLoading = userLoading
    },
    setUserError: function(state, userError) {
      state.userError = userError
    }
  },
  actions: {
    login: async ({ commit }, user) => {
      commit('setUserLoading', true)
      commit('setUserError', null)

      const mutation = gql`
        mutation($input: LoginInput!) {
          login(input: $input) {
            user {
              email
              role
              rules
            }
            errors
          }
        }
      `

      const variables = {
        input: {
          email: user.email,
          password: user.password,
          rememberMe: user.rememberMe
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.login.errors) {
          commit('setUserError', data.login.errors)
        } else {
          commit('setUser', data.login.user)
        }
      } catch (error) {
        commit('setUserError', error)
      } finally {
        commit('setUserLoading', false)
      }
    },
    // Logout
    logout: async ({ commit }) => {
      const mutation = gql`
        mutation($input: LogoutInput!) {
          logout(input: $input) {
            user {
              email
            }
          }
        }
      `

      const variables = {
        input: {}
      }

      try {
        await client.mutate({ mutation, variables })

        commit('setUser', null)
      } catch (error) {
        commit('setUserError', error)
        throw error
      } finally {
        commit('setUserLoading', false)
      }
    },
    // Check local storage for auth headers and load user from backend
    authenticate: async ({ commit }) => {
      commit('setUserLoading', true)
      commit('setUserError', null)

      const query = gql`
        query {
          currentUser {
            email
            role
            rules
          }
        }
      `

      try {
        const { data } = await client.query({ query })

        if (data.currentUser) {
          commit('setUser', data.currentUser)
        } else {
          commit('setUserError', 'unauthenticated')
        }
      } catch (error) {
        commit('setUserError', error)
      } finally {
        commit('setUserLoading', false)
      }
    }
  }
}
