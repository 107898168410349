<template>
  <span :class="active ? 'text-success' : 'text-danger'">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" class="svg-inline--fa" fill="currentColor"><path d="m498,250c-137,0 -248,111 -248,248s111,248 248,248s248,-111 248,-248s-111,-248 -248,-248z"/></svg>
  </span>
</template>

<script>
export default {
  props: ['active']
}
</script>
