
<div>
  <transition name="fade" mode="out-in">
    <div v-if="tagLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ tag.key }}</h5>
        <router-link v-if="$can('update', 'Tag')" :to="{ name: 'edit_tag', params: { id: $route.params.id } }"
        class="btn btn-warning">
        {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <table class="table">
        <tr>
          <th colspan="2" class="table-col-shrink">{{ $t('attributes.tag.id') }}</th>
          <td>{{ tag.id }}</td>
        </tr>

        <tr>
          <th colspan="2">{{ $t('attributes.tag.key') }}</th>
          <td>{{ tag.key }}</td>
        </tr>

        <tr v-for="(tagLanguageData, index) in tag.tagLanguageDatas" :key="tagLanguageData.id">
          <th :rowspan="tag.tagLanguageDatas.length" v-if="index === 0">{{ $t('attributes.tag.name') }}</th>
          <th><span class="badge badge-light">{{ tagLanguageData.tagLanguage.code }}</span></th>
          <td>{{ tagLanguageData.name }}</td>
        </tr>

        <tr>
          <th colspan="2">{{ $t('attributes.tag.brands') }}</th>
          <td>
            <template v-if="tag.brands.length > 0">
              <a @click="expandBrands = !expandBrands" class="btn btn-light">
                {{ tag.brands.length }}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa icon-will-rotate fa-w-6" :class="{ 'icon-rotate': expandBrands }" fill="currentColor"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>
              </a>
              <transition-expand>
                <ul v-if="expandBrands" class="list-unstyled mt-2 mb-0">
                  <li v-for="brand in tag.brands" :key="brand.id">
                    <router-link :to="{ name: 'brand', params: { id: brand.id } }">
                      <region-flag :code="brand.region.code" />
                      {{ brand.name }}
                    </router-link>
                  </li>
                </ul>
              </transition-expand>
            </template>
            <template v-else>-</template>
          </td>
        </tr>

        <tr>
          <th colspan="2">{{ $t('attributes.tag.retailers') }}</th>
          <td>
            <template v-if="tag.retailers.length > 0">
              <a @click="expandRetailers = !expandRetailers" class="btn btn-light">
                {{ tag.retailers.length }}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa icon-will-rotate fa-w-6" :class="{ 'icon-rotate': expandRetailers }" fill="currentColor"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>
              </a>
              <transition-expand>
                <ul v-if="expandRetailers" class="list-unstyled mt-2 mb-0">
                  <li v-for="retailer in tag.retailers" :key="retailer.id">
                  <router-link :to="{ name: 'retailer', params: { id: retailer.id } }">
                      <region-flag :code="retailer.region.code" />
                      {{ retailer.name }}
                    </router-link>
                  </li>
                </ul>
              </transition-expand>
            </template>
            <template v-else>-</template>
          </td>
        </tr>

      </table>
    </div>
  </transition>
</div>
