<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="retailerLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">

        <form @submit.prevent="submit">

          <b-tabs content-class="my-3">
            <!-- General information tab -->
            <b-tab title="General information" active>

              <div class="form-group row">
                <label for="name-input" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.name') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="retailer.name" type="text" class="form-control" :class="{ 'is-invalid' : retailerSubmitError && retailerSubmitError.name }" id="name-input" :placeholder="$t('attributes.retailer.name')">
                  <div v-if="retailerSubmitError && retailerSubmitError.name" class="invalid-feedback">{{ tErrors('retailer', 'name', retailerSubmitError.name) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="display-name-input" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.display_name') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="retailer.displayName" type="text" class="form-control" :class="{ 'is-invalid' : retailerSubmitError && retailerSubmitError.displayName }" id="display-name-input" :placeholder="$t('attributes.retailer.display_name')">
                  <div v-if="retailerSubmitError && retailerSubmitError.displayName" class="invalid-feedback">{{ tErrors('retailer', 'display_name', retailerSubmitError.displayName) }}</div>
                </div>
              </div>

              <div v-if="action === 'edit'" class="form-group row">
                <label for="key-input" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.key') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="retailer.key" type="text" class="form-control" :class="{ 'is-invalid' : retailerSubmitError && retailerSubmitError.key }" id="key-input" :placeholder="$t('attributes.retailer.key')">
                  <div v-if="retailerSubmitError && retailerSubmitError.key" class="invalid-feedback">{{ tErrors('retailer', 'key', retailerSubmitError.key) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="service-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.service') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <multiselect
                    v-model="retailer.service"
                    :options="['drive', 'delivery', 'store']"
                    :allow-empty="false"
                    :placeholder="$t('shared.placeholders.select')"
                    :show-labels="false"
                    :class="{ 'is-invalid' : retailerSubmitError && retailerSubmitError.service }"
                    id="service-multiselect">
                  </multiselect>
                  <div v-if="retailerSubmitError && retailerSubmitError.service" class="invalid-feedback d-block">{{ tErrors('retailer', 'service', retailerSubmitError.service) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="has-national-stock-checkbox" class="col-sm-2">{{ $t('attributes.retailer.has_national_stock') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.hasNationalStock" type="checkbox" class="custom-control-input" id="has-national-stock-checkbox">
                    <label class="custom-control-label" for="has-national-stock-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="region-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.region') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <key-multiselect
                    v-model="retailer.regionId"
                    :options="regionOptions"
                    label="name"
                    track-by="id"
                    :placeholder="$t('shared.placeholders.select')"
                    :show-labels="false"
                    :loading="regionsLoading"
                    :class="{ 'is-invalid' : retailerSubmitError  && retailerSubmitError.region }"
                    id="region-multiselect">
                    <template slot="singleLabel" slot-scope="{ option }">
                      <region-flag :code="option.code" />
                      {{ option.name }}
                    </template>
                    <template slot="option" slot-scope="{ option }">
                      <region-flag :code="option.code" />
                      {{ option.name }}
                    </template>
                  </key-multiselect>
                  <div v-if="retailerSubmitError && retailerSubmitError.region" class="invalid-feedback d-block">{{ tErrors('retailer', 'region', retailerSubmitError.region) }}</div>
                </div>
              </div>

              <div v-if="action === 'edit'" class="form-group row">
                <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.retailer.active') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                    <label class="custom-control-label" for="active-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="injectable-checkbox" class="col-sm-2">{{ $t('attributes.retailer.injectable') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.injectable" type="checkbox" class="custom-control-input" id="injectable-checkbox">
                    <label class="custom-control-label" for="injectable-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="has-validation-data-source-checkbox" class="col-sm-2">{{ $t('attributes.retailer.has_validation_data_source') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.hasValidationDataSource" type="checkbox" class="custom-control-input" id="has-validation-data-source-checkbox">
                    <label class="custom-control-label" for="has-validation-data-source-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="display-retail-outlet-names-checkbox" class="col-sm-2">{{ $t('attributes.retailer.display_retail_outlet_names') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.displayRetailOutletNames" type="checkbox" class="custom-control-input" id="display-retail-outlet-names-checkbox">
                    <label class="custom-control-label" for="display-retail-outlet-names-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="transfer-origin-utms-checkbox" class="col-sm-2">
                  <span v-b-popover.hover.right="$t('attributes.retailer.transfer_origin_utms_help')">
                    {{ $t('attributes.retailer.transfer_origin_utms') }}
                    <sup>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>
                    </sup>
                  </span>
                </label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.transferOriginUtms" type="checkbox" class="custom-control-input" id="transfer-origin-utms-checkbox">
                    <label class="custom-control-label" for="transfer-origin-utms-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="origin-key-input" class="col-sm-2 col-form-label">
                  <span v-b-popover.hover.right="$t('attributes.retailer.origin_key_help')">
                    {{ $t('attributes.retailer.origin_key') }}
                    <sup>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>
                    </sup>
                  </span>
                </label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="retailer.originKey" type="text" class="form-control" :class="{ 'is-invalid' : retailerSubmitError && retailerSubmitError.originKey }" id="origin-key-input" :placeholder="$t('attributes.retailer.origin_key')">
                  <div v-if="retailerSubmitError && retailerSubmitError.originKey" class="invalid-feedback">{{ tErrors('retailer', 'origin_key', retailerSubmitError.originKey) }}</div>
                </div>
              </div>

            </b-tab>

            <!-- Script tab -->
            <b-tab title="Script">
              <div class="form-group row">
                <label for="has-validation-script-checkbox" class="col-sm-2">{{ $t('attributes.retailer.has_validation_script') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.hasValidationScript" type="checkbox" class="custom-control-input" id="has-validation-script-checkbox">
                    <label class="custom-control-label" for="has-validation-script-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="validation-url-input" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.validation_url') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="retailer.validationUrl" type="text" class="form-control" :class="{ 'is-invalid' : retailerSubmitError && retailerSubmitError.validationUrl }" id="validation-url-input" :placeholder="$t('attributes.retailer.validation_url')">
                  <div v-if="retailerSubmitError && retailerSubmitError.validationUrl" class="invalid-feedback">{{ tErrors('retailer', 'validation_url', retailerSubmitError.validationUrl) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="validation-url-regex-input" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.validation_url_regex') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="retailer.validationUrlRegex" type="text" class="form-control" :class="{ 'is-invalid' : retailerSubmitError && retailerSubmitError.validationUrlRegex }" id="validation-url-regex-input" :placeholder="$t('attributes.retailer.validation_url_regex')">
                  <div v-if="retailerSubmitError && retailerSubmitError.validationUrlRegex" class="invalid-feedback">{{ tErrors('retailer', 'validation_url_regex', retailerSubmitError.validationUrlRegex) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="tag-page-checkbox" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.tag_page') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div v-for="tagPage of tagPages" :key="tagPage" class="custom-control custom-radio">
                    <input v-model="retailer.tagPage" type="radio" :id="`tag-page-${tagPage}`" name="tag-page" :value="tagPage" class="custom-control-input">
                    <label :for="`tag-page-${tagPage}`" class="custom-control-label">{{ $t(`attributes.retailer.tag_pages.${tagPage}`) }}</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="validation-query-string-params" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.validation_query_string_params') }}</label>
                <div class="col-lg-10 col-12">
                  <div v-if="retailer.validationQueryStringParams.length === 0" class="alert alert-warning" role="alert">
                    {{ $t('shared.warnings.no_validation_query_string_params') }}
                  </div>
                  <table v-else class="table">
                    <thead>
                      <tr>
                        <th>{{ $t('attributes.validation_query_string_param.key') }}</th>
                        <th>{{ $t('attributes.validation_query_string_param.value') }}/{{ $t('attributes.validation_query_string_param.value_regex') }}</th>
                        <th class="table-col-shrink"></th>
                        <th class="table-col-shrink"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(validationQueryStringParam, index) in retailer.validationQueryStringParams"
                        :class="{ 'table-danger': validationQueryStringParam._destroy }"
                        style="line-height: 38px;"
                        :key="index"
                        >
                        <td>
                          <input v-model="validationQueryStringParam.key" type="text" class="form-control" :placeholder="$t('attributes.validation_query_string_param.key')">
                        </td>
                        <td>
                          <input v-model="validationQueryStringParam.value" type="text" class="form-control mb-2" :placeholder="$t('attributes.validation_query_string_param.value')">
                          <input v-model="validationQueryStringParam.valueRegex" type="text" class="form-control" :placeholder="$t('attributes.validation_query_string_param.value_regex')">
                        </td>
                        <td>
                          <template v-if="!validationQueryStringParam.id">
                            <span class="badge badge-warning">New</span>
                          </template>
                        </td>
                        <td class="text-nowrap">
                          <template v-if="!validationQueryStringParam._destroy">
                            <button type="button" class="btn btn-sm btn-danger" @click="removeValidationQueryStringParam(validationQueryStringParam)">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa" fill="currentColor"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
                              {{ $t('shared.actions.delete') }}
                            </button>
                          </template>
                          <template v-else>
                            <button type="button" class="btn btn-sm btn-success" @click="restoreValidationQueryStringParam(validationQueryStringParam)">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa" fill="currentColor"><path d="M480 256c0 123.4-100.5 223.9-223.9 223.9c-48.84 0-95.17-15.58-134.2-44.86c-14.12-10.59-16.97-30.66-6.375-44.81c10.59-14.12 30.62-16.94 44.81-6.375c27.84 20.91 61 31.94 95.88 31.94C344.3 415.8 416 344.1 416 256s-71.69-159.8-159.8-159.8c-37.46 0-73.09 13.49-101.3 36.64l45.12 45.14c17.01 17.02 4.955 46.1-19.1 46.1H35.17C24.58 224.1 16 215.5 16 204.9V59.04c0-24.04 29.07-36.08 46.07-19.07l47.6 47.63C149.9 52.71 201.5 32.11 256.1 32.11C379.5 32.11 480 132.6 480 256z"/></svg>
                              {{ $t('shared.actions.restore') }}
                            </button>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="addValidationQueryStringParam">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
                      {{ $t('shared.actions.new_validation_query_string_param') }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <h5>URL tester</h5>
                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'is-valid': testUrlResult === true,
                      'is-invalid': testUrlResult === false
                    }"
                    v-model="testUrl"
                    placeholder="URL...">
                  </div>
              </div>
            </b-tab>

            <!-- Images tab -->
            <b-tab title="Images">
              <div class="d-flex align-items-stretch flex-wrap">
                <div class="flex-fill d-flex flex-column justify-content-between align-items-center">
                  <b>{{ $t('attributes.retailer.img_small') }}</b>
                  <img v-if="retailer.imgSmall.url" :src="retailer.imgSmall.url" style="width: 32px; height: 32px;">
                  <div>96px&times;96px</div>
                  <div>
                    <b-form-file
                      v-model="retailer.imgSmallFile"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      id="img-input" />
                  </div>
                </div>
                <div class="flex-fill d-flex flex-column justify-content-between align-items-center">
                  <b>{{ $t('attributes.retailer.img_large') }}</b>
                  <img v-if="retailer.imgLarge.url" :src="retailer.imgLarge.url" style="width: 200px; height: 100px;">
                  <div>600px&times;300px</div>
                  <div>
                    <b-form-file
                      v-model="retailer.imgLargeFile"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      id="img-large-input" />
                  </div>
                </div>
                <div class="flex-fill d-flex flex-column justify-content-between align-items-center">
                  <b>{{ $t('attributes.retailer.img_flex') }}</b>
                  <img v-if="retailer.imgFlex.url" :src="retailer.imgFlex.url" style="max-width: 200px; max-height: 200px;">
                  <div>600px&times;600px maximum</div>
                  <div>
                    <b-form-file
                      v-model="retailer.imgFlexFile"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      id="img-flex-input" />
                  </div>
                </div>
              </div>
            </b-tab>

            <!-- Report tab -->
            <b-tab title="Report">
              <div class="form-group row">
                <label for="name-input" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.report_language') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <select v-model="retailer.reportLanguage" class="custom-select" id="selectLanguage">
                    <option :value="null">Choose...</option>
                    <option v-for="language in reportLanguages" :key="language" :value="language">{{ language }}</option>
                  </select>
                  <small class="text-muted">{{ $t('views.retailers.reportLanguageNote') }}</small>
                </div>
              </div>
            </b-tab>

            <!-- Michelin tab -->
            <b-tab title="Michelin">
              <div class="form-group row">
                <label for="has-fitters-checkbox" class="col-sm-2">{{ $t('attributes.retailer.has_fitters') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.hasFitters" type="checkbox" class="custom-control-input" id="has-fitters-checkbox">
                    <label class="custom-control-label" for="has-fitters-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="has-fitter-prices-checkbox" class="col-sm-2">{{ $t('attributes.retailer.has_fitter_prices') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.hasFitterPrices" type="checkbox" class="custom-control-input" id="has-fitter-prices-checkbox">
                    <label class="custom-control-label" for="has-fitter-prices-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="has-fitters-but-no-delivery-checkbox" class="col-sm-2">{{ $t('attributes.retailer.has_fitters_but_no_delivery') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.hasFittersButNoDelivery" type="checkbox" class="custom-control-input" id="has-fitters-but-no-delivery-checkbox">
                    <label class="custom-control-label" for="has-fitters-but-no-delivery-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="has-rating-checkbox" class="col-sm-2">{{ $t('attributes.retailer.has_rating') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.hasRating" type="checkbox" class="custom-control-input" id="has-rating-checkbox">
                    <label class="custom-control-label" for="has-rating-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="michelin-type-select" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.michelin_type') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <select v-model="retailer.michelinType" class="custom-select" id="michelin-type-select">
                    <option :value="null">{{ $t('shared.placeholders.select') }}</option>
                    <option value="Click&Mortar">Click&Mortar</option>
                    <option value="PurePlayer">PurePlayer</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label for="transfer-utms-checkbox" class="col-sm-2">
                  <span v-b-popover.hover.right="$t('attributes.retailer.transfer_utms_help')">
                    {{ $t('attributes.retailer.transfer_utms') }}
                    <sup>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>
                    </sup>
                  </span>
                </label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="retailer.transferUtms" type="checkbox" class="custom-control-input" id="transfer-utms-checkbox">
                    <label class="custom-control-label" for="transfer-utms-checkbox"></label>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <hr>

          <div class="form-group row">
            <label for="textarea-description" class="col-sm-2 col-form-label">{{ $t('shared.description.label') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <textarea
                v-model="description"
                class="form-control"
                id="textarea-description"
                :placeholder="$t('shared.description.placeholder')" />
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="retailerSubmitLoading">
              <md-spinner v-if="retailerSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTabs, BTab, BFormFile, VBPopover } from 'bootstrap-vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { MdSpinner, Multiselect, KeyMultiselect, RegionFlag, BTabs, BTab, BFormFile },
  directives: { 'b-popover': VBPopover },
  props: ['action'],
  data: function() {
    return {
      retailer: {
        name: null,
        displayName: null,
        key: null,
        service: 'drive',
        hasNationalStock: false,
        regionId: null,
        active: true,
        injectable: false,
        hasFitters: false,
        hasFitterPrices: false,
        hasRating: false,
        hasFittersButNoDelivery: false,
        displayRetailOutletNames: false,
        michelinType: null,
        transferUtms: false,
        transferOriginUtms: false,
        validationUrl: null,
        validationUrlRegex: null,
        imgSmall: {},
        imgLarge: {},
        imgFlex: {},
        imgSmallFile: null,
        imgLargeFile: null,
        imgFlexFile: null,
        validationQueryStringParams: [],
        reportLanguage: null,
        tagPage: null
      },
      retailerLoading: false,
      retailerError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      description: null,
      retailerSubmitLoading: false,
      retailerSubmitError: null,
      testUrl: null,
      reportLanguages: ['fr', 'en', 'de', 'es'],
      tagPages: ['allPages', 'successPage']
    }
  },
  computed: {
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    },
    // Test validation script
    testUrlResult: function() {
      if (!this.testUrl) return null

      let res, urlObject
      try {
        urlObject = new URL(this.testUrl)
      } catch (e) {
        return false
      }

      res = false
      const searchParams = new URLSearchParams(urlObject.search)
      const validationQueryStringParamsMatch = this.retailer.validationQueryStringParams.every(queryStringParam => {
        if (queryStringParam.value) {
          return searchParams.get(queryStringParam.key) === queryStringParam.value
        } else if (queryStringParam.valueRegex) {
          return new RegExp(queryStringParam.valueRegex).test(searchParams.get(queryStringParam.key))
        }
      })

      if (this.retailer.validationUrl !== '') {
        if (urlObject.pathname === this.retailer.validationUrl && validationQueryStringParamsMatch) {
          res = true
        }
      }

      if (this.retailer.validationUrlRegex !== '') {
        const validationUrlRegex = eval(`"${this.retailer.validationUrlRegex}"`) // eslint-disable-line no-eval
        if ((new RegExp(validationUrlRegex).test(urlObject.pathname) || new RegExp(validationUrlRegex).test(urlObject.href)) && validationQueryStringParamsMatch) {
          res = true
        }
      }

      return res
    }
  },
  methods: {
    // Load data
    loadData: async function({ retailer = true } = {}) {
      this.regionsLoading = true
      this.regionsError = null

      if (retailer) {
        this.retailerLoading = true
        this.retailerError = null
      }

      const query = gql`
        query retailerForm($retailer: Boolean = true, $retailerId: Int = null) {
          retailer(id: $retailerId) @include(if: $retailer) {
            id
            name
            active
            displayName
            key
            service
            imgLarge
            imgSmall
            imgFlex
            hasNationalStock
            injectable
            hasValidationScript
            hasValidationDataSource
            hasFitters
            hasFitterPrices
            hasFittersButNoDelivery
            hasRating
            displayRetailOutletNames
            michelinType
            transferUtms
            transferOriginUtms
            originKey
            reportLanguage
            scriptPresence
            scriptUrl
            tagPage
            validationUrl
            validationUrlRegex
            validationQueryStringParams {
              id
              key
              value
              valueRegex
            }
            region {
              id
              code
              languages {
                id
                code
              }
            }
            retailerContacts {
              id
              firstName
              lastName
              email
              comment
              sendReport
            }
            retailerDataSources {
              id
              origin
              active
              trustedEan
              trustedUpc
              exportFtpScrapedDistributionRo
              exportFtpScrapedFittingRo
              dluRoFittersExportable
              url
              comment
              language {
                id
                code
              }
            }
            tags {
              id
              key
              tagLanguageDatas {
                id
                name
                tagLanguage {
                  id
                  code
                }
              }
            }
          }
          regions {
            id
            code
          }
        }
      `

      const variables = {
        retailer,
        retailerId: retailer ? parseInt(this.$route.params.id) : undefined
      }

      try {
        const { data } = await client.query({ query, variables })

        this.regions = Object.freeze(data.regions)

        if (retailer) {
          this.retailer = {
            ...cloneDeep(data.retailer),
            validationQueryStringParams: data.retailer.validationQueryStringParams.map(queryStringParam => ({
              id: queryStringParam.id,
              key: queryStringParam.key,
              value: queryStringParam.value,
              valueRegex: queryStringParam.valueRegex
            })),
            regionId: data.retailer.region.id
          }
        }
      } catch (error) {
        this.regionsError = error
        this.retailerError = error
        throw error
      } finally {
        this.regionsLoading = false
        this.retailerLoading = false
      }
    },
    // Add validation query string param
    addValidationQueryStringParam: function() {
      this.retailer.validationQueryStringParams.push({ key: null, value: null, valueRegex: null })
    },
    // Remove validation query string param
    removeValidationQueryStringParam: function(validationQueryStringParam) {
      if (validationQueryStringParam.id) {
        this.$set(validationQueryStringParam, '_destroy', true)
      } else {
        const index = this.retailer.validationQueryStringParams.indexOf(validationQueryStringParam)
        this.retailer.validationQueryStringParams.splice(index, 1)
      }
    },
    // Restore validation query string param
    restoreValidationQueryStringParam: function(validationQueryStringParam) {
      this.$set(validationQueryStringParam, '_destroy', false)
    },
    // Submit
    submit: async function() {
      this.retailerSubmitLoading = true
      this.retailerSubmitError = null

      // Files
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const variables = {
        input: {
          attributes: {
            name: this.retailer.name,
            displayName: this.retailer.displayName,
            key: this.retailer.key,
            service: this.retailer.service,
            hasNationalStock: this.retailer.hasNationalStock,
            regionId: this.retailer.regionId,
            injectable: this.retailer.injectable,
            hasValidationScript: this.retailer.hasValidationScript,
            hasValidationDataSource: this.retailer.hasValidationDataSource,
            hasFitters: this.retailer.hasFitters,
            hasFitterPrices: this.retailer.hasFitterPrices,
            hasFittersButNoDelivery: this.retailer.hasFittersButNoDelivery,
            hasRating: this.retailer.hasRating,
            displayRetailOutletNames: this.retailer.displayRetailOutletNames,
            michelinType: this.retailer.michelinType,
            transferUtms: this.retailer.transferUtms,
            transferOriginUtms: this.retailer.transferOriginUtms,
            originKey: this.retailer.originKey,
            validationUrl: this.retailer.validationUrl,
            validationUrlRegex: this.retailer.validationUrlRegex,
            validationQueryStringParamsAttributes: this.retailer.validationQueryStringParams,
            reportLanguage: this.retailer.reportLanguage,
            imgSmall: this.retailer.imgSmallFile ? await toBase64(this.retailer.imgSmallFile) : undefined,
            imgLarge: this.retailer.imgLargeFile ? await toBase64(this.retailer.imgLargeFile) : undefined,
            imgFlex: this.retailer.imgFlexFile ? await toBase64(this.retailer.imgFlexFile) : undefined,
            tagPage: this.retailer.tagPage
          },
          description: this.description
        }
      }

      if (this.action === 'new') {
        const mutation = gql`
          mutation($input: CreateRetailerInput!) {
            createRetailer(input: $input) {
              retailer {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.createRetailer.errors) {
            this.retailerSubmitError = data.createRetailer.errors
          } else {
            this.$router.push({ name: 'retailer', params: { id: data.createRetailer.retailer.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.retailer.create'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.retailerSubmitError = error
          throw error
        } finally {
          this.retailerSubmitLoading = false
        }
      } else if (this.action === 'edit') {
        variables.input.id = this.$route.params.id
        variables.input.attributes.active = this.retailer.active

        const mutation = gql`
          mutation($input: UpdateRetailerInput!) {
            updateRetailer(input: $input) {
              retailer {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.updateRetailer.errors) {
            this.retailerSubmitError = data.updateRetailer.errors
          } else {
            this.$router.push({ name: 'retailer', params: { id: data.updateRetailer.retailer.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.retailer.update'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.retailerSubmitError = error
          throw error
        } finally {
          this.retailerSubmitLoading = false
        }
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ retailer: false })
    }
  }
}
</script>

<style>
.multiselect.is-invalid .multiselect__tags {
  border-color: #cb5a5e;
}
</style>
