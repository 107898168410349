
<div class="d-flex h-100 align-items-center">
  <form class="form-signin" @submit.prevent="login">
    <div class="form-header d-flex flex-column align-items-center justify-content-center">
      <c2b-logo class="logo" />
      HUB
    </div>

    <input type="email" id="inputEmail" class="form-control"
      :class="error ? 'is-invalid' : ''"
      :placeholder="$t('attributes.user.email')"
      v-model="email" required autofocus>

    <input type="password" id="inputPassword" class="form-control"
      :class="error ? 'is-invalid' : ''"
      :placeholder="$t('attributes.user.password')"
      v-model="password" required>

    <div v-if="error" class="invalid-feedback">
      {{ $t(`devise.failure.${userError}`) }}
    </div>

    <div class="custom-control custom-checkbox mt-2">
      <input v-model="rememberMe" type="checkbox" class="custom-control-input" id="remember-me">
      <label class="custom-control-label" for="remember-me">{{ $t('attributes.user.remember_me') }}</label>
    </div>

    <button class="btn btn-lg btn-primary btn-block position-relative" type="submit" :disabled="userLoading">
      <div class="position-absolute" v-if="userLoading">
        <md-spinner md-indeterminate :diameter="32" :stroke-width="5"></md-spinner>
      </div>
      {{ $t('shared.actions.sign_in') }}
    </button>

    <div class="mt-4 text-center">
      <router-link :to="{ name: 'new_password' }">{{ $t('devise.links.new_password') }}</router-link>
    </div>
  </form>
</div>
