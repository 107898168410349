<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="brandLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">

        <form @submit.prevent="submit">
          <div class="form-group row">
            <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.brand.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="brand.name" type="text" class="form-control" :class="{ 'is-invalid' : brandSubmitError &&  brandSubmitError.name }" id="input-name" :placeholder="$t('attributes.brand.name')">
              <div v-if="brandSubmitError && brandSubmitError.name" class="invalid-feedback">{{ tErrors('brand', 'name', brandSubmitError.name) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="display-name-input" class="col-sm-2 col-form-label">{{ $t('attributes.brand.display_name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="brand.displayName" type="text" class="form-control" :class="{ 'is-invalid' : brandSubmitError && brandSubmitError.displayName }" id="display-name-input" :placeholder="$t('attributes.brand.display_name')">
              <div v-if="brandSubmitError && brandSubmitError.displayName" class="invalid-feedback">{{ tErrors('brand', 'display_name', brandSubmitError.displayName) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.brand.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="brand.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                <label class="custom-control-label" for="active-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="region-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.brand.region') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <key-multiselect
                v-model="brand.regionId"
                :options="regionOptions"
                label="name"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false"
                :loading="regionsLoading"
                :class="{ 'is-invalid' : brandSubmitError && brandSubmitError.region }"
                :disabled="regionsError !== null"
                id="region-multiselect">
                <template slot="singleLabel" slot-scope="{ option }">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </template>
              </key-multiselect>
              <div v-if="brandSubmitError &&  brandSubmitError.region" class="invalid-feedback d-block">{{ tErrors('brand', 'region', brandSubmitError.region) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="parent-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.brand.parent') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <brand-modal-select
                v-model="brand.parent"
                languages
                :class="{ 'is-invalid' : brandSubmitError && brandSubmitError.parent }"
                id="multiselect-parent" />
              <div v-if="brandSubmitError && brandSubmitError.parent" class="invalid-feedback d-block">{{ tErrors('brand', 'parent', brandSubmitError.parent) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="is-brand-checkbox" class="col-sm-2">{{ $t('attributes.brand.is_brand') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="brand.isBrand" type="checkbox" class="custom-control-input" id="is-brand-checkbox">
                <label class="custom-control-label" for="is-brand-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="prospect-checkbox" class="col-sm-2">{{ $t('attributes.brand.prospect') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="brand.prospect" type="checkbox" class="custom-control-input" id="prospect-checkbox">
                <label class="custom-control-label" for="prospect-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="img-input" class="col-sm-2 col-form-label">{{ $t('attributes.brand.img') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <img v-if="action === 'edit' && brand.img && brand.img.url" :src="brand.img.url" class="border mb-2" />
              <b-form-file
                v-model="brand.imgFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="img-input" />
            </div>
          </div>

          <div class="form-group row">
            <label for="tags-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.brand.tags') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <key-multiselect
                v-model="brand.tagIds"
                :options="tags"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                label="key"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false"
                :loading="tagsLoading"
                :disabled="tagsError !== null"
                id="tags-multiselect">
              </key-multiselect>
            </div>
          </div>

          <div class="form-group row">
            <label for="input-amazon-tracking-id" class="col-sm-2 col-form-label">{{ $t('attributes.brand.amazon_tracking_id') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="brand.amazonTrackingId" type="text" class="form-control" :class="{ 'is-invalid' : brandSubmitError && brandSubmitError.amazonTrackingId }" id="input-amazon-tracking-id" :placeholder="$t('attributes.brand.amazon_tracking_id')">
              <div v-if="brandSubmitError && brandSubmitError.amazonTrackingId" class="invalid-feedback">{{ tErrors('brand', 'amazon_tracking_id', brandSubmitError.amazonTrackingId) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="textarea-description" class="col-sm-2 col-form-label">{{ $t('shared.description.label') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <textarea
                v-model="description"
                class="form-control"
                id="textarea-description"
                :placeholder="$t('shared.description.placeholder')" />
            </div>
          </div>

          <div class="alert alert-danger" v-if="brandSubmitError && brandSubmitError.status === 502">
            {{ $t('errors.bad_gateway') }}
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="brandSubmitLoading || brandSubmitError && brandSubmitError.status === 502">
              <md-spinner v-if="brandSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import BrandModalSelect from '../shared/BrandModalSelect.vue'
import { BFormFile } from 'bootstrap-vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { MdSpinner, KeyMultiselect, RegionFlag, BrandModalSelect, BFormFile },
  props: ['action'],
  data: function() {
    return {
      brand: {
        name: null,
        displayName: null,
        active: true,
        regionId: null,
        parent: null,
        isBrand: true,
        prospect: false,
        tagIds: [],
        img: null,
        amazonTrackingId: null
      },
      brandLoading: false,
      brandError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      tags: [],
      tagsLoading: false,
      tagsError: null,
      brandSubmitLoading: false,
      brandSubmitError: null,
      description: null
    }
  },
  computed: {
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    }
  },
  methods: {
    loadData: async function({ brand = true } = {}) {
      this.regionsLoading = true
      this.tagsLoading = true
      this.regionsError = null
      this.tagsError = null

      if (brand) {
        this.brandLoading = true
        this.brandError = null
      }

      const query = gql`
        query brandForm($brand: Boolean = true, $brandId: Int = null) {
          brand(id: $brandId) @include(if: $brand) {
            id
            name
            displayName
            active
            region {
              id
              code
            }
            parent {
              id
              name
              region {
                id
                code
              }
            }
            isBrand
            prospect
            tags {
              id
            }
            img
            amazonTrackingId
          }
          regions {
            id
            code
          }
          tags {
            id
            key
          }
        }
      `

      const variables = {
        brand,
        brandId: brand ? parseInt(this.$route.params.id) : undefined
      }

      try {
        const { data } = await client.query({ query, variables })

        this.regions = Object.freeze(data.regions)
        this.tags = Object.freeze(data.tags)

        if (brand) {
          this.brand = {
            ...cloneDeep(data.brand),
            tagIds: data.brand.tags.map(tag => tag.id),
            regionId: data.brand.region.id
          }
        }
      } catch (error) {
        this.regionsError = this.tagsError = this.brandError = error
        throw error
      } finally {
        this.regionsLoading = false
        this.tagsLoading = false
        this.brandLoading = false
      }
    },
    submit: async function() {
      this.brandSubmitLoading = true
      this.brandSubmitError = null

      // Files
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const variables = {
        input: {
          attributes: {
            name: this.brand.name,
            displayName: this.brand.displayName,
            active: this.brand.active,
            regionId: this.brand.regionId,
            parentId: this.brand.parent?.id || null,
            isBrand: this.brand.isBrand,
            prospect: this.brand.prospect,
            tagIds: this.brand.tagIds,
            amazonTrackingId: this.brand.amazonTrackingId,
            img: this.brand.imgFile ? await toBase64(this.brand.imgFile) : undefined
          },
          description: this.description
        }
      }

      if (this.action === 'new') {
        const mutation = gql`
          mutation($input: CreateBrandInput!) {
            createBrand(input: $input) {
              brand {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.createBrand.errors) {
            this.brandSubmitError = data.createBrand.errors
          } else {
            this.$router.push({ name: 'brand', params: { id: data.createBrand.brand.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.brand.create'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.brandSubmitError = error
          throw error
        } finally {
          this.brandSubmitLoading = false
        }
      } else if (this.action === 'edit') {
        variables.input.id = this.$route.params.id

        const mutation = gql`
          mutation($input: UpdateBrandInput!) {
            updateBrand(input: $input) {
              brand {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.updateBrand.errors) {
            this.brandSubmitError = data.updateBrand.errors
          } else {
            this.$router.push({ name: 'brand', params: { id: data.updateBrand.brand.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.brand.update'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.brandSubmitError = error
          throw error
        } finally {
          this.brandSubmitLoading = false
        }
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ brand: false })
    }
  }
}
</script>

<style>
.multiselect.is-invalid .multiselect__tags {
  border-color: #cb5a5e;
}
</style>
