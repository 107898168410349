
<div>
  <transition name="fade" mode="out-in">
    <div v-if="retailerLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ retailer.name }}</h5>
        <router-link v-if="$can('update', 'Retailer')" :to="{ name: 'edit_retailer', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <b-tabs content-class="mt-3">
        <!-- General tab -->
        <b-tab title="General information" active>
          <div class="table-responsive">
            <table class="table">
              <tr>
                <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer.id') }}</th>
                <td>{{ retailer.id }}</td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.name') }}</th>
                <td>{{ retailer.name }}</td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.display_name') }}</th>
                <td>{{ retailer.displayName }}</td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.key') }}</th>
                <td>{{ retailer.key }}</td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.service') }}</th>
                <td>
                  <span class="badge badge-light">{{ retailer.service }}</span>
                </td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.has_national_stock') }}</th>
                <td>
                  <dot :active="retailer.hasNationalStock" />
                </td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.region') }}</th>
                <td>
                  <region-flag :code="retailer.region.code" />
                  {{ regionName(retailer.region.code) }}
                </td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.active') }}</th>
                <td>
                  <dot :active="retailer.active" />
                </td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.tags') }}</th>
                <td>
                  <template v-if="retailer.tags.length > 0">
                    <ul class="list-unstyled mb-0">
                      <li v-for="tag in retailer.tags" :key="tag.id">
                        <router-link :to="{ name: 'tag', params: { id: tag.id } }">
                          {{ getTagName(tag)}}
                        </router-link>
                      </li>
                    </ul>
                  </template>
                  <template v-else>-</template>
                </td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.injectable') }}</th>
                <td>
                  <dot :active="retailer.injectable" />
                </td>
              </tr>
              <tr>
                <th>{{ $t('attributes.retailer.has_validation_data_source') }}</th>
                <td>
                  <dot :active="retailer.hasValidationDataSource" />
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.display_retail_outlet_names') }}</th>
                <td>
                  <dot :active="retailer.displayRetailOutletNames" />
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">
                  <span v-b-popover.hover.right="$t('attributes.retailer.transfer_origin_utms_help')">
                    {{ $t('attributes.retailer.transfer_origin_utms') }}
                    <sup>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>
                    </sup>
                  </span>
                </th>
                <td>
                  <dot :active="retailer.transferOriginUtms" />
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">
                  <span v-b-popover.hover.right="$t('attributes.retailer.origin_key_help')">
                    {{ $t('attributes.retailer.origin_key') }}
                    <sup>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>
                    </sup>
                  </span>
                </th>
                <td>
                  <code v-if="retailer.originKey">
                    {{ retailer.originKey }}
                  </code>
                  <template v-else>-</template>
                </td>
              </tr>
            </table>
          </div>
        </b-tab>

        <!-- Stats tab -->
        <b-tab title="Statistics" lazy>
          <!-- Date range picker -->
          <div class="mb-3 text-center">
            <date-range-picker v-model="dateRange" />
          </div>

          <b-tabs pills content-class="mt-3" v-model="statTabIndex" align="center">
            <!-- Tunnel tab -->
            <b-tab active>
              <template v-slot:title>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa"><path fill="currentColor" d="M64 400C64 408.8 71.16 416 80 416H480C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H80C35.82 480 0 444.2 0 400V64C0 46.33 14.33 32 32 32C49.67 32 64 46.33 64 64V400zM342.6 278.6C330.1 291.1 309.9 291.1 297.4 278.6L240 221.3L150.6 310.6C138.1 323.1 117.9 323.1 105.4 310.6C92.88 298.1 92.88 277.9 105.4 265.4L217.4 153.4C229.9 140.9 250.1 140.9 262.6 153.4L320 210.7L425.4 105.4C437.9 92.88 458.1 92.88 470.6 105.4C483.1 117.9 483.1 138.1 470.6 150.6L342.6 278.6z"/></svg>
                {{ $t('views.retailers.statsTabs.tunnel') }}
              </template>

              <!-- Line chart -->
              <div class="position-relative mb-3">
                <div v-if="dailyEventAggregatesLoading" class="position-absolute w-100 d-flex align-items-center justify-content-center" style="z-index: 1; height: calc(100% - 75px);">
                  <md-spinner md-indeterminate></md-spinner>
                </div>
                <highcharts :options="lineChartOptions" style="width: 100%; height: 400px;"></highcharts>
              </div>

              <!-- Export button -->
              <div class="mb-3 text-right">
                <button @click="exportXLSX('tunnel', 'tunnel-stats-table')" type="button" class="btn btn-primary">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
                  {{ $t('shared.actions.xlsxExport') }}
                </button>
              </div>

              <!-- Table -->
              <table id="tunnel-stats-table" class="table table-bordered" :aria-busy="dailyEventAggregatesLoading">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th class="data-cell">{{ $t('shared.stats.print') }}</th>
                    <th class="data-cell">{{ $t('shared.stats.redirection') }}</th>
                    <th class="data-cell">{{ $t('shared.stats.redirectionRate') }}</th>
                    <th class="data-cell">{{ $t('shared.stats.validation') }}</th>
                    <th class="data-cell">{{ $t('shared.stats.validationRate') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-light font-weight-semibold">
                    <td>Total</td>
                    <td class="text-right">{{ totals.actions.print_retail_outlet_retail_outlet | number }}</td>
                    <td class="text-right">{{ totals.actions.redirection_product | number }}</td>
                    <td class="text-right">{{ totals.rates.redirection_product | percentage }}</td>
                    <td class="text-right">{{ totals.actions.validation_product | number }}</td>
                    <td class="text-right">{{ totals.rates.validation_product | percentage }}</td>
                  </tr>
                  <tr v-for="dailyItem in dailyEventAggregatesSeries" :key="dailyItem.date.getTime()">
                    <td>{{ dailyItem.date.toLocaleDateString() }}</td>
                    <td class="text-right">{{ dailyItem.actions.print_retail_outlet_retail_outlet | number }}</td>
                    <td class="text-right">{{ dailyItem.actions.redirection_product | number }}</td>
                    <td class="text-right">{{ dailyItem.rates.redirection_product | percentage }}</td>
                    <td class="text-right">{{ dailyItem.actions.validation_product | number }}</td>
                    <td class="text-right">{{ dailyItem.rates.validation_product | percentage }}</td>
                  </tr>
                </tbody>
              </table>
            </b-tab>

            <!-- Brands stats tab -->
            <b-tab>
              <template v-slot:title>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa"><path fill="currentColor" d="M472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4zM.0003 229.5V80C.0003 53.49 21.49 32 48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5L.0003 229.5zM112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112z"/></svg>
                {{ $t('views.retailers.statsTabs.brands') }}
              </template>

              <!-- Export button -->
              <div class="mb-3 text-right">
                <button @click="exportXLSX('brands', 'brands-stats-table')" type="button" class="btn btn-primary">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
                  {{ $t('shared.actions.xlsxExport') }}
                </button>
              </div>

              <!-- Table -->
              <div v-if="brandEventAggregatesLoading || brandsLoading" style="position: absolute; width: 100%; height: 100%;">
                <div style="position: sticky; top: 0; text-align: center;">
                  <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
                </div>
              </div>

              <div class="table-responsive">
                <table id="brands-stats-table" class="table table-bordered table-striped" :aria-busy="brandEventAggregatesLoading || brandsLoading">
                  <thead>
                    <tr class="text-nowrap">
                      <th class="table-col-shrink"></th>
                      <th>{{ $t('views.retailers.brand') }}</th>
                      <th class="data-cell">{{ $t('shared.stats.print') }}</th>
                      <th class="data-cell">{{ $t('shared.stats.redirection') }}</th>
                      <th class="data-cell">{{ $t('shared.stats.redirectionRate') }}</th>
                      <th class="data-cell">{{ $t('shared.stats.validation') }}</th>
                      <th class="data-cell">{{ $t('shared.stats.validationRate') }}</th>
                    </tr>
                  </thead>
                  <transition name="fade" mode="out-in">
                    <tbody v-if="!brandEventAggregatesLoading && !brandsLoading">
                      <tr v-if="brandsWithStats.length === 0">
                        <td colspan="8" class="text-center alert-warning">{{ $t('views.retailers.noBrands') }}</td>
                      </tr>
                      <tr v-for="brandWithStats in brandsWithStats" :key="brandWithStats.brandId">
                        <td>
                          <template v-if="brandWithStats.brand && brandWithStats.brand.img.url">
                            <img :src="brandWithStats.brand.img.url" class="td-img" />
                          </template>
                        </td>
                        <td>
                          <div class="d-flex justify-content-between">
                            <span>
                              <template v-if="brandWithStats.brand">{{ brandWithStats.brand.name }}</template>
                            </span>
                            <router-link :to="{ name: 'brand', params: { id: brandWithStats.brandId } }">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa"><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z"/></svg>
                            </router-link>
                          </div>
                        </td>
                        <td class="text-right">{{ brandWithStats.stats.print_retail_outlet_retail_outlet | number }}</td>
                        <td class="text-right">{{ brandWithStats.stats.redirection_product | number }}</td>
                        <td class="text-right">{{ brandWithStats.stats.redirectionRate | percentage }}</td>
                        <td class="text-right">{{ brandWithStats.stats.validation_product | number }}</td>
                        <td class="text-right">{{ brandWithStats.stats.validationRate | percentage }}</td>
                      </tr>
                    </tbody>
                  </transition>
                </table>
              </div>
            </b-tab>

            <!-- Validations tab -->
            <b-tab>
              <template v-slot:title>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM276.8 133.6C276.8 121.7 267.1 112 255.2 112C243.3 112 233.6 121.7 233.6 133.6V149.7C227.5 151.1 221.6 152.1 216.1 155.4C198.1 163.1 181.1 177.6 177.1 199.4C172.9 222.5 182.9 243.3 202.4 255.8C216.6 264.9 235.4 270.2 250.5 274.5C252.7 275.1 254.1 275.7 257.3 276.4C269.2 279.6 281.7 282.1 291.8 289.9C303.5 297.9 297.9 312.1 285.9 316.1C277 320.6 263.4 322.1 246.4 319.6C234.8 317.8 223.2 313.8 211.9 310C209.4 309.2 206.9 308.3 204.4 307.5C193.1 303.7 180.8 309.9 177.1 321.2C173.3 332.5 179.5 344.8 190.8 348.5C204.9 353.1 219.1 357.8 233.6 361L233.6 378.4C233.6 390.3 243.3 400 255.2 400C267.1 400 276.8 390.3 276.8 378.4L276.8 363.2C285.7 362.2 294.2 360.2 302 357C320.8 349.5 336.3 334.8 340.5 312.4C344.7 289.2 335.6 267.5 316.2 254.2C301.3 244 281.4 238.4 265.8 233.9C263.5 233.3 261.1 232.6 258.8 231.1C247.4 228.9 235.5 225.7 225.6 219.4C213.1 211.4 222.2 199.8 233.2 195C245.6 189.7 260.9 188.5 274.1 191C281.2 192.4 288.2 194.4 295.2 196.3C296.7 196.8 298.3 197.2 299.8 197.6C311.3 200.8 323.2 194.1 326.4 182.6C329.6 171.1 322.8 159.2 311.3 155.1C309.5 155.5 307.6 154.9 305.8 154.4C296.2 151.7 286.5 149 276.8 147.8L276.8 133.6z"/></svg>
                {{ $t('views.retailers.statsTabs.validations') }}
              </template>

              <!-- Validation events -->
              <div v-if="validationEventsLoading" style="position: absolute; width: 100%; height: 100%;">
                <div style="position: sticky; top: 0; text-align: center;">
                  <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered table-striped" :aria-busy="validationEventsLoading">
                  <thead>
                    <tr class="text-nowrap">
                      <th>{{ $t('attributes.validationEvent.id') }}</th>
                      <th>{{ $t('attributes.validationEvent.date') }}</th>
                      <th>{{ $t('attributes.validationEvent.clickDate') }}</th>
                      <th>{{ $t('attributes.validationEvent.medium') }}</th>
                      <th>{{ $t('attributes.validationEvent.mediumId') }}</th>
                      <th>{{ $t('attributes.validationEvent.parentHostname') }}</th>
                      <th>{{ $t('attributes.validationEvent.retailOutletId') }}</th>
                      <th>{{ $t('attributes.validationEvent.retailerOrderData') }}</th>
                    </tr>
                  </thead>
                  <transition name="fade" mode="out-in">
                    <tbody>
                      <tr v-if="!validationEventsLoading && validationEvents.length === 0">
                        <td colspan="8" class="text-center alert-warning">{{ $t('views.retailers.noValidationEvents') }}</td>
                      </tr>
                      <tr v-for="validationEvent in validationEvents" :key="validationEvent.id">
                        <td><code>{{ validationEvent.id }}</code></td>
                        <td class="text-nowrap"><small>{{ validationEvent.date | formatDate }}</small></td>
                        <td class="text-nowrap"><small v-if="validationEvent.clickDate">{{ validationEvent.clickDate | formatDate }}</small></td>
                        <td>{{ validationEvent.dimensions.medium }}</td>
                        <td>{{ validationEvent.dimensions.mediumId }}</td>
                        <td><code>{{ validationEvent.dimensions.parentHostname }}</code></td>
                        <td>{{ validationEvent.dimensions.retailOutletId }}</td>
                        <td>
                          <codemirror
                            v-if="validationEvent.dimensions.retailerOrderData"
                            :value="JSON.stringify(validationEvent.dimensions.retailerOrderData, null, 2)"
                            :options="retailerOrderDataCodemirrorOptions"
                            class="retailer-order-data-code-mirror" />
                        </td>
                      </tr>
                    </tbody>
                  </transition>
                </table>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- Script tab -->
        <b-tab title="Script">
          <div class="table-responsive">
            <table class="table">
              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.has_validation_script') }}</th>
                <td>
                  <dot :active="retailer.hasValidationScript" />
                </td>
              </tr>

              <tr>
                <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer.script_url') }}</th>
                <td>
                  <a :href="retailer.scriptUrl" target="_blank">{{ retailer.scriptUrl }}</a>
                  <button v-clipboard="retailer.scriptUrl" class="btn btn-secondary btn-sm">
                    Copy to clipboard
                  </button>
                </td>
              </tr>

              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.validation_url') }}</th>
                <td>
                  <code v-if="retailer.validationUrl">{{ retailer.validationUrl }}</code>
                  <template v-else>-</template>
                </td>
              </tr>

              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.validation_url_regex') }}</th>
                <td>
                  <code v-if="retailer.validationUrlRegex">{{ retailer.validationUrlRegex }}</code>
                  <template v-else>-</template>
                </td>
              </tr>

              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.validation_query_string_params') }}</th>
                <td>
                  <template v-if="retailer.validationQueryStringParams.length === 0">
                    -
                  </template>
                  <table
                    v-else
                    class="table table-sm">
                    <thead>
                      <tr>
                        <th>{{ $t('attributes.validation_query_string_param.key') }}</th>
                        <th>{{ $t('attributes.validation_query_string_param.value') }}</th>
                        <th>{{ $t('attributes.validation_query_string_param.value_regex') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="validationQueryStringParam in retailer.validationQueryStringParams"
                        :key="validationQueryStringParam.key"
                        >
                        <td><code>{{ validationQueryStringParam.key }}</code></td>
                        <td>
                          <code v-if="validationQueryStringParam.value">{{ validationQueryStringParam.value }}</code>
                          <template v-else>&empty;</template>
                        </td>
                        <td>
                          <code v-if="validationQueryStringParam.valueRegex">{{ validationQueryStringParam.valueRegex }}</code>
                          <template v-else>&empty;</template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.script_presence') }}</th>
                <td>
                  <dot :active="retailer.scriptPresence" />
                </td>
              </tr>

              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.tag_page') }}</th>
                <td>
                  <div v-for="tagPage of tagPages" :key="tagPage" class="custom-control custom-radio">
                    <input v-model="retailer.tagPage" type="radio" :id="`tag-page-${tagPage}`" name="tag-page" :value="tagPage" class="custom-control-input" disabled>
                    <label :for="`tag-page-${tagPage}`" class="custom-control-label">{{ $t(`attributes.retailer.tag_pages.${tagPage}`) }}</label>
                  </div>
                </td>
              </tr>
            </table>

            <div class="card">
              <div class="card-body">
                <div class="mb-3">
                  <h5>URL tester</h5>
                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'is-valid': testUrlResult === true,
                      'is-invalid': testUrlResult === false
                    }"
                    v-model="testUrl"
                    placeholder="URL...">
                  </div>

                <h5>JS code</h5>
                <codemirror
                  :value="validationSnippet"
                  :options="{ mode: 'text/javascript', lineNumbers: true, tabSize: 2, autoRefresh: true }" />
              </div>
            </div>
          </div>
        </b-tab>

        <!-- Images tab -->
        <b-tab title="Images">
          <div class="d-flex align-items-stretch flex-wrap">
            <div class="flex-fill d-flex flex-column justify-content-between align-items-center">
                <b>{{ $t('attributes.retailer.img_small') }}</b>
                <img :src="retailer.imgSmall.url" style="width: 32px; height: 32px;">
                <div>96px&times;96px</div>
            </div>
            <div class="flex-fill d-flex flex-column justify-content-between align-items-center">
              <b>{{ $t('attributes.retailer.img_large') }}</b>
              <img :src="retailer.imgLarge.url" style="width: 200px; height: 100px;">
                <div>600px&times;300px</div>
            </div>
            <div class="flex-fill d-flex flex-column justify-content-between align-items-center">
              <b>{{ $t('attributes.retailer.img_flex') }}</b>
              <img :src="retailer.imgFlex.url" style="max-width: 200px; max-height: 200px;">
                <div>600px&times;600px maximum</div>
            </div>
            <div class="flex-fill d-flex flex-column justify-content-between align-items-center">
              <b>{{ $t('attributes.retailer.img_marker') }}</b>
              <img :src="retailer.imgSmall.marker.url" style="width: 36px; height: 50px;">
                <div>108px&times;150px</div>
            </div>
          </div>
        </b-tab>

        <!-- Data sources tab -->
        <b-tab title="Data sources">
          <div class="d-flex flex-wrap justify-content-end align-items-center gutters mb-4">
            <router-link v-if="$can('create', 'RetailerDataSource')" :to="{ name: 'new_retailer_data_source', id: retailer.id }" class="btn btn-success">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
              {{ $t('shared.actions.new_retailer_data_source') }}
            </router-link>
          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.retailer_data_source.id') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.retailer_data_source.language') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.retailer_data_source.origin') }}</th>
                  <th class="">{{ $t('attributes.retailer_data_source.comment') }}</th>
                  <th>{{ $t('attributes.retailer_data_source.url') }}</th>
                  <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer_data_source.trusted_ean') }}</th>
                  <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer_data_source.trusted_upc') }}</th>
                  <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer_data_source.override_source_utms') }}</th>
                  <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer_data_source.dlu_ro_fitters_exportable') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.retailer_data_source.active') }}</th>
                  <th class="table-col-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="retailerDataSource in retailer.retailerDataSources" :key="retailerDataSource.id">
                  <th>{{ retailerDataSource.id }}</th>
                  <td><code>{{ retailerDataSource.language.code }}</code></td>
                  <td><span class="badge badge-light">{{ retailerDataSource.origin }}</span></td>
                  <td class="overflow-auto" style="max-width: 0;">{{ retailerDataSource.comment }}</td>
                  <td class="overflow-auto" style="max-width: 0;">{{ retailerDataSource.url }}</td>
                  <td class="text-center">
                    <dot :active="retailerDataSource.trustedEan" />
                  </td>
                  <td class="text-center">
                    <dot :active="retailerDataSource.trustedUpc" />
                  </td>
                  <td class="text-center">
                    <dot :active="retailerDataSource.overrideSourceUtms" />
                  </td>
                  <td class="text-center">
                    <dot :active="retailerDataSource.dluRoFittersExportable" />
                  </td>
                  <td class="text-center">
                    <dot :active="retailerDataSource.active" />
                  </td>
                  <td>
                    <router-link v-if="$can('update', 'RetailerDataSource')" :to="{ name: 'edit_retailer_data_source', params: { id: retailer.id, retailer_data_source_id: retailerDataSource.id } }" class="btn btn-warning btn-sm">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <!-- Consolidation Data sources tab -->
        <b-tab title="Consolidation Data sources">
          <div class="d-flex flex-wrap justify-content-end align-items-center gutters mb-4">
            <router-link v-if="$can('create', 'ConsolidationRetailOutletDataSource')" :to="{ name: 'new_consolidation_retail_outlet_data_source', id: retailer.id }" class="btn btn-success">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
              {{ $t('shared.actions.new_consolidation_retail_outlet_data_source') }}
            </router-link>
          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.consolidation_retail_outlet_data_source.id') }}</th>
                  <th class="table-col-shrink  text-nowrap">{{ $t('attributes.consolidation_retail_outlet_data_source.retailer_data_source_id') }}</th>
                  <th class="table-col-shrink  text-nowrap">{{ $t('attributes.consolidation_retail_outlet_data_source.retail_outlet_typology') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.consolidation_retail_outlet_data_source.consolidation_provider') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.consolidation_retail_outlet_data_source.matching_formula') }}</th>
                  <th class="overflow-auto" style="max-width: 0;">{{ $t('attributes.consolidation_retail_outlet_data_source.consolidation_fields') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.consolidation_retail_outlet_data_source.active') }}</th>
                  <th class="table-col-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="consolidationRetailOutletDataSource in retailer.consolidationRetailOutletDataSources" :key="consolidationRetailOutletDataSource.id">
                  <th>{{ consolidationRetailOutletDataSource.id }}</th>
                  <td class="text-center">{{ consolidationRetailOutletDataSource.retailerDataSourceId }}</td>
                  <td class="text-center">{{ consolidationRetailOutletDataSource.retailOutletTypology }}</td>
                  <td class="text-center">{{ consolidationRetailOutletDataSource.consolidationProvider }}</td>
                  <td class="text-center">{{ consolidationRetailOutletDataSource.matchingFormula }}</td>
                  <td class="text-center">{{ consolidationRetailOutletDataSource.consolidationFields.join(' ') }}</td>
                  <td class="text-center">
                    <dot :active="consolidationRetailOutletDataSource.active" />
                  </td>
                  <td>
                    <router-link v-if="$can('update', 'ConsolidationRetailOutletDataSource')" :to="{ name: 'edit_consolidation_retail_outlet_data_source', params: { id: retailer.id, consolidation_retail_outlet_data_source_id: consolidationRetailOutletDataSource.id } }" class="btn btn-warning btn-sm">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <!-- Contacts tab -->
        <b-tab title="Contacts">
          <div class="d-flex flex-wrap justify-content-end align-items-center gutters mb-4">
            <router-link v-if="$can('create', 'RetailerContact')" :to="{ name: 'new_retailer_contact', id: retailer.id }" class="btn btn-success">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
              {{ $t('shared.actions.new_retailer_contact') }}
            </router-link>
          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.retailer_contact.id') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.retailer_contact.email') }}</th>
                  <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer_contact.last_name') }}</th>
                  <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer_contact.first_name') }}</th>
                  <th>{{ $t('attributes.retailer_contact.comment') }}</th>
                  <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer_contact.send_report') }}</th>
                  <th class="table-col-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="retailerContact in retailerContactsSorted" :key="retailerContact.id">
                  <th>{{ retailerContact.id }}</th>
                  <td>{{ retailerContact.email }}</td>
                  <td>{{ retailerContact.lastName }}</td>
                  <td>{{ retailerContact.firstName }}</td>
                  <td>{{ retailerContact.comment }}</td>
                  <td class="text-center">
                    <dot :active="retailerContact.sendReport" />
                  </td>
                  <td>
                    <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                      <template v-slot:button-content>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-w-14" fill="currentColor"><path d="M64 360C94.93 360 120 385.1 120 416C120 446.9 94.93 472 64 472C33.07 472 8 446.9 8 416C8 385.1 33.07 360 64 360zM64 200C94.93 200 120 225.1 120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200zM64 152C33.07 152 8 126.9 8 96C8 65.07 33.07 40 64 40C94.93 40 120 65.07 120 96C120 126.9 94.93 152 64 152z"/></svg>
                      </template>
                      <router-link v-if="$can('update', 'RetailerContact')" :to="{ name: 'edit_retailer_contact', params: { id: retailer.id, retailer_contact_id: retailerContact.id } }" class="dropdown-item">
                        {{ $t('shared.actions.edit') }}
                      </router-link>
                      <a href="javascript:void(0);" @click="deleteRetailerContact(retailerContact.id)" class="dropdown-item text-danger">
                        {{ $t('shared.actions.delete') }}
                      </a>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <!-- Report tab -->
        <b-tab title="Report">
          <div class="form-group row">
            <div class="col-sm-2">{{ $t('attributes.retailer.report_language') }}</div>
            <div class="col-lg-4 col-sm-6 col-12">
              {{ retailer.reportLanguage || '∅' }}
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-2 col-form-label">Month</div>
            <div class="col-lg-4 col-sm-6 col-12">
              <vue-monthly-picker
                v-model="selectedMonth"
                input-class="form-control"
                selected-background-color="#304269">
              </vue-monthly-picker>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-2 col-form-label">Preview language</div>
            <div class="col-lg-4 col-sm-6 col-12">
              <select v-model="reportLanguage" class="custom-select" id="selectLanguage">
                <option :value="null">Choose...</option>
                <option v-for="language in reportLanguages" :key="language" :value="language">{{ language }}</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-sm-6 col-12 offset-sm-2">
              <a :href="htmlReportUrl" target="_blank" class="btn btn-primary">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa" fill="currentColor"><path d="M224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM154.1 353.8c7.812 7.812 7.812 20.5 0 28.31C150.2 386.1 145.1 388 140 388s-10.23-1.938-14.14-5.844l-48-48c-7.812-7.812-7.812-20.5 0-28.31l48-48c7.812-7.812 20.47-7.812 28.28 0s7.812 20.5 0 28.31L120.3 320L154.1 353.8zM306.1 305.8c7.812 7.812 7.812 20.5 0 28.31l-48 48C254.2 386.1 249.1 388 244 388s-10.23-1.938-14.14-5.844c-7.812-7.812-7.812-20.5 0-28.31L263.7 320l-33.86-33.84c-7.812-7.812-7.812-20.5 0-28.31s20.47-7.812 28.28 0L306.1 305.8zM256 0v128h128L256 0z"/></svg>
                HTML
              </a>
              <a :href="pdfReportUrl" target="_blank" class="btn btn-danger">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa" fill="currentColor"><path d="M184 208c0-4.406-3.594-8-8-8S168 203.6 168 208c0 2.062 .2969 23.31 9.141 50.25C179.1 249.6 184 226.2 184 208zM256 0v128h128L256 0zM80 422.4c0 9.656 10.47 11.97 14.38 6.375C99.27 421.9 108.8 408 120.1 388.6c-14.22 7.969-27.25 17.31-38.02 28.31C80.75 418.3 80 420.3 80 422.4zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM292 312c24.26 0 44 19.74 44 44c0 24.67-18.94 44-43.13 44c-5.994 0-11.81-.9531-17.22-2.805c-20.06-6.758-38.38-15.96-54.55-27.39c-23.88 5.109-45.46 11.52-64.31 19.1c-14.43 26.31-27.63 46.15-36.37 58.41C112.1 457.8 100.8 464 87.94 464C65.92 464 48 446.1 48 424.1c0-11.92 3.74-21.82 11.18-29.51c16.18-16.52 37.37-30.99 63.02-43.05c11.75-22.83 21.94-46.04 30.33-69.14C136.2 242.4 136 208.4 136 208c0-22.05 17.95-40 40-40c22.06 0 40 17.95 40 40c0 24.1-7.227 55.75-8.938 62.63c-1.006 3.273-2.035 6.516-3.082 9.723c7.83 14.46 17.7 27.21 29.44 38.05C263.1 313.4 284.3 312.1 287.6 312H292zM156.5 354.6c17.98-6.5 36.13-11.44 52.92-15.19c-12.42-12.06-22.17-25.12-29.8-38.16C172.3 320.6 164.4 338.5 156.5 354.6zM292.9 368C299 368 304 363 304 356.9C304 349.4 298.6 344 292 344H288c-.3438 .0313-16.83 .9687-40.95 4.75c11.27 7 24.12 13.19 38.84 18.12C288 367.6 290.5 368 292.9 368z"/></svg>
                PDF
              </a>
            </div>
          </div>
        </b-tab>

        <!-- History tab -->
        <b-tab title="History">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.version.event') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.item') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.id') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.name') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.changeset') }}</th>
                  <th>{{ $t('attributes.version.description') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.author') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.version.createdAt') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="retailer.versions.length === 0">
                  <td colspan="9" class="text-center alert-warning">
                    {{ $t('shared.warnings.no_version') }}
                  </td>
                </tr>
                <tr v-else v-for="version in retailer.versions" :key="version.id">
                  <td>
                    <span class="badge" :class="eventBadgeClass(version.event)">{{ version.event }}</span>
                  </td>
                  <td>{{ version.itemType }}</td>
                  <td class="text-nowrap">
                    {{ version.itemId }}
                  </td>
                  <td class="text-nowrap">
                    <template v-if="version.item">
                      {{ version.item.name }}
                    </template>
                  </td>
                  <td>
                    <template v-if="version.event === 'update'">
                      <ul class="list-inline text-nowrap mb-0">
                        <li
                          v-for="(values, key) in version.changeset"
                          :key="`${version.id}-${key}`"
                          v-b-popover.hover.bottom="`${values[0]} → ${values[1]}`"
                          class="list-inline-item">
                          <code>{{ key }}</code>
                        </li>
                      </ul>
                    </template>
                  </td>
                  <td class="text-nowrap">{{ version.description }}</td>
                  <td class="text-nowrap">
                    <router-link v-if="version.author" :to="{ name: 'user', params: { id: version.author.id } }">
                      {{ version.author.email }}
                    </router-link>
                  </td>
                  <td><small class="text-nowrap">{{ version.createdAt }}</small></td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <!-- Michelin tab -->
        <b-tab title="Michelin">
          <div class="table-responsive">
            <table class="table">
              <tr>
                <th class="table-col-shrink text-nowrap">{{ $t('attributes.retailer.has_fitters') }}</th>
                <td>
                  <dot :active="retailer.hasFitters" />
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.has_fitter_prices') }}</th>
                <td>
                  <dot :active="retailer.hasFitterPrices" />
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.has_fitters_but_no_delivery') }}</th>
                <td>
                  <dot :active="retailer.hasFittersButNoDelivery" />
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.has_rating') }}</th>
                <td>
                  <dot :active="retailer.hasRating" />
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.retailer.michelin_type') }}</th>
                <td>
                  {{ retailer.michelinType }}
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">
                  <span v-b-popover.hover.right="$t('attributes.retailer.transfer_utms_help')">
                    {{ $t('attributes.retailer.transfer_utms') }}
                    <sup>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>
                    </sup>
                  </span>
                </th>
                <td>
                  <dot :active="retailer.transferUtms" />
                </td>
              </tr>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </transition>
</div>
