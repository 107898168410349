<template>
  <div class="btn-group text-nowrap">
    <button type="button" @click="moveToPrevDateRange()" class="btn btn-link border-form-control border-right-0">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-left fa-w-10"><path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" class=""></path></svg>
    </button>
    <date-range-picker
      v-model="dateRange"
      :ranges="ranges">
      <div slot="input" slot-scope="picker">
        {{ picker.startDate.toLocaleDateString() }} - {{ picker.endDate.toLocaleDateString() }}
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
      </div>
    </date-range-picker>
    <button type="button" @click="moveToNextDateRange()" class="btn btn-link border-form-control">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-10"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
    </button>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

export default {
  components: { DateRangePicker },
  model: {
    prop: 'parentDateRange',
    event: 'update'
  },
  props: {
    parentDateRange: {
      default: null,
      required: true
    },
    ranges: {
      type: Object,
      default: () => {
        return {
          Today: [moment.utc().startOf('day').toDate(), moment.utc().startOf('day').toDate()],
          'Last 7 days': [moment.utc().subtract(6, 'days').startOf('day').toDate(), moment.utc().startOf('day').toDate()],
          'This month': [moment.utc().startOf('month').toDate(), moment.utc().endOf('month').startOf('day').toDate()],
          'Last month': [moment.utc().subtract(1, 'month').startOf('month').toDate(), moment.utc().subtract(1, 'month').endOf('month').startOf('day').toDate()],
          'This year': [moment.utc().startOf('year').toDate(), moment.utc().endOf('year').startOf('day').toDate()],
          'Last year': [moment.utc().subtract(1, 'year').startOf('year').toDate(), moment.utc().subtract(1, 'year').endOf('year').startOf('day').toDate()]
        }
      }
    }
  },
  data: function() {
    // Default date range: last 30 days
    const defaultDateRange = {
      startDate: moment.utc().startOf('day').subtract(30, 'days').toDate(),
      endDate: moment.utc().startOf('day').subtract(1, 'days').toDate()
    }

    // Init date range from url params or initial v-model value or default date range
    const dateRange = {
      startDate: this.$route.query.startDate
        ? new Date(this.$route.query.startDate)
        : this.parentDateRange.startDate
          ? this.parentDateRange.startDate
          : defaultDateRange.startDate,
      endDate: this.$route.query.endDate
        ? new Date(this.$route.query.endDate)
        : this.parentDateRange.endDate
          ? this.parentDateRange.endDate
          : defaultDateRange.endDate
    }

    this.$emit('update', dateRange)

    return {
      dateRange: dateRange,
      defaultDateRange: defaultDateRange
    }
  },
  methods: {
    // Serialize Date to string for URLs
    dateSerializer: function(date) {
      return moment.utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .format('YYYY-MM-DD')
    },
    // Move to previous date range
    moveToPrevDateRange: function() {
      const duration = moment.duration(moment.utc(this.dateRange.endDate).diff(moment.utc(this.dateRange.startDate)))
      const newDate = moment.utc(this.dateRange.startDate).subtract(1, 'day')
      const startDate = this.dateRange.startDate
      const endDate = this.dateRange.endDate

      const newDateRange = {}

      // If the previous date range was a (or n) full month(s), move to a full period, otherwise keep the duration
      if (moment.utc(startDate).isSame(moment.utc(startDate).startOf('month')) && moment.utc(endDate).isSame(moment.utc(endDate).endOf('month').startOf('day'))) {
        newDateRange.startDate = moment.utc(newDate).startOf('month').subtract(Math.round(duration.asMonths()) - 1, 'months').toDate()
      } else {
        newDateRange.startDate = moment.utc(newDate).subtract(duration).toDate()
      }
      newDateRange.endDate = moment.utc(newDate).toDate()

      this.dateRange = newDateRange
    },
    // Move to next date range
    moveToNextDateRange: function() {
      const duration = moment.duration(moment.utc(this.dateRange.endDate).diff(moment.utc(this.dateRange.startDate)))
      const newDate = moment.utc(this.dateRange.endDate).add(1, 'day')
      const startDate = this.dateRange.startDate
      const endDate = this.dateRange.endDate

      const newDateRange = {}

      // If the previous date range was a (or n) full month(s), move to a full period, otherwise keep the duration
      if (moment.utc(startDate).isSame(moment.utc(startDate).startOf('month')) && moment.utc(endDate).isSame(moment.utc(endDate).endOf('month').startOf('day'))) {
        newDateRange.endDate = moment.utc(newDate).endOf('month').startOf('day').add(Math.round(duration.asMonths()) - 1, 'months').toDate()
      } else {
        newDateRange.endDate = moment.utc(newDate).add(duration).toDate()
      }
      newDateRange.startDate = moment.utc(newDate).toDate()

      this.dateRange = newDateRange
    }
  },
  watch: {
    // Page previous/next
    $route: function(to, from) {
      // Update date range if startDate/endDate do not match
      const dateRange = { ...this.dateRange }
      let changed = false

      if (to.query.startDate !== this.dateSerializer(dateRange.startDate)) {
        changed = true
        dateRange.startDate = to.query.startDate
          ? moment.utc(to.query.startDate).toDate()
          : this.defaultDateRange.startDate
      }

      if (to.query.endDate !== this.dateSerializer(dateRange.endDate)) {
        changed = true
        dateRange.endDate = to.query.endDate
          ? moment.utc(to.query.endDate).toDate()
          : this.defaultDateRange.endDate
      }

      if (changed) {
        this.dateRange = dateRange
      }
    },
    // When date range changes
    dateRange: function(dateRange) {
      // Force date at 00:00:00 (clicking on a date range will set time at 12:00:00)
      dateRange.startDate = moment.utc(dateRange.startDate).startOf('day').toDate()
      dateRange.endDate = moment.utc(dateRange.endDate).startOf('day').toDate()

      const startDateSerialized = this.dateSerializer(dateRange.startDate)
      const endDateSerialized = this.dateSerializer(dateRange.endDate)
      const defaultStartDateSerialized = this.dateSerializer(this.defaultDateRange.startDate)
      const defaultEndDateSerialized = this.dateSerializer(this.defaultDateRange.endDate)

      // Update url
      if (startDateSerialized === defaultStartDateSerialized && endDateSerialized === defaultEndDateSerialized) {
        if (this.$route.query.startDate && this.$route.query.endDate) {
          this.$router.push(Object.assign({}, this.$route.currentRoute, { query: {} }))
        }
      } else {
        if (startDateSerialized !== this.$route.query.startDate || endDateSerialized !== this.$route.query.endDate) {
          this.$router.push(Object.assign({}, this.$route.currentRoute, {
            query: {
              startDate: this.dateSerializer(dateRange.startDate),
              endDate: this.dateSerializer(dateRange.endDate)
            }
          }))
        }
      }

      // Update parent
      this.$emit('update', dateRange)
    }
  }
}
</script>

<style lang="scss">
.border-form-control {
  border: 1px solid #ced4da;
}
</style>
