<template>
  <div>
    <!-- Tabs -->
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <router-link to="/" class="nav-link active">Stats</router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'versions' }" class="nav-link">Modifications history</router-link>
      </li>
    </ul>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="regionsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered" :aria-busy="regionsLoading" style="table-layout: fixed; min-width: 1000px;">
          <thead>
            <tr>
              <th style="width: 230px;">Region</th>
              <th>Brands</th>
              <th>Retailers</th>
              <th>Drive</th>
              <th>Delivery</th>
              <th>Store</th>
              <th>Drive with script</th>
              <th>Delivery with script</th>
              <th>Drive with report</th>
              <th>Delivery with report</th>
              <th>Store with report</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="region in regions" :key="region.id">
              <td class="text-nowrap">
                <region-flag :code="region.code" />
                {{ regionName(region.code) }}
              </td>
              <td class="text-right" :class="{ 'text-black-50': region.brandsCount === 0 }">{{ region.brandsCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.retailersActiveCount === 0 }">{{ region.retailersActiveCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.drivesCount === 0 }">{{ region.drivesCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.deliveriesCount === 0 }">{{ region.deliveriesCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.storesCount === 0 }">{{ region.storesCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.drivesWithScriptCount === 0 }">{{ region.drivesWithScriptCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.deliveriesWithScriptCount === 0 }">{{ region.deliveriesWithScriptCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.drivesWithReportCount === 0 }">{{ region.drivesWithReportCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.deliveriesWithReportCount === 0 }">{{ region.deliveriesWithReportCount }}</td>
              <td class="text-right" :class="{ 'text-black-50': region.storesWithReportCount === 0 }">{{ region.storesWithReportCount }}</td>
            </tr>
          </tbody>
          <tfoot v-if="regions.length">
            <tr class="border-top">
              <th>Total</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.brandsCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.retailersActiveCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.drivesCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.deliveriesCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.storesCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.drivesWithScriptCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.drivesWithScriptCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.drivesWithReportCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.deliveriesWithReportCount, 0) }}</th>
              <th class="text-right">{{ regions.reduce((acc, region) => acc + region.storesWithReportCount, 0) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { MdSpinner, RegionFlag },
  data: function() {
    return {
      regions: [],
      regionsLoading: false,
      regionsError: null
    }
  },
  methods: {
    // Load regions
    loadRegions: async function() {
      this.regionsLoading = true
      this.regionsError = null

      const query = gql`
        query RegionsHomePageStats {
          regions {
            code
            id
            brandsCount
            drivesCount
            deliveriesCount
            deliveriesWithReportCount
            deliveriesWithScriptCount
            drivesCount
            drivesWithReportCount
            drivesWithScriptCount
            storesCount
            storesWithReportCount
            retailersActiveCount
          }
        }
      `

      try {
        const { data } = await client.query({ query })

        this.regions = Object.freeze(data.regions)
      } catch (error) {
        this.regionsError = error
        throw error
      } finally {
        this.regionsLoading = false
      }
    }
  },
  created: function() {
    this.loadRegions()
  }
}
</script>
