<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="appLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">
        <h5 class="mb-3">{{ app.name }}</h5>

        <ul class="nav nav-tabs">
          <li v-for="model in app.models" :key="model" class="nav-item">
            <router-link class="nav-link" :class="{ active: $route.params.model === model }" :to="{ name: 'show_app_model', params: { id: $route.params.id, model: model } }">{{ model }}</router-link>
          </li>
        </ul>

        <div class="position-relative">
          <!-- Table spinner -->
          <div v-if="modelInstancesLoading" style="position: absolute; width: 100%; height: 100%;">
            <div style="position: sticky; top: 0; text-align: center;">
              <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table" :aria-busy="modelInstancesLoading">
              <thead>
                <tr>
                  <th class="table-col-shrink">#</th>
                  <th style="text-transform: capitalize;">{{ previewField }}</th>
                  <th class="table-col-shrink text-center">Status</th>
                  <th class="table-col-shrink"></th>
                  <th class="table-col-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="apiError">
                  <td colspan="5" class="text-center alert-danger">
                    {{ $t('shared.errors.internal_server_error') }}
                  </td>
                </tr>
                <tr v-else-if="!modelInstancesLoading && modelInstances.length === 0">
                  <td colspan="5" class="text-center alert-warning">
                    {{ $t('shared.warnings.no_model_instances') }}
                  </td>
                </tr>
                <tr v-else v-for="modelInstance in modelInstances" :key="modelInstance.id">
                  <th>{{ modelInstance.id }}</th>
                  <td>
                    <template v-if="modelInstance.hub_record">
                      {{ modelInstance.hub_record[previewField] }}
                    </template>
                  </td>
                  <td class="text-center">
                    <span class="badge" :class="statusClass(modelInstance.status)">{{ modelInstance.status }}</span>
                  </td>
                  <td>
                    <template v-if="modelInstance.status === 'partial'">
                      <button class="btn btn-light" v-b-modal="`modal-${modelInstance.id}`">Compare</button>

                      <b-modal :id="`modal-${modelInstance.id}`" size="lg" scrollable ok-only>
                        <div class="row">
                          <div class="col-6">
                            <h5>Hub</h5>
                            <pre>{{ JSON.stringify(modelInstance.hub_record, null, 2) }}</pre>
                          </div>
                          <div class="col-6">
                            <h5>{{ app.name }}</h5>
                            <pre>{{ JSON.stringify(modelInstance.dist_record, null, 2) }}</pre>
                          </div>
                        </div>
                      </b-modal>
                    </template>
                  </td>
                  <td>
                    <button
                      v-if="modelInstance.status === 'nok' || modelInstance.status === 'partial'"
                      @click="sync(modelInstance)"
                      class="btn btn-primary">
                      Sync
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import equal from 'fast-deep-equal'
import { BModal, VBModal } from 'bootstrap-vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { MdSpinner, BModal },
  directives: { 'b-modal': VBModal },
  data: function() {
    return {
      apiError: null,
      appLoading: false,
      modelInstancesLoading: false,
      app: {
        name: null,
        sync_cfg: null
      },
      modelInstances: [],
      previewField: null,
      errors: {}
    }
  },
  methods: {
    // Load app
    loadApp: async function() {
      this.appLoading = true

      const query = gql`
        query AppForm ($id: Int!) {
          app(id: $id) {
            id
            name
          }
        }
      `

      const variables = { id: Number(this.$route.params.id) }

      try {
        const { data } = await client.query({ query, variables })

        this.app = data.app
      } catch (error) {
        this.apiError = error
        throw error
      } finally {
        this.appLoading = false
      }
    },
    // Load model instances
    loadModelInstances: async function() {
      this.apiError = null
      this.modelInstancesLoading = true

      try {
        const res = await fetch(`/api/apps/${this.$route.params.id}/models/${this.$route.params.model}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })
        const json = await res.json()
        if (!res.ok) throw json

        this.modelInstancesLoading = false
        this.modelInstances = json.model_instances
        this.previewField = json.preview_field

        // Compute status
        this.modelInstances.forEach(modelInstance => {
          modelInstance.status = this.getStatus(modelInstance)
        })
      } catch (error) {
        this.apiError = error
        throw error
      } finally {
        this.modelInstancesLoading = false
      }
    },
    // Compute status
    getStatus: function(modelInstance) {
      if (!modelInstance.hub_record) {
        return 'incoherent'
      } else if (!modelInstance.dist_record) {
        return 'nok'
      } else if (!equal(modelInstance.hub_record, modelInstance.dist_record)) {
        return 'partial'
      } else {
        return 'ok'
      }
    },
    // Status badge class
    statusClass: function(status) {
      switch (status) {
        case 'incoherent':
          return 'badge-secondary'
        case 'nok':
          return 'badge-danger'
        case 'partial':
          return 'badge-warning'
        case 'ok':
          return 'badge-success'
      }
    },
    // Sync a model instance
    sync: async function(modelInstance) {
      try {
        const res = await fetch(`/api/apps/${this.$route.params.id}/models/${this.$route.params.model}/sync/${modelInstance.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })
        const json = await res.json()
        if (!res.ok) throw json

        // Note: ideally, we should update modelInstance.dist_record and getStatus again
        modelInstance.status = 'ok'

        const index = this.modelInstances.indexOf(modelInstance)
        this.$set(this.modelInstances, index, modelInstance)
      } catch (error) {
        this.apiError = error
        throw error
      }
    }
  },
  created: function() {
    this.loadApp()
    this.loadModelInstances()
  },
  watch: {
    '$route.params': function() {
      this.loadModelInstances()
    }
  }
}
</script>
