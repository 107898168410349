<template>
  <li class="nav-item">
    <a href="javascript:void(0);" class="nav-link" :class="linkClasses" @click="expand = !expand">
      <div class="d-flex align-items-center gutters-xs">
        <slot name="text"></slot>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa icon-will-rotate fa-w-6 ml-auto" :class="{ 'icon-rotate': expand }" fill="currentColor"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>
      </div>
    </a>
    <transition-expand>
      <ul v-if="expand" class="nav nav-pills flex-column flex-nowrap">
        <slot></slot>
      </ul>
    </transition-expand>
  </li>
</template>

<script>
import TransitionExpand from '../shared/TransitionExpand.vue'

export default {
  components: { TransitionExpand },
  data: function() {
    // Computed properties are not accessible in data...
    const childHrefs = []

    this.$slots.default.forEach(slot => {
      if (slot.componentOptions && slot.componentOptions.tag === 'side-menu-item') {
        const path = slot.componentOptions.propsData.path
        childHrefs.push(this.$router.resolve(path).href)
      }
    })

    return {
      expand: childHrefs.some(childHref => this.$route.path.startsWith(childHref))
    }
  },
  computed: {
    childHrefs: function() {
      const childHrefs = []

      this.$slots.default.forEach(slot => {
        if (slot.componentOptions && slot.componentOptions.tag === 'side-menu-item') {
          const path = slot.componentOptions.propsData.path
          childHrefs.push(this.$router.resolve(path).href)
        }
      })

      return childHrefs
    },
    linkClasses: function() {
      const linkClasses = []

      if (this.childHrefs.some(childHref => this.$route.path.startsWith(childHref))) {
        linkClasses.push('active')
      }

      if (this.expand) {
        linkClasses.push('opened')
      }

      return linkClasses
    }
  }
}
</script>
