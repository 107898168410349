<template>
  <div>
    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="monthlyRetailerReportDeliveriesLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="monthlyRetailerReportDeliveriesLoading">
          <thead>
            <tr>
              <th class="table-col-shrink">Date</th>
              <th class="w-100"></th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="monthlyRetailerReportDeliveriesError">
              <tr>
                <td colspan="6" class="text-center alert-danger">
                  <template v-if="monthlyRetailerReportDeliveriesError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internal_server_error') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-if="!monthlyRetailerReportDeliveriesLoading && monthlyRetailerReportDeliveries.length === 0">
                <td colspan="3" class="text-center alert-warning">
                  {{ $t('shared.warnings.no_retailer_report_deliveries') }}
                </td>
              </tr>
              <tr
                v-else
                v-for="monthlyRetailerReportDelivery in monthlyRetailerReportDeliveriesComputed"
                :key="monthlyRetailerReportDelivery.month">
                <th class="text-nowrap">{{ monthlyRetailerReportDelivery.monthFormatted }}</th>
                <td>
                  {{ monthlyRetailerReportDelivery.retailerReportDeliveries.length }} reports sent
                </td>
                <td>
                  <button class="btn btn-primary text-nowrap" v-b-modal="`modal-${monthlyRetailerReportDelivery.month}`">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-fw" fill="currentColor"><path d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z"/></svg>
                    Details
                  </button>

                  <b-modal
                    :id="`modal-${monthlyRetailerReportDelivery.month}`"
                    size="xl"
                    scrollable
                    ok-only
                    :title="monthlyRetailerReportDelivery.monthFormatted">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="table-col-shrink">Date</th>
                          <th class="table-col-shrink">Retailer</th>
                          <th>Recipients</th>
                          <th class="table-col-shrink">Archive</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="retailerReportDelivery in monthlyRetailerReportDelivery.retailerReportDeliveries" :key="retailerReportDelivery.id">
                          <td class="text-nowrap">
                            <small>{{ new Date(retailerReportDelivery.createdAt).toLocaleString() }}</small>
                          </td>
                          <td class="text-nowrap">
                            <router-link :to="{ name: 'retailer', params: { id: retailerReportDelivery.retailer.id } }">
                              <region-flag :code="retailerReportDelivery.retailer.region.code" />
                              {{ retailerReportDelivery.retailer.name }}
                              <span class="badge badge-light">
                                {{ retailerReportDelivery.retailer.service }}
                              </span>
                            </router-link>
                          </td>
                          <td>
                            <ul class="list-unstyled mb-0">
                              <li v-for="recipient in retailerReportDelivery.recipients" :key="recipient">
                                {{ recipient }}
                              </li>
                            </ul>
                          </td>
                          <td>
                            <a :href="retailerReportDelivery.pdfUrl" class="btn btn-danger text-nowrap" target="_blank">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa" fill="currentColor"><path d="M184 208c0-4.406-3.594-8-8-8S168 203.6 168 208c0 2.062 .2969 23.31 9.141 50.25C179.1 249.6 184 226.2 184 208zM256 0v128h128L256 0zM80 422.4c0 9.656 10.47 11.97 14.38 6.375C99.27 421.9 108.8 408 120.1 388.6c-14.22 7.969-27.25 17.31-38.02 28.31C80.75 418.3 80 420.3 80 422.4zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM292 312c24.26 0 44 19.74 44 44c0 24.67-18.94 44-43.13 44c-5.994 0-11.81-.9531-17.22-2.805c-20.06-6.758-38.38-15.96-54.55-27.39c-23.88 5.109-45.46 11.52-64.31 19.1c-14.43 26.31-27.63 46.15-36.37 58.41C112.1 457.8 100.8 464 87.94 464C65.92 464 48 446.1 48 424.1c0-11.92 3.74-21.82 11.18-29.51c16.18-16.52 37.37-30.99 63.02-43.05c11.75-22.83 21.94-46.04 30.33-69.14C136.2 242.4 136 208.4 136 208c0-22.05 17.95-40 40-40c22.06 0 40 17.95 40 40c0 24.1-7.227 55.75-8.938 62.63c-1.006 3.273-2.035 6.516-3.082 9.723c7.83 14.46 17.7 27.21 29.44 38.05C263.1 313.4 284.3 312.1 287.6 312H292zM156.5 354.6c17.98-6.5 36.13-11.44 52.92-15.19c-12.42-12.06-22.17-25.12-29.8-38.16C172.3 320.6 164.4 338.5 156.5 354.6zM292.9 368C299 368 304 363 304 356.9C304 349.4 298.6 344 292 344H288c-.3438 .0313-16.83 .9687-40.95 4.75c11.27 7 24.12 13.19 38.84 18.12C288 367.6 290.5 368 292.9 368z"/></svg>
                              PDF
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-modal>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Pagination from '../shared/Pagination.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BModal, VBModal } from 'bootstrap-vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { MdSpinner, Pagination, RegionFlag, BModal },
  directives: { 'b-modal': VBModal },
  data: function() {
    return {
      monthlyRetailerReportDeliveries: [],
      monthlyRetailerReportDeliveriesLoading: false,
      monthlyRetailerReportDeliveriesError: null,
      totalRows: 0,
      perPage: 10
    }
  },
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    },
    monthlyRetailerReportDeliveriesComputed: function() {
      const monthlyRetailerReportDeliveries = cloneDeep(this.monthlyRetailerReportDeliveries)

      monthlyRetailerReportDeliveries.forEach(monthlyRetailerReportDelivery => {
        monthlyRetailerReportDelivery.monthFormatted = new Date(monthlyRetailerReportDelivery.month).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric' })
        monthlyRetailerReportDelivery.retailerReportDeliveries.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      })

      return monthlyRetailerReportDeliveries
    }
  },
  methods: {
    // Load retailer report deliveries
    loadMonthlyRetailerReportDeliveries: async function() {
      this.monthlyRetailerReportDeliveriesLoading = true
      this.monthlyRetailerReportDeliveriesError = null

      const query = gql`
        query retailerReportDeliveriesIndex($page: Int!, $perPage: Int!) {
          monthlyRetailerReportDeliveriesPage(page: $page, perPage: $perPage) {
            nodesCount
            nodes {
              month
              retailerReportDeliveries {
                id
                pdfUrl
                recipients
                createdAt
                retailer {
                  id
                  name
                  service
                  region {
                    id
                    code
                  }
                }
              }
            }
          }
        }
      `

      const variables = {
        page: Number(this.currentPage),
        perPage: Number(this.perPage)
      }

      try {
        const { data } = await client.query({ query, variables })

        this.totalRows = data.monthlyRetailerReportDeliveriesPage.nodesCount
        this.monthlyRetailerReportDeliveries = Object.freeze(data.monthlyRetailerReportDeliveriesPage.nodes)
      } catch (error) {
        this.monthlyRetailerReportDeliveriesError = error
        throw error
      } finally {
        this.monthlyRetailerReportDeliveriesLoading = false
      }
    }
  },
  created: function() {
    this.loadMonthlyRetailerReportDeliveries()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.loadMonthlyRetailerReportDeliveries()
    }
  }
}
</script>
