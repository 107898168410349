
<multiselect
  v-bind="$attrs"
  v-on="listeners"
  :value="completeValue"
  :options="options"
  :track-by="trackBy"
  :taggable="taggable"
  @tag="addTag"
  class="key-multiselect"
>
  <!-- Pass on all named slots -->
  <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  <!-- Pass on all scoped slots -->
  <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
    <slot :name="slot" v-bind="scope"/>
  </template>
</multiselect>
