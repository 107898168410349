<template>
  <div>
    <!-- Date range picker -->
    <div class="mb-3">
      <div>
        <date-range-picker v-model="dateRange" />
      </div>
    </div>

    <hr>

    <!-- Table spinner -->
    <div v-if="retailerVersionsLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <template v-else>
      <!-- Summary -->
      <h5 class="d-flex gutters-xs mb-2">
        <span class="badge badge-success">+{{ scriptPresenceTotals.activated }}</span>
        <span class="badge badge-danger">-{{ scriptPresenceTotals.removed }}</span>
      </h5>

      <!-- Table -->
      <table class="table">
        <thead>
          <tr>
            <th style="width: 200px;">{{ $t('attributes.version.createdAt') }}</th>
            <th>{{ $t('attributes.version.retailer') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="retailerVersionsComputed.length === 0">
            <td colspan="3" class="text-center alert-warning">
              {{ $t('shared.warnings.no_version') }}
            </td>
          </tr>
          <tr v-else v-for="retailerVersion in retailerVersionsComputed" :key="retailerVersion.id">
            <td>{{ retailerVersion.createdAt | datetime }}</td>
            <td>
              <router-link :to="{ name: 'retailer', params: { id: retailerVersion.retailer.id } }">
                <region-flag :code="retailerVersion.retailer.region.code" />
                {{ retailerVersion.retailer.name }}
                <span class="badge badge-light">
                  {{ retailerVersion.retailer.service }}
                </span>
              </router-link>
            </td>
            <td class="text-right">
              <span class="badge" :class="retailerVersion.scriptPresence ? 'badge-success' : 'badge-danger'">
                {{ retailerVersion.scriptPresence ? 'Activated' : 'Removed' }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import RegionFlag from '../shared/RegionFlag.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import moment from 'moment'
import DateRangePicker from '../shared/DateRangePicker.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { RegionFlag, MdSpinner, DateRangePicker },
  data: function() {
    // Default date range
    const startDate = this.$route.query.startDate
      ? new Date(this.$route.query.startDate)
      : moment.utc().startOf('day').toDate()
    const endDate = this.$route.query.endDate
      ? new Date(this.$route.query.endDate)
      : moment.utc().startOf('day').toDate()

    return {
      retailerVersions: [],
      retailerVersionsLoading: false,
      retailerVersionsError: null,
      dateRange: {
        startDate: startDate,
        endDate: endDate
      }
    }
  },
  computed: {
    retailerVersionsComputed: function() {
      const retailerVersionsComputed = cloneDeep(this.retailerVersions)

      retailerVersionsComputed.forEach(retailerVersion => {
        retailerVersion.scriptPresence = retailerVersion.objectChanges.script_presence[1]
      })

      return retailerVersionsComputed
    },
    scriptPresenceTotals: function() {
      return {
        activated: this.retailerVersionsComputed.filter(retailerVersion => retailerVersion.scriptPresence).length,
        removed: this.retailerVersionsComputed.filter(retailerVersion => !retailerVersion.scriptPresence).length
      }
    }
  },
  methods: {
    // Load data
    loadData: async function() {
      this.retailerVersionsLoading = true
      this.retailerVersionsError = null

      const query = gql`
        query retailersMonitoring ($event: [String!], $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $changedFields: [String!]) {
          retailerVersions(event: $event, startDate: $startDate, endDate: $endDate, changedFields: $changedFields) {
            id
            retailer {
              id
              name
              service
              imgSmall
              region {
                code
              }
            }
            createdAt
            objectChanges
          }
        }
      `

      const variables = {
        event: 'update',
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        changedFields: ['script_presence']
      }

      if (this.$route.query.item) variables.itemType = this.$route.query.item
      if (this.$route.query.event) variables.event = this.$route.query.event

      try {
        const { data } = await client.query({ query, variables })

        this.retailerVersions = Object.freeze(data.retailerVersions)
        this.totalRows = data.retailerVersions
      } catch (error) {
        this.retailerVersionsError = error
        throw error
      } finally {
        this.retailerVersionsLoading = false
      }
    },
    // Date range picker update
    updateDateRange: function(dateRange) {
      this.dateRange = {
        startDate: moment.utc(dateRange.startDate).startOf('day').toDate(),
        endDate: moment.utc(dateRange.endDate).startOf('day').toDate()
      }
    }
  },
  filters: {
    datetime: function(value) {
      return moment.utc(value).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  created: function() {
    this.loadData()
  },
  watch: {
    // Update data when the route has changed
    $route: function() {
      this.loadData()
    }
  }
}
</script>
