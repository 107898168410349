import Vue from 'vue'
import App from '../App.vue'
import router from '../router'
import store from '../store'
import i18n from '../i18n'
import { abilitiesPlugin } from '@casl/vue'
import { ability } from '../store/ability'
import { ToastPlugin } from 'bootstrap-vue'
import Clipboard from 'v-clipboard'

Vue.use(abilitiesPlugin, ability)
Vue.use(ToastPlugin)
Vue.use(Clipboard)

Vue.mixin({
  methods: {
    // Error translation (rails style)
    tErrors: function(model, attribute, errors) {
      const tErrors = []
      errors.forEach(error => {
        let message
        if (error.count) {
          message = i18n.tc(`errors.messages.${error.error}`, error.count)
        } else {
          message = i18n.t(`errors.messages.${error.error}`, { ...error })
        }

        tErrors.push(i18n.t('errors.format', {
          attribute: i18n.t(`attributes.${model}.${attribute}`),
          message: message
        }))
      })
      return tErrors.join(', ')
    },
    // Get a region name from its code
    regionName: function(code) {
      if (code === 'INTERNATIONAL') {
        return 'International'
      } else {
        const regionNames = new Intl.DisplayNames([i18n.language()], { type: 'region' })
        return regionNames.of(code)
      }
    }
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('div'))
  /* eslint-disable no-new */
  new Vue({
    router,
    store,
    i18n,
    el,
    render: h => h(App)
  })
})
