
<div>
  <transition name="fade" mode="out-in">
    <div v-if="appLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">

      <form @submit.prevent="submit">

        <div class="form-group row">
          <label for="inputName" class="col-sm-2 col-form-label">{{ $t('attributes.app.name') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="app.name" type="text" class="form-control" :class="{ 'is-invalid' : errors.name }" id="inputName" :placeholder="$t('attributes.app.name')">
            <div v-if="errors.name" class="invalid-feedback">{{ tErrors('app', 'name', errors.name) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 col-form-label">{{ $t('attributes.app.sync_cfg') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <codemirror
              v-model="app.syncCfg"
              :options="{ mode: 'text/x-yaml', lineNumbers: true, tabSize: 2 }">
            </codemirror>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="submitLoading">
            <md-spinner v-if="submitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>

    </div>
  </transition>
</div>
