<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="userLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">

        <form @submit.prevent="submit">
          <template v-if="action === 'new' || action === 'edit'">
            <div class="form-group row">
              <label for="inputEmail" class="col-sm-2 col-form-label">{{ $t('attributes.user.email') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="user.email" type="email" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.email }" id="inputEmail" :placeholder="$t('attributes.user.email')">
                <div v-if="userSubmitError && userSubmitError.email" class="invalid-feedback">{{ tErrors('user', 'email', userSubmitError.email) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ $t('attributes.user.role') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <select v-model="user.role" class="custom-select">
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                  <option value="reader">Reader</option>
                  <option value="user">User</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="checkboxActive" class="col-sm-2">{{ $t('attributes.user.active') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch">
                  <input v-model="user.active" type="checkbox" class="custom-control-input" id="checkboxActive">
                  <label class="custom-control-label" for="checkboxActive"></label>
                </div>
              </div>
            </div>
          </template>

          <template v-if="action === 'edit_password'">
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label">{{ $t('attributes.user.new_password') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="user.password" type="password" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.password }" id="inputPassword" :placeholder="$t('attributes.user.new_password')">
                <div v-if="userSubmitError && userSubmitError.password" class="invalid-feedback">{{ tErrors('user', 'password', userSubmitError.password) }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPasswordConfirmation" class="col-sm-2 col-form-label">{{ $t('attributes.user.password_confirmation') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="user.passwordConfirmation" type="password" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.passwordConfirmation }" id="inputPasswordConfirmation" :placeholder="$t('attributes.user.password_confirmation')">
                <div v-if="userSubmitError && userSubmitError.passwordConfirmation" class="invalid-feedback">{{ tErrors('user', 'password_confirmation', userSubmitError.passwordConfirmation) }}</div>
              </div>
            </div>
          </template>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="userSubmitLoading">
              <md-spinner v-if="userSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
              <template v-else-if="action === 'edit_password'">{{ $t('shared.submit.update_password') }}</template>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { MdSpinner },
  props: ['action'],
  data: function() {
    return {
      user: {
        email: null,
        role: 'admin',
        active: true,
        password: '',
        passwordConfirmation: ''
      },
      userLoading: false,
      userError: null,
      userSubmitLoading: false,
      userSubmitError: null
    }
  },
  methods: {
    loadUser: async function() {
      this.userLoading = true
      this.userError = null

      const query = gql`
        query user($id: Int!) {
          user(id: $id) {
            id
            email
            role
            active
          }
        }
      `

      const variables = { id: Number(this.$route.params.id) }

      try {
        const { data } = await client.query({ query, variables })

        this.user = cloneDeep(data.user)
      } catch (error) {
        this.userError = error
        throw error
      } finally {
        this.userLoading = false
      }
    },
    submit: async function() {
      this.userSubmitLoading = true
      this.userSubmitError = null

      try {
        const variables = {
          input: {
            attributes: {
              email: this.user.email,
              active: this.user.active,
              role: this.user.role
            }
          }
        }

        if (this.action === 'new') {
          const mutation = gql`
            mutation($input: CreateUserInput!) {
              createUser(input: $input) {
                user {
                  id
                }
                errors
              }
            }
          `

          const { data } = await client.mutate({ mutation, variables })

          this.$router.push({ name: 'user', params: { id: data.createUser.user.id } })
          this.$root.$bvToast.toast(this.$t('shared.success.user.create'), {
            variant: 'success',
            noCloseButton: true,
            autoHideDelay: 3000
          })
        } else if (this.action === 'edit') {
          variables.input.id = this.$route.params.id

          const mutation = gql`
            mutation($input: UpdateUserInput!) {
              updateUser(input: $input) {
                user {
                  id
                  email
                  role
                  active
                }
                errors
              }
            }
          `

          const { data } = await client.mutate({ mutation, variables })

          this.$router.push({ name: 'user', params: { id: data.updateUser.user.id } })
          this.$root.$bvToast.toast(this.$t('shared.success.user.update'), {
            variant: 'success',
            noCloseButton: true,
            autoHideDelay: 3000
          })
        } else if (this.action === 'edit_password') {
          variables.input.id = this.$route.params.id
          variables.input.attributes = {
            password: this.user.password,
            passwordConfirmation: this.user.passwordConfirmation
          }

          const mutation = gql`
            mutation($input: UpdateUserPasswordInput!) {
              updateUserPassword(input: $input) {
                user {
                  id
                }
                errors
                bypassSignIn
              }
            }
          `

          await client.mutate({ mutation, variables })

          this.$router.push('/')
          this.$root.$bvToast.toast(this.$t('shared.success.user.update_password'), {
            variant: 'success',
            noCloseButton: true,
            autoHideDelay: 3000
          })
        }
      } catch (error) {
        this.userSubmitError = error
        throw error
      } finally {
        this.userSubmitLoading = false
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadUser()
    }
  }
}
</script>
