
<div>
  <!-- Date range picker -->
  <div class="mb-3">
    <div>
      <date-range-picker v-model="dateRange" />
    </div>
  </div>

  <hr>

  <!-- Table spinner -->
  <div v-if="retailerVersionsLoading" style="position: absolute; width: 100%; height: 100%;">
    <div style="position: sticky; top: 0; text-align: center;">
      <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
    </div>
  </div>

  <template v-else>
    <!-- Summary -->
    <h5 class="d-flex gutters-xs mb-2">
      <span class="badge badge-success">+{{ scriptPresenceTotals.activated }}</span>
      <span class="badge badge-danger">-{{ scriptPresenceTotals.removed }}</span>
    </h5>

    <!-- Table -->
    <table class="table">
      <thead>
        <tr>
          <th style="width: 200px;">{{ $t('attributes.version.createdAt') }}</th>
          <th>{{ $t('attributes.version.retailer') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="retailerVersionsComputed.length === 0">
          <td colspan="3" class="text-center alert-warning">
            {{ $t('shared.warnings.no_version') }}
          </td>
        </tr>
        <tr v-else v-for="retailerVersion in retailerVersionsComputed" :key="retailerVersion.id">
          <td>{{ retailerVersion.createdAt | datetime }}</td>
          <td>
            <router-link :to="{ name: 'retailer', params: { id: retailerVersion.retailer.id } }">
              <region-flag :code="retailerVersion.retailer.region.code" />
              {{ retailerVersion.retailer.name }}
              <span class="badge badge-light">
                {{ retailerVersion.retailer.service }}
              </span>
            </router-link>
          </td>
          <td class="text-right">
            <span class="badge" :class="retailerVersion.scriptPresence ? 'badge-success' : 'badge-danger'">
              {{ retailerVersion.scriptPresence ? 'Activated' : 'Removed' }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
</div>
