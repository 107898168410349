<template>
  <li class="nav-item">
    <router-link :to="path" :exact="path === '/'" class="nav-link" active-class="active" :class="activeClass">
      <div class="d-flex align-items-center gutters-xs">
        <slot></slot>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: ['path', 'additionalActivePaths'],
  computed: {
    // By default the router-link will be active if the `to` prop matches the route
    // The prop `additionalActivePaths` allows to provide additional paths that trigger the active class
    activeClass: function() {
      let activeClass = ''

      if (this.additionalActivePaths && this.additionalActivePaths.some(path => {
        return this.$route.path.startsWith(this.$router.resolve(path).href)
      })) {
        activeClass = 'active'
      }

      return activeClass
    }
  }
}
</script>
