import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages: loadLocaleMessages()
})

function loadLocaleMessages() {
  const localeData = import.meta.globEager('./locales/*.json')
  const messages = {}

  Object.keys(localeData).forEach(path => {
    const locale = path.replace(/^.+\/([^/]+)\.json$/, '$1')
    messages[locale] = localeData[path]
  })

  return messages
}

i18n.region = function() { return this.locale.split('-')[1] }
i18n.language = function() { return this.locale.split('-')[0] }

export default i18n
