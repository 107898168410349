
<div>
  <!-- Tabs -->
  <ul class="nav nav-tabs mb-3">
    <li class="nav-item">
      <router-link to="/" class="nav-link">Stats</router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'versions' }" class="nav-link active">Modifications history</router-link>
    </li>
  </ul>

  <div class="mb-4">
    <form @submit.prevent="updateFilters">
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="filter-event">{{ $t('attributes.version.event') }}</label>
          <multiselect
            v-model="filters.event"
            :multiple="true"
            :close-on-select="false"
            :options="['create', 'update', 'destroy']"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            />
        </div>

        <div class="col-md-4 mb-3">
          <label for="filter-item">{{ $t('attributes.version.item') }}</label>
          <multiselect
            v-model="filters.item"
            :multiple="true"
            :close-on-select="false"
            :options="['Brand', 'Retailer', 'RetailerDataSource', 'User']"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            />
        </div>
      </div>

      <div class="gutters-xs">
        <button class="btn btn-primary" type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa" fill="currentColor"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </div>
    </form>
  </div>

  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="versionsLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="versionsLoading">
        <thead>
          <tr>
            <th class="table-col-shrink">{{ $t('attributes.version.event') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.version.item') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.version.id') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.version.name') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.version.changeset') }}</th>
            <th>{{ $t('attributes.version.description') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.version.author') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.version.createdAt') }}</th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="versionsError" key="1">
            <tr>
              <td colspan="9" class="text-center alert-danger">
                <template v-if="versionsError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internal_server_error') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else key="2">
            <tr v-if="!versionsLoading && versions.length === 0">
              <td colspan="9" class="text-center alert-warning">
                {{ $t('shared.warnings.no_version') }}
              </td>
            </tr>
            <tr v-else v-for="version in versions" :key="version.id">
              <td>
                <span class="badge" :class="eventBadgeClass(version.event)">{{ version.event }}</span>
              </td>
              <td>{{ version.itemType }}</td>
              <td class="text-nowrap">
                {{ version.itemId }}
              </td>
              <td class="text-nowrap">
                <template v-if="version.item">
                  {{ version.item.name }}
                </template>
              </td>
              <td>
                <template v-if="version.event === 'update'">
                  <ul class="list-inline text-nowrap mb-0">
                    <li
                      v-for="(values, key) in version.changeset"
                      :key="`${version.id}-${key}`"
                      v-b-popover.hover.bottom="`${values[0]} → ${values[1]}`"
                      class="list-inline-item">
                      <code>{{ key }}</code>
                    </li>
                  </ul>
                </template>
              </td>
              <td class="text-nowrap">{{ version.description }}</td>
              <td class="text-nowrap">
                <router-link v-if="version.author" :to="{ name: 'user', params: { id: version.author.id } }">
                  {{ version.author.email }}
                </router-link>
              </td>
              <td><small class="text-nowrap">{{ version.createdAt }}</small></td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>

  <pagination :total-rows="totalRows" :per-page="perPage" />
</div>
