<template>
  <ul class="list-unstyled">
    <li :class="{ childless: !updatedHasChildren }">
      <span
        class="pointer node"
        v-if="updatedHasChildren"
        @click="openNode"
      >
        <svg v-if="expanded" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10 fa-2x fa-fw"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
        <svg v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6 fa-2x fa-fw"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
      </span>
      <slot :node="node"/>
      <tree
        v-show="expanded"
        v-for="child in children"
        :key="child.id"
        :node="child"
        :load-children="loadChildren"
      >
        <!-- Pass down default slot -->
        <template v-slot:default="slotProps">
          <slot name="default" v-bind="slotProps"/>
        </template>
      </tree>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'tree',
  props: {
    node: Object,
    loadChildren: Function
  },
  data() {
    return {
      expanded: false,
      childrenLoading: false,
      children: []
    }
  },
  computed: {
    hasChildren() {
      return this.node.children
    },
    updatedHasChildren() {
      return this.node.childrenCount > 0
    }
  },
  methods: {
    openNode: async function() {
      this.expanded = !this.expanded
      this.children = await this.loadChildren(this.node.id)
    }
  }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
.node {
  margin-left: 0;
  padding: 0.1rem;
}
.childless{
  margin-left: 1.75rem;
}
</style>
