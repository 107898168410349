
<div>
  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="loading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="loading">
        <thead>
          <tr>
            <th class="table-col-shrink">{{ $t('attributes.app.id') }}</th>
            <th>{{ $t('attributes.app.name') }}</th>
            <th>{{ $t('attributes.app.models') }}</th>
            <th class="table-col-shrink"></th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="apiError === 'Forbidden'" key="1">
            <tr>
              <td colspan="4" class="text-center alert-danger">
                {{ $t('errors.unauthorized.manage.all') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else key="2">
            <tr v-if="!loading && apps.length === 0">
              <td colspan="4" class="text-center alert-warning">
                {{ $t('shared.warnings.no_app') }}
              </td>
            </tr>
            <tr v-else v-for="app in apps" :key="app.id">
              <th>{{ app.id }}</th>
              <td>{{ app.name }}</td>
              <td>
                <ul class="list-unstyled mb-0">
                  <li v-for="model in app.models" :key="model">
                    <router-link :to="{ name: 'show_app_model', params: { id: app.id, model: model } }">{{ model }}</router-link>
                  </li>
                </ul>
              </td>
              <td>
                <router-link :to="{ name: 'edit_app', params: { id: app.id } }" class="btn btn-warning btn-sm">
                  {{ $t('shared.actions.edit') }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>

</div>
