<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="validationDataSourceLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ validationDataSource.name }}</h5>
          <router-link v-if="$can('update', 'ValidationDataSource')" :to="{ name: 'edit_validation_data_source', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <!-- Date range picker -->
        <div class="mb-3 text-center">
          <date-range-picker v-model="dateRange" :ranges="ranges" />
        </div>

        <!-- Tabs -->
        <b-tabs content-class="mt-3">
          <!-- Retailers tab -->
          <b-tab title="Retailers" active>
            <!-- Column chart -->
            <highcharts :options="validationsByRetailerChartOptions" style="width: 100%; height: 400px;" class="mb-3 border p-3"></highcharts>

            <!-- Table -->
            <div class="position-relative">
              <!-- Table spinner -->
              <div v-if="validationJobsLoading" style="position: absolute; width: 100%; height: 100%;">
                <div style="position: sticky; top: 0; text-align: center;">
                  <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered" :aria-busy="validationJobsLoading">
                  <thead>
                    <tr>
                      <th>Retailer</th>
                      <th class="table-col-shrink">Validations</th>
                      <th class="table-col-shrink text-center">Amount</th>
                    </tr>
                  </thead>
                  <template v-if="validationJobs.length === 0">
                    <tbody>
                      <tr>
                        <td colspan="3" class="text-center table-warning">
                          {{ $t('shared.warnings.no_validation_job') }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-else>
                    <tbody>
                      <tr v-for="({ retailer, validations }, retailerId) in validationsByRetailer" :key="retailerId">
                        <td>
                          <template v-if="retailer">
                            <region-flag :code="retailer.region.code" />
                            <template v-if="retailer.imgSmall.url">
                              <img :src="retailer.imgSmall.url" class="retailer-img-small" />
                            </template>
                            {{ retailer.name }}
                            <span class="badge badge-light">
                              {{ retailer.service }}
                            </span>
                          </template>
                          <template v-else>
                            <span class="text-danger">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa" fill="currentColor"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"/></svg>
                              Missing retailer
                            </span>
                          </template>
                        </td>
                        <td class="text-right">
                          {{ validations.length }}
                        </td>
                        <td class="text-right">
                          {{ validations.reduce((acc, validation) => acc + parseFloat(validation.amount), 0) | price(retailer ? retailer.region : null) }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Total</th>
                        <th class="text-right">{{ validationJobs.reduce((acc, validationJob) => acc + validationJob.validations.length, 0) }}</th>
                        <th class="text-right">-</th><!-- TODO: sums splitted by currency? -->
                      </tr>
                    </tfoot>
                  </template>
                </table>
              </div>
            </div>
          </b-tab>

          <!-- Jobs tab -->
          <b-tab title="Jobs & status">
            <!-- Xrange chart -->
            <template v-if="showValidationJobsChart">
              <highcharts :options="validationJobsChartOptions" style="width: 100%; height: 100px;" class="mb-3 border p-3"></highcharts>
            </template>

            <!-- Column chart -->
            <highcharts :options="validationsByStatusChartOptions" style="width: 100%; height: 300px;" class="mb-3 border p-3"></highcharts>

            <!-- Table -->
            <div class="position-relative">
              <!-- Table spinner -->
              <div v-if="validationJobsLoading" style="position: absolute; width: 100%; height: 100%;">
                <div style="position: sticky; top: 0; text-align: center;">
                  <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered" :aria-busy="validationJobsLoading">
                  <thead>
                    <tr>
                      <th rowspan="2" class="table-col-shrink text-center">#</th>
                      <th rowspan="2" class="table-col-shrink text-nowrap text-center">Date range</th>
                      <th rowspan="2" class="table-col-shrink">Status</th>
                      <th rowspan="2">Log</th>
                      <th rowspan="2" class="table-col-shrink">Retailer</th>
                      <th rowspan="2" class="table-col-shrink">Validations</th>
                      <th colspan="5" class="text-center">Validations by status</th>
                      <th rowspan="2" class="table-col-shrink">Validations in stats database</th>
                    </tr>
                    <tr>
                      <th class="table-col-shrink">Approved</th>
                      <th class="table-col-shrink">Pending</th>
                      <th class="table-col-shrink">Declined</th>
                      <th class="table-col-shrink">Deleted</th>
                      <th class="table-col-shrink">Undefined</th>
                    </tr>
                  </thead>
                  <tbody v-if="validationJobs.length === 0">
                    <tr>
                      <td colspan="12" class="text-center table-warning">
                        {{ $t('shared.warnings.no_validation_job') }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else v-for="validationJob in validationJobsWithGroupedRetailers" :key="validationJob.id">
                    <!-- Total -->
                    <tr>
                      <th :rowspan="validationJob.retailersCount + 1">{{ validationJob.id }}</th>
                      <td :rowspan="validationJob.retailersCount + 1">
                        <template v-if="validationJob.startDate && validationJob.endDate">
                          <small class="text-nowrap">
                            [{{ new Date(validationJob.startDate).toLocaleDateString(undefined, { timeZone: 'UTC' }) }},
                          </small>
                          <small class="text-nowrap">
                            {{ new Date(validationJob.endDate).toLocaleDateString(undefined, { timeZone: 'UTC' }) }}]
                          </small>
                        </template>
                        <template v-else>
                          -
                        </template>
                      </td>
                      <td :rowspan="validationJob.retailersCount + 1" class="text-center">
                        <dot :active="validationJob.success" />
                      </td>
                      <td :rowspan="validationJob.retailersCount + 1"><code>{{ validationJob.log }}</code></td>
                      <td><b>Total</b></td>
                      <td class="text-right"><b>{{ validationJob.validations.length }}</b></td>
                      <td class="text-right text-success"><b>{{ validationJob.counts.approved }}</b></td>
                      <td class="text-right text-warning"><b>{{ validationJob.counts.pending }}</b></td>
                      <td class="text-right text-danger"><b>{{ validationJob.counts.declined }}</b></td>
                      <td class="text-right text-secondary"><b>{{ validationJob.counts.deleted }}</b></td>
                      <td class="text-right"><b>{{ validationJob.counts.undefined }}</b></td>
                      <td class="text-right text-primary"><b>{{ validationJob.counts.withEvent }}</b></td>
                    </tr>
                    <!-- Retailer -->
                    <tr v-for="({ validations, counts, retailer }, retailerId) in validationJob.validationsByRetailer" :key="retailerId">
                      <td class="text-nowrap">
                        <template v-if="retailer">
                          <region-flag :code="retailer.region.code" />
                          <template v-if="retailer.imgSmall.url">
                            <img :src="retailer.imgSmall.url" class="retailer-img-small" />
                          </template>
                          {{ retailer.name }}
                          <span class="badge badge-light">
                            {{ retailer.service }}
                          </span>
                        </template>
                        <template v-else>
                          <span class="text-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa" fill="currentColor"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"/></svg>
                            Missing retailer
                          </span>
                        </template>
                      </td>
                      <td class="text-right"><b>{{ validations.length }}</b></td>
                      <td class="text-right text-success">{{ counts.approved }}</td>
                      <td class="text-right text-warning">{{ counts.pending }}</td>
                      <td class="text-right text-danger">{{ counts.declined }}</td>
                      <td class="text-right text-secondary">{{ counts.deleted }}</td>
                      <td class="text-right">{{ counts.undefined }}</td>
                      <td class="text-right text-primary"><b>{{ counts.withEvent }}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import DateRangePicker from '../shared/DateRangePicker.vue'
import moment from 'moment'
import LocaleCurrency from 'locale-currency'
import { BTabs, BTab } from 'bootstrap-vue'
import Dot from '../shared/Dot.vue'
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import xrange from 'highcharts/modules/xrange'
import noData from 'highcharts/modules/no-data-to-display'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'
import flag from 'country-code-emoji'
xrange(Highcharts)
noData(Highcharts)

export default {
  components: { MdSpinner, RegionFlag, DateRangePicker, BTabs, BTab, Dot, highcharts: Chart },
  props: ['id'],
  data: function() {
    const startDate = this.$route.query.startDate
      ? new Date(this.$route.query.startDate)
      : moment.utc().startOf('month').toDate()
    const endDate = this.$route.query.endDate
      ? new Date(this.$route.query.endDate)
      : moment.utc().endOf('month').startOf('day').toDate()

    return {
      validationDataSource: null,
      validationDataSourceLoading: false,
      validationDataSourceError: null,
      validationJobs: [],
      validationJobsLoading: false,
      validationJobsError: null,
      dateRange: {
        startDate: startDate,
        endDate: endDate
      },
      ranges: {
        'This month': [moment.utc().startOf('month').toDate(), moment.utc().endOf('month').startOf('day').toDate()],
        'Last month': [moment.utc().subtract(1, 'month').startOf('month').toDate(), moment.utc().subtract(1, 'month').endOf('month').startOf('day').toDate()],
        'This year': [moment.utc().startOf('year').toDate(), moment.utc().endOf('year').startOf('day').toDate()],
        'Last year': [moment.utc().subtract(1, 'year').startOf('year').toDate(), moment.utc().subtract(1, 'year').endOf('year').startOf('day').toDate()]
      }
    }
  },
  computed: {
    // Data for summary tab
    validations: function() {
      return this.validationJobs.map(job => job.validations).flat()
    },
    validationsByRetailer: function() {
      const validationsByRetailer = {}

      this.validations.forEach(validation => {
        validationsByRetailer[validation.retailerId] = validationsByRetailer[validation.retailerId] || { validations: [] }
        validationsByRetailer[validation.retailerId].validations.push(validation)

        if (!validationsByRetailer[validation.retailerId].retailer) {
          validationsByRetailer[validation.retailerId].retailer = validation.retailer
        }
      })

      return validationsByRetailer
    },
    // Retailers
    retailers: function() {
      // Get retailers from validations uniq
      return new Array(...new Set(this.validations.map(validation => validation.retailer))).filter(retailer => retailer)
    },
    // Show validation jobs chart if there is at least one job with dates
    showValidationJobsChart: function() {
      return this.validationJobs.some(validationJob => validationJob.startDate && validationJob.endDate)
    },
    // Options for validation jobs xchart chart
    validationJobsChartOptions: function() {
      const series = {
        borderRadius: 0,
        pointWidth: 30,
        zones: [{ color: '#0275d8' }],
        data: this.validationJobs.map(validationJob => {
          return {
            id: validationJob.id,
            x: new Date(validationJob.startDate).getTime(),
            x2: new Date(validationJob.endDate).getTime(),
            y: 0,
            color: validationJob.success ? '#28a745' : '#dc3545'
          }
        })
      }

      return {
        chart: {
          type: 'xrange',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: null,
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: 'Job #{point.id}</b><br/>'
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: null,
          categories: ['']
        },
        series
      }
    },
    // Options for validations column chart by status
    validationsByStatusChartOptions: function() {
      // Build series data
      const statuses = ['approved', 'pending', 'declined', 'deleted', 'undefined']
      const counts = {}
      const seriesData = statuses.reduce((acc, status) => {
        acc[status] = []
        return acc
      }, {})

      this.validations.forEach(validation => {
        const date = new Date(validation.transactionDate).setUTCHours(0, 0, 0, 0)
        if (!counts[date]) {
          counts[date] = statuses.reduce((acc, status) => {
            acc[status] = 0
            return acc
          }, {})
        }
        const status = validation.status || 'undefined'
        if (statuses.includes(status)) {
          counts[date][status]++
        }
      })

      Object.keys(counts).forEach(date => {
        statuses.forEach(status => {
          seriesData[status].push([Number(date), counts[date][status]])
        })
      })

      const series = statuses.map(status => ({
        name: status,
        data: seriesData[status],
        color: status === 'approved' ? '#28a745' : status === 'pending' ? '#ffc107' : status === 'declined' ? '#dc3545' : status === 'deleted' ? '#6c757d' : '#6c757d'
      }))

      return {
        chart: {
          type: 'column',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: null,
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          min: new Date(this.dateRange.startDate).getTime(),
          max: new Date(this.dateRange.endDate).getTime()
        },
        yAxis: {
          title: null
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        series
      }
    },
    // Options for validations column chart by retailer
    validationsByRetailerChartOptions: function() {
      // Build series data
      const retailerIds = Object.keys(this.validationsByRetailer)
      const counts = {}
      const seriesData = retailerIds.reduce((acc, retailerId) => {
        acc[retailerId] = []
        return acc
      }, {})

      this.validations.forEach(validation => {
        const date = new Date(validation.transactionDate).setUTCHours(0, 0, 0, 0)
        if (!counts[date]) {
          counts[date] = retailerIds.reduce((acc, retailerId) => {
            acc[retailerId] = 0
            return acc
          }, {})
        }
        counts[date][validation.retailerId]++
      })

      Object.keys(counts).forEach(date => {
        retailerIds.forEach(retailerId => {
          seriesData[retailerId].push([Number(date), counts[date][retailerId]])
        })
      })

      const series = retailerIds.map(retailerId => {
        console.log(retailerId)
        const retailer = retailerId ? this.retailers.find(retailer => retailer.id === Number(retailerId)) : null
        return {
          name: retailer ? `${this.flagEmoji(retailer.region.code)} ${retailer.name} <span class="badge badge-light">${retailer.service}</span>` : 'Missing retailer',
          data: seriesData[retailerId].sort((a, b) => a[0] - b[0])
        }
      })

      return {
        chart: {
          type: 'column',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: null,
        credits: {
          enabled: false
        },
        legend: {
          enabled: true
        },
        xAxis: {
          type: 'datetime',
          min: new Date(this.dateRange.startDate).getTime(),
          max: new Date(this.dateRange.endDate).getTime()
        },
        yAxis: {
          title: null
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        series
      }
    },
    // Validation jobs with validations grouped by retailer + various counts
    validationJobsWithGroupedRetailers: function() {
      const validationJobsWithGroupedRetailers = cloneDeep(this.validationJobs)

      validationJobsWithGroupedRetailers.forEach(validationJob => {
        validationJob.validationsByRetailer = validationJob.validations.reduce((result, validation) => {
          result[validation.retailerId] = result[validation.retailerId] || {}
          result[validation.retailerId].validations = result[validation.retailerId].validations || []
          result[validation.retailerId].validations.push(validation)

          if (!result[validation.retailerId].retailer) {
            result[validation.retailerId].retailer = validation.retailer
          }

          return result
        }, {})

        validationJob.retailersCount = Object.keys(validationJob.validationsByRetailer).length

        validationJob.counts = {}

        for (const retailerData of Object.values(validationJob.validationsByRetailer)) {
          // Retailer specific count
          retailerData.counts = {
            approved: retailerData.validations.filter(validation => validation.status === 'approved').length,
            pending: retailerData.validations.filter(validation => validation.status === 'pending').length,
            declined: retailerData.validations.filter(validation => validation.status === 'declined').length,
            deleted: retailerData.validations.filter(validation => validation.status === 'deleted').length,
            undefined: retailerData.validations.filter(validation => !validation.status).length,
            withEvent: retailerData.validations.filter(validation => validation.eventId !== null).length
          }

          // Global count
          validationJob.counts.approved = validationJob.counts.approved || 0
          validationJob.counts.approved += retailerData.counts.approved
          validationJob.counts.pending = validationJob.counts.pending || 0
          validationJob.counts.pending += retailerData.counts.pending
          validationJob.counts.declined = validationJob.counts.declined || 0
          validationJob.counts.declined += retailerData.counts.declined
          validationJob.counts.deleted = validationJob.counts.deleted || 0
          validationJob.counts.deleted += retailerData.counts.deleted
          validationJob.counts.undefined = validationJob.counts.undefined || 0
          validationJob.counts.undefined += retailerData.counts.undefined
          validationJob.counts.withEvent = validationJob.counts.withEvent || 0
          validationJob.counts.withEvent += retailerData.counts.withEvent
        }
      })

      return validationJobsWithGroupedRetailers
    }
  },
  methods: {
    // Load validation data source
    loadValidationDataSource: async function() {
      this.validationDataSourceLoading = true
      this.validationDataSourceError = null

      const query = gql`
        query validationDataSourceShow ($id: Int!){
          validationDataSource(id: $id) {
            id
            name
            lastJobDate
            lastJobStatus
            retailersCount
          }
        }
      `

      const variables = {
        id: Number(this.id)
      }

      try {
        const { data } = await client.query({ query, variables })

        this.validationDataSource = Object.freeze(data.validationDataSource)
      } catch (error) {
        this.validationDataSourceError = error
        throw error
      } finally {
        this.validationDataSourceLoading = false
      }
    },
    // Load validation jobs for current dateRange
    loadValidationJobs: async function() {
      this.validationJobsLoading = true
      this.validationJobsError = null

      const query = gql`
        query validationDataSourceShowValidationJobs ($validationDataSourceId: Int!, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!){
          validationJobs(validationDataSourceId: $validationDataSourceId, startDate: $startDate, endDate: $endDate) {
            id
            success
            log
            startDate
            endDate
            validations(startDate: $startDate, endDate: $endDate) {
              id
              transactionDate
              retailerId
              retailer {
                id
                name
                service
                imgSmall
                region {
                  code
                }
              }
              amount
              eventId
              status
            }
          }
        }
      `

      const variables = {
        validationDataSourceId: Number(this.id),
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate
      }

      try {
        const { data } = await client.query({ query, variables })

        this.validationJobs = Object.freeze(data.validationJobs)
      } catch (error) {
        this.validationJobsError = error
        throw error
      } finally {
        this.validationJobsLoading = false
      }
    },
    // Get flag emoji for region
    flagEmoji: function(code) {
      if (code === 'INTERNATIONAL') {
        return '🌍'
      } else {
        return flag(code)
      }
    }
  },
  filters: {
    price: function(value, region) {
      if (region) {
        const currency = LocaleCurrency.getCurrency(region.code)
        if (value !== undefined && currency) {
          return value.toLocaleString(undefined, {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: 2
          })
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    }
  },
  created: function() {
    this.loadValidationDataSource()
  },
  watch: {
    dateRange: function(dateRange) {
      this.loadValidationJobs()
    }
  }
}
</script>

<style>
.retailer-img-small {
  max-height: 16px;
  margin-top: -3px;
}
</style>
