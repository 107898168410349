
<div>
  <transition name="fade" mode="out-in">
    <div v-if="consolidationRetailOutletDataSourceLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">

      <form @submit.prevent="submit">
        <div class="form-group row">
          <div class="col-sm-2">{{ $t('attributes.consolidation_retail_outlet_data_source.retailer') }}</div>
          <div class="col-lg-4 col-sm-6 col-12">
            <template v-if="retailer">
              <region-flag :code="retailer.region.code" />
              {{ retailer.name }}
              <span class="badge badge-light">
                {{ retailer.service }}
              </span>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label for="selectRetailerDataSource" class="col-sm-2 col-form-label">{{ $t('attributes.consolidation_retail_outlet_data_source.retailer_data_source_id') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <select
              v-model="consolidationRetailOutletDataSource.retailerDataSourceId"
              class="custom-select"
              id="selectRetailerDataSource"
              :class="{ 'is-invalid' : consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.retailerDataSourceId }"
            >
              <template v-if="retailer">
                <option v-for="retailerDataSource in retailer.retailerDataSources" :key="retailerDataSource.id" :value="retailerDataSource.id">{{ retailerDataSource.id }}</option>
              </template>
            </select>
            <div
              v-if="consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.retailerDataSourceId"
              class="invalid-feedback"
            >{{ tErrors('consolidation_retail_outlet_data_source', 'retailer_data_source_id', consolidationRetailOutletDataSourceSubmitError.retailerDataSourceId) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="selectRetailOutletTypology" class="col-sm-2 col-form-label">{{ $t('attributes.consolidation_retail_outlet_data_source.retail_outlet_typology') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <select
              v-model="consolidationRetailOutletDataSource.retailOutletTypology"
              class="custom-select"
              id="selectRetailOutletTypology"
              :class="{ 'is-invalid' : consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.retailOutletTypology }"
            >
              <option v-for="option in retailOutletTypologyOptions" :key="option" :value="option">{{ option }}</option>
            </select>
            <div
              v-if="consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.retailOutletTypology"
              class="invalid-feedback"
            >{{ tErrors('consolidation_retail_outlet_data_source', 'retail_outlet_typology', consolidationRetailOutletDataSourceSubmitError.retailOutletTypology) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="selectConsolidationProvider" class="col-sm-2 col-form-label">{{ $t('attributes.consolidation_retail_outlet_data_source.consolidation_provider') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <select
            v-model="consolidationRetailOutletDataSource.consolidationProvider"
              class="custom-select"
              id="selectConsolidationProvider"
              :class="{ 'is-invalid' : consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.consolidationProvider }"
            >
              <option v-for="option in consolidationProviderOptions" :key="option" :value="option">{{ option }}</option>
            </select>
            <div
              v-if="consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.consolidationProvider"
              class="invalid-feedback"
            >{{ tErrors('consolidation_retail_outlet_data_source', 'consolidation_provider', consolidationRetailOutletDataSourceSubmitError.consolidationProvider) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="selectMatchingFormula" class="col-sm-2 col-form-label">{{ $t('attributes.consolidation_retail_outlet_data_source.matching_formula') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <select
              v-model="consolidationRetailOutletDataSource.matchingFormula"
              class="custom-select"
              id="selectMatchingFormula"
              :class="{ 'is-invalid' : consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.matchingFormula }"
            >
              <option v-for="option in matchingFormulaOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          <div
            v-if="consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.matchingFormula"
            class="invalid-feedback"
          >{{ tErrors('consolidation_retail_outlet_data_source', 'matching_formula', consolidationRetailOutletDataSourceSubmitError.matchingFormula) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputUrl" class="col-sm-2 col-form-label">{{ $t('attributes.consolidation_retail_outlet_data_source.url') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input
              v-model="consolidationRetailOutletDataSource.url"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.url }"
              id="inputUrl"
              :placeholder="$t('attributes.consolidation_retail_outlet_data_source.url')"
            >
            <div
              v-if="consolidationRetailOutletDataSourceSubmitError && consolidationRetailOutletDataSourceSubmitError.url"
              class="invalid-feedback"
            >{{ tErrors('consolidation_retail_outlet_data_source', 'url', consolidationRetailOutletDataSourceSubmitError.url) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label
            for="multiselect-consolidationFields"
            class="col-sm-2 col-form-label"
          >{{ $t('attributes.consolidation_retail_outlet_data_source.consolidation_fields') }}</label>
          <div class="col-lg-4 col-sm-4 col-12">
            <multiselect
              v-model="consolidationRetailOutletDataSource.consolidationFields"
              :options="consolidationFieldValues"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :options-limit="10000"
              id="multiselect-consolidationFields"
            >
            </multiselect>
          </div>
        </div>

        <div class="form-group row">
          <label for="checkboxActive" class="col-sm-2">{{ $t('attributes.consolidation_retail_outlet_data_source.active') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="consolidationRetailOutletDataSource.active" type="checkbox" class="custom-control-input" id="checkboxActive">
              <label class="custom-control-label" for="checkboxActive"></label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="consolidationRetailOutletDataSourceSubmitLoading">
            <md-spinner v-if="consolidationRetailOutletDataSourceSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>

    </div>
  </transition>
</div>
