
<div class="d-flex h-100 align-items-center">
  <form class="form-signin" @submit.prevent="resetPassword">
    <div class="form-header d-flex flex-column align-items-center justify-content-center">
      <c2b-logo class="logo" />
      HUB
    </div>

    <input
      type="password"
      id="inputNewPassword"
      class="form-control"
      :class="{ 'is-invalid' : passwordSubmitError && passwordSubmitError.password }"
      :placeholder="$t('attributes.user.new_password')"
      v-model="password"
      required>

    <input
      type="password"
      id="inputNewPasswordConfirmation"
      class="form-control"
      :class="{ 'is-invalid' : passwordSubmitError && passwordSubmitError.passwordConfirmation }"
      :placeholder="$t('attributes.user.new_password_confirmation')"
      v-model="passwordConfirmation"
      required>

    <div v-if="passwordSubmitError" class="invalid-feedback d-block">
      <div v-if="passwordSubmitError && passwordSubmitError.resetPasswordToken">{{ tErrors('user', 'resetPasswordToken', passwordSubmitError.resetPasswordToken) }}</div>
      <div v-if="passwordSubmitError && passwordSubmitError.password">{{ tErrors('user', 'password', passwordSubmitError.password) }}</div>
      <div v-if="passwordSubmitError && passwordSubmitError.passwordConfirmation">{{ tErrors('user', 'password_confirmation', passwordSubmitError.passwordConfirmation) }}</div>
    </div>

    <button class="btn btn-primary btn-block position-relative" type="submit" :disabled="passwordSubmitLoading">
      <div v-if="passwordSubmitLoading" class="position-absolute">
        <md-spinner md-indeterminate :diameter="24" :stroke-width="5"></md-spinner>
      </div>
      {{ $t('devise.passwords.edit_action') }}
    </button>

    <div class="mt-4 text-center">
      <router-link :to="{ name: 'login' }">{{ $t('devise.links.login') }}</router-link>
    </div>
  </form>
</div>
