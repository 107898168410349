<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="retailerDataSourceLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">

        <form @submit.prevent="submit">
          <div class="form-group row">
            <div class="col-sm-2">{{ $t('attributes.retailer_data_source.retailer') }}</div>
            <div class="col-lg-4 col-sm-6 col-12">
              <template v-if="retailer">
                <region-flag :code="retailer.region.code" />
                {{ retailer.name }}
                <span class="badge badge-light">
                  {{ retailer.service }}
                </span>
              </template>
            </div>
          </div>

          <div class="form-group row">
            <label for="selectLanguage" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_data_source.language') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <select v-model="retailerDataSource.languageId" class="custom-select" id="selectLanguage" :class="{ 'is-invalid' : retailerDataSourceSubmitError && retailerDataSourceSubmitError.language }">
                <template v-if="retailer">
                  <option v-for="language in retailer.region.languages" :key="language.code" :value="language.id">{{ language.code }}</option>
                </template>
              </select>
              <div v-if="retailerDataSourceSubmitError && retailerDataSourceSubmitError.language" class="invalid-feedback">{{ tErrors('retailer_data_source', 'language', retailerDataSourceSubmitError.language) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="selectOrigin" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_data_source.origin') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <select v-model="retailerDataSource.origin" class="custom-select" id="selectOrigin">
                <option v-for="option in originOptions" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputComment" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_data_source.comment') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="retailerDataSource.comment" type="text" class="form-control" :class="{ 'is-invalid' : retailerDataSourceSubmitError && retailerDataSourceSubmitError.comment }" id="inputComment" :placeholder="$t('attributes.retailer_data_source.comment')">
              <div v-if="retailerDataSourceSubmitError && retailerDataSourceSubmitError.comment" class="invalid-feedback">{{ tErrors('retailer_data_source', 'comment', retailerDataSourceSubmitError.comment) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputUrl" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_data_source.url') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="retailerDataSource.url" type="text" class="form-control" :class="{ 'is-invalid' : retailerDataSourceSubmitError && retailerDataSourceSubmitError.url }" id="inputUrl" :placeholder="$t('attributes.retailer_data_source.url')">
              <div v-if="retailerDataSourceSubmitError && retailerDataSourceSubmitError.url" class="invalid-feedback">{{ tErrors('retailer_data_source', 'url', retailerDataSourceSubmitError.url) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="checkboxTrustedEan" class="col-sm-2">{{ $t('attributes.retailer_data_source.trusted_ean') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="retailerDataSource.trustedEan" type="checkbox" class="custom-control-input" id="checkboxTrustedEan">
                <label class="custom-control-label" for="checkboxTrustedEan"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="checkboxTrustedUpc" class="col-sm-2">{{ $t('attributes.retailer_data_source.trusted_upc') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="retailerDataSource.trustedUpc" type="checkbox" class="custom-control-input" id="checkboxTrustedUpc">
                <label class="custom-control-label" for="checkboxTrustedUpc"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="checkboxExportFtpScrapedDistributionRo" class="col-sm-2">{{ $t('attributes.retailer_data_source.export_ftp_scraped_distribution_ro') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="retailerDataSource.exportFtpScrapedDistributionRo" type="checkbox" class="custom-control-input" id="checkboxExportFtpScrapedDistributionRo">
                <label class="custom-control-label" for="checkboxExportFtpScrapedDistributionRo"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="checkboxExportFtpScrapedFittingRo" class="col-sm-2">{{ $t('attributes.retailer_data_source.export_ftp_scraped_fitting_ro') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="retailerDataSource.exportFtpScrapedFittingRo" type="checkbox" class="custom-control-input" id="checkboxExportFtpScrapedFittingRo">
                <label class="custom-control-label" for="checkboxExportFtpScrapedFittingRo"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="overrideSourceUtmsCheckbox" class="col-sm-2">{{ $t('attributes.retailer_data_source.override_source_utms') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="retailerDataSource.overrideSourceUtms" type="checkbox" class="custom-control-input" id="overrideSourceUtmsCheckbox">
                <label class="custom-control-label" for="overrideSourceUtmsCheckbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="checkboxDluRoFittersExportable" class="col-sm-2">{{ $t('attributes.retailer_data_source.dlu_ro_fitters_exportable') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="retailerDataSource.dluRoFittersExportable" type="checkbox" class="custom-control-input" id="checkboxDluRoFittersExportable">
                <label class="custom-control-label" for="checkboxDluRoFittersExportable"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="checkboxActive" class="col-sm-2">{{ $t('attributes.retailer_data_source.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="retailerDataSource.active" type="checkbox" class="custom-control-input" id="checkboxActive">
                <label class="custom-control-label" for="checkboxActive"></label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="retailerDataSourceSubmitLoading">
              <md-spinner v-if="retailerDataSourceSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { MdSpinner, RegionFlag },
  props: ['action'],
  data: function() {
    return {
      retailerDataSource: {
        languageId: null,
        origin: 'crawlkeywords',
        comment: null,
        url: null,
        trustedEan: false,
        trustedUpc: false,
        dluRoFittersExportable: false,
        exportFtpScrapedDistributionRo: false,
        exportFtpScrapedFittingRo: false,
        active: false,
        overrideSourceUtms: true
      },
      retailerDataSourceLoading: false,
      retailerDataSourceError: null,
      retailer: null,
      retailerLoading: false,
      retailerError: null,
      retailerDataSourceSubmitLoading: false,
      retailerDataSourceSubmitError: null,
      originOptions: ['crawlkeywords', 'crawlcategories', 'feed', 'bor']
    }
  },
  methods: {
    loadData: async function({ retailerDataSource = true } = {}) {
      this.retailerLoading = true
      this.retailerError = null

      if (retailerDataSource) {
        this.retailerDataSourceLoading = true
        this.retailerDataSourceError = null
      }

      const query = gql`
        query($retailerDataSource: Boolean = true, $retailerDataSourceId: Int = null, $retailerId: Int!) {
          retailerDataSource(id: $retailerDataSourceId) @include(if: $retailerDataSource) {
            language {
              id
              code
            }
            origin
            comment
            url
            trustedEan
            trustedUpc
            dluRoFittersExportable
            exportFtpScrapedDistributionRo
            exportFtpScrapedFittingRo
            active
            overrideSourceUtms
          }
          retailer(id: $retailerId) {
            name
            service
            region {
              code
              languages {
                id
                code
              }
            }
          }
        }
      `

      const variables = {
        retailerDataSource,
        retailerDataSourceId: retailerDataSource ? parseInt(this.$route.params.retailer_data_source_id) : undefined,
        retailerId: parseInt(this.$route.params.id)
      }

      try {
        const { data } = await client.query({ query, variables })

        this.retailer = Object.freeze(data.retailer)

        if (retailerDataSource) {
          this.retailerDataSource = { ...cloneDeep(data.retailerDataSource), languageId: data.retailerDataSource.language.id }

          // Init language select value
          if (!this.retailerDataSource.language.id) {
            this.retailerDataSource.languageId = this.retailer.region.languages[0].id
          }
        }
      } catch (error) {
        this.retailerError = error
        throw error
      } finally {
        this.retailerLoading = false
        this.retailerDataSourceLoading = false
      }
    },
    submit: async function() {
      this.retailerDataSourceSubmitLoading = true
      this.retailerDataSourceSubmitError = null

      const variables = {
        input: {
          attributes: {
            active: this.retailerDataSource.active,
            languageId: this.retailerDataSource.languageId,
            origin: this.retailerDataSource.origin,
            comment: this.retailerDataSource.comment,
            url: this.retailerDataSource.url,
            retailerId: Number(this.$route.params.id),
            trustedEan: this.retailerDataSource.trustedEan,
            trustedUpc: this.retailerDataSource.trustedUpc,
            dluRoFittersExportable: this.retailerDataSource.dluRoFittersExportable,
            exportFtpScrapedDistributionRo: this.retailerDataSource.exportFtpScrapedDistributionRo,
            exportFtpScrapedFittingRo: this.retailerDataSource.exportFtpScrapedFittingRo,
            overrideSourceUtms: this.retailerDataSource.overrideSourceUtms
          }
        }
      }

      if (this.action === 'new') {
        const mutation = gql`
          mutation($input: CreateRetailerDataSourceInput!) {
            createRetailerDataSource(input: $input) {
              retailerDataSource {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.createRetailerDataSource.errors) {
            this.retailerDataSourceSubmitError = data.createRetailerDataSource.errors
          } else {
            this.$router.push({ name: 'retailer', params: { id: this.$route.params.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.retailer_data_source.create'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.retailerDataSourceSubmitError = error
          throw error
        } finally {
          this.retailerDataSourceSubmitLoading = false
        }
      } else if (this.action === 'edit') {
        variables.input.id = this.$route.params.retailer_data_source_id

        const mutation = gql`
          mutation($input: UpdateRetailerDataSourceInput!) {
            updateRetailerDataSource(input: $input) {
              retailerDataSource {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.updateRetailerDataSource.errors) {
            this.retailerDataSourceSubmitError = data.updateRetailerDataSource.errors
          } else {
            this.$router.push({ name: 'retailer', params: { id: this.$route.params.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.retailer_data_source.update'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.retailerDataSourceSubmitError = error
          throw error
        } finally {
          this.retailerDataSourceSubmitLoading = false
        }
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    }

    this.loadData({ retailerDataSource: false })
  }
}
</script>
