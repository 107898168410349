
<div>
  <transition name="fade" mode="out-in">
    <div v-if="retailerContactLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">

      <form @submit.prevent="submit">
        <div class="form-group row">
          <div class="col-sm-2">{{ $t('attributes.retailer_contact.retailer') }}</div>
          <div class="col-lg-4 col-sm-6 col-12">
            <template v-if="retailer">
              <region-flag :code="retailer.region.code" />
              {{ retailer.name }}
              <span class="badge badge-light">
                {{ retailer.service }}
              </span>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputEmail" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_contact.email') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="retailerContact.email" type="text" class="form-control" :class="{ 'is-invalid' : retailerContactSubmitError && retailerContactSubmitError.email }" id="inputEmail" :placeholder="$t('attributes.retailer_contact.email')">
            <div v-if="retailerContactSubmitError && retailerContactSubmitError.email" class="invalid-feedback">{{ tErrors('retailer_contact', 'email', retailerContactSubmitError.email) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputLastName" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_contact.last_name') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="retailerContact.lastName" type="text" class="form-control" :class="{ 'is-invalid' : retailerContactSubmitError && retailerContactSubmitError.lastName }" id="inputLastName" :placeholder="$t('attributes.retailer_contact.last_name')">
            <div v-if="retailerContactSubmitError && retailerContactSubmitError.lastName" class="invalid-feedback">{{ tErrors('retailer_contact', 'last_name', retailerContactSubmitError.lastName) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputFirstName" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_contact.first_name') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="retailerContact.firstName" type="text" class="form-control" :class="{ 'is-invalid' : retailerContactSubmitError && retailerContactSubmitError.firstName }" id="inputFirstName" :placeholder="$t('attributes.retailer_contact.first_name')">
            <div v-if="retailerContactSubmitError && retailerContactSubmitError.firstName" class="invalid-feedback">{{ tErrors('retailer_contact', 'first_name', retailerContactSubmitError.firstName) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputComment" class="col-sm-2 col-form-label">{{ $t('attributes.retailer_contact.comment') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="retailerContact.comment" type="text" class="form-control" :class="{ 'is-invalid' : retailerContactSubmitError && retailerContactSubmitError.comment }" id="inputComment" :placeholder="$t('attributes.retailer_contact.comment')">
            <div v-if="retailerContactSubmitError && retailerContactSubmitError.comment" class="invalid-feedback">{{ tErrors('retailer_contact', 'comment', retailerContactSubmitError.comment) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="checkboxSendReport" class="col-sm-2">{{ $t('attributes.retailer_contact.send_report') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch">
              <input v-model="retailerContact.sendReport" type="checkbox" class="custom-control-input" id="checkboxSendReport">
              <label class="custom-control-label" for="checkboxSendReport"></label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="retailerContactSubmitLoading">
            <md-spinner v-if="retailerContactSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>

    </div>
  </transition>
</div>
