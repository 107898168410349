
<div>
  <transition name="fade" mode="out-in">
    <div v-if="userLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ user.email }}</h5>
        <router-link v-if="$can('update', 'User')" :to="{ name: 'edit_user', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <table class="table">
        <tr>
          <th>{{ $t('attributes.user.id') }}</th>
          <td>{{ user.id }}</td>
        </tr>
        <tr>
          <th>{{ $t('attributes.user.email') }}</th>
          <td>{{ user.email }}</td>
        </tr>
        <tr>
          <th>{{ $t('attributes.user.role') }}</th>
          <td class="gutters-xs">
            <span class="badge badge-light">
              {{ user.role }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ $t('attributes.user.active') }}</th>
          <td>
            <dot :active="user.active" />
          </td>
        </tr>
      </table>
    </div>
  </transition>
</div>
