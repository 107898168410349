
<div class="d-flex h-100 align-items-center">
  <form class="form-signin" @submit.prevent="newPassword">
    <div class="form-header d-flex flex-column align-items-center justify-content-center">
      <c2b-logo class="logo" />
      HUB
    </div>

    <input type="email" id="inputEmail" class="form-control"
      :class="newPasswordError ? 'is-invalid' : ''"
      :placeholder="$t('attributes.user.email')"
      v-model="email" required autofocus>

    <div v-if="newPasswordError" class="invalid-feedback">
      {{ tErrors('user', 'email', newPasswordError.data.email) }}
    </div>

    <button class="btn btn-primary btn-block" type="submit" :disabled="newPasswordLoading">
      <div class="position-relative">
        <div v-if="newPasswordLoading" class="position-absolute h-100 d-flex align-items-center">
          <md-spinner md-indeterminate :diameter="24" :stroke-width="5"></md-spinner>
        </div>
        <div style="margin: 0 24px;">
          {{ $t('devise.passwords.new_action') }}
        </div>
      </div>
    </button>

    <div class="mt-4 text-center">
      <router-link :to="{ name: 'login' }">{{ $t('devise.links.login') }}</router-link>
    </div>
  </form>
</div>
