<template>
  <div>
    <div class="d-flex flex-wrap justify-content-between align-items-center gutters mb-4">
      <button @click="showFilters = !showFilters" class="btn btn-light">
        <div class="gutters-xs">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa" fill="currentColor"><path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z"/></svg>
          {{ $t('shared.actions.filter') }}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa icon-will-rotate fa-w-6" :class="{ 'icon-rotate': showFilters }" fill="currentColor"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>
        </div>
      </button>
      <div class="gutters-xs">
        <a :href="exportUrl" class="btn btn-secondary">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa" fill="currentColor"><path d="M384 128h-128V0L384 128zM256 160H384v304c0 26.51-21.49 48-48 48h-288C21.49 512 0 490.5 0 464v-416C0 21.49 21.49 0 48 0H224l.0039 128C224 145.7 238.3 160 256 160zM255 295L216 334.1V232c0-13.25-10.75-24-24-24S168 218.8 168 232v102.1L128.1 295C124.3 290.3 118.2 288 112 288S99.72 290.3 95.03 295c-9.375 9.375-9.375 24.56 0 33.94l80 80c9.375 9.375 24.56 9.375 33.94 0l80-80c9.375-9.375 9.375-24.56 0-33.94S264.4 285.7 255 295z"/></svg>
          {{ $t('shared.actions.export') }}
        </a>
        <router-link v-if="$can('create', 'Brand')" :to="{ name: 'new_brand' }" class="btn btn-success">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
          {{ $t('shared.actions.new_brand') }}
        </router-link>
      </div>
    </div>

    <transition-expand>
      <div v-show="showFilters" class="mb-4">
        <form @submit.prevent="updateFilters">
          <div class="form-row">
            <div class="col-lg-3 col-md-4 mb-3">
              <label for="filter-name">{{ $t('attributes.brand.name') }}</label>
              <input v-model="filters.name" type="text" class="form-control" id="filter-name" placeholder="Name">
            </div>

            <div class="col-lg-3 col-md-4 mb-3">
              <label for="filter-active">{{ $t('attributes.brand.active') }}</label>
              <select v-model="filters.active" class="custom-select">
                <option :value="null">{{ $t('shared.placeholders.select') }}</option>
                <option :value="false">Inactive</option>
                <option :value="true">Active</option>
              </select>
            </div>

            <div class="col-lg-3 col-md-4 mb-3">
              <label for="filter-region">{{ $t('attributes.brand.region') }}</label>
              <key-multiselect
                v-model="filters.regionIds"
                :multiple="true"
                :close-on-select="false"
                :options="regionOptions"
                label="name"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false"
                :loading="dataLoading">
                <template slot="option" slot-scope="props">
                  <region-flag :code="props.option.code" />
                  {{ props.option.name }}
                </template>
                <template v-slot:tag="{ option, remove }">
                  <span class="multiselect__tag">
                    <region-flag :code="option.code" />
                    {{ option.name }}
                    <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
                  </span>
                </template>
              </key-multiselect>
            </div>

            <div class="col-lg-3 col-md-4 mb-3">
              <label for="filter-region">{{ $t('attributes.brand.tags') }}</label>
              <key-multiselect
                v-model="filters.tagIds"
                :multiple="true"
                :close-on-select="false"
                :options="tags"
                :custom-label="getTagName"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false"
                :loading="dataLoading">
              </key-multiselect>
            </div>
          </div>

          <div class="gutters-xs">
            <button class="btn btn-primary" type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa" fill="currentColor"><path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>
              {{ $t('shared.actions.apply') }}
            </button>
            <button class="btn btn-secondary" type="button" @click="resetFilters">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa" fill="currentColor"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
              {{ $t('shared.actions.reset') }}
            </button>
          </div>
        </form>
      </div>
    </transition-expand>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="dataLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="dataLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.brand.id') }}</th-sortable>
              <th class="table-col-shrink"></th>
              <th class="table-col-shrink"></th>
              <th-sortable sort-key="name">{{ $t('attributes.brand.name') }}</th-sortable>
              <th-sortable sort-key="region_id" class="table-col-shrink">{{ $t('attributes.brand.region') }}</th-sortable>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="brandsError" key="1">
              <tr>
                <td colspan="6" class="text-center alert-danger">
                  <template v-if="brandsError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internal_server_error') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else key="2">
              <tr v-if="!dataLoading && brands.length === 0">
                <td colspan="6" class="text-center alert-warning">
                  {{ $t('shared.warnings.no_brand') }}
                </td>
              </tr>
              <tr v-else v-for="brand in brands" :key="brand.id">
                <th>{{ brand.id }}</th>
                <td class="text-center">
                  <dot :active="brand.active" />
                </td>
                <td>
                  <template v-if="brand.img.url">
                    <img :src="brand.img.url" class="td-img" />
                  </template>
                </td>
                <td>{{ brand.name }}</td>
                <td class="text-center">
                  <region-flag :code="brand.region.code" />
                </td>
                <td>
                  <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                    <template v-slot:button-content>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-w-14" fill="currentColor"><path d="M64 360C94.93 360 120 385.1 120 416C120 446.9 94.93 472 64 472C33.07 472 8 446.9 8 416C8 385.1 33.07 360 64 360zM64 200C94.93 200 120 225.1 120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200zM64 152C33.07 152 8 126.9 8 96C8 65.07 33.07 40 64 40C94.93 40 120 65.07 120 96C120 126.9 94.93 152 64 152z"/></svg>
                    </template>
                    <router-link :to="{ name: 'brand', params: { id: brand.id } }" class="dropdown-item">
                      {{ $t('shared.actions.show') }}
                    </router-link>
                    <router-link v-if="$can('update', 'Brand')" :to="{ name: 'edit_brand', params: { id: brand.id } }" class="dropdown-item">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import TransitionExpand from '../shared/TransitionExpand.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import Pagination from '../shared/Pagination.vue'
import { BDropdown } from 'bootstrap-vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import ThSortable from '../shared/ThSortable.vue'
import i18n from '../../i18n.js'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { TransitionExpand, MdSpinner, KeyMultiselect, Pagination, BDropdown, Dot, RegionFlag, ThSortable },
  data: function() {
    return {
      brands: [],
      brandsError: null,
      dataLoading: false,
      regions: [],
      regionsError: null,
      tags: [],
      tagsError: null,
      totalRows: 0,
      perPage: 20,
      showFilters: true,
      filters: {
        name: null,
        active: null,
        regionIds: [],
        tagIds: []
      }
    }
  },
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    },
    // Get CSV export url
    exportUrl: function() {
      const url = new URL('/api/brands/export', window.location.origin)

      // Filters
      if (this.$route.query.name) url.searchParams.set('name', this.$route.query.name)
      if (this.$route.query.active) url.searchParams.set('active', this.$route.query.active)
      if (this.$route.query.regionIds) url.searchParams.set('region_ids', this.$route.query.regionIds)
      if (this.$route.query.tagIds) url.searchParams.set('tag_ids', this.$route.query.tagIds)

      // Sort
      if (this.$route.query.sort) url.searchParams.set('sort', this.$route.query.sort)
      if (this.$route.query.sort_direction) url.searchParams.set('sort_direction', this.$route.query.sort_direction)

      return url.href
    }
  },
  methods: {
    // Load brands using url query params
    loadData: async function() {
      this.dataLoading = true
      this.brandsError = null
      this.regionsError = null
      this.tagsError = null

      const query = gql`
        query brandsIndex ($page: Int!, $perPage: Int!, $name: String, $active: Boolean, $regionIds: [Int!], $tagIds: [Int!], $sort: String, $sortDirection: String){
          brandsPage(page: $page, perPage: $perPage, name: $name, active: $active, regionIds: $regionIds, tagIds: $tagIds, sort: $sort, sortDirection: $sortDirection) {
            nodesCount
            nodes {
              id
              active
              name
              region {
                id
                code
              }
              img
            }
          }
          regions {
            id
            code
            languages {
              id
              code
            }
          }
          tags {
            id
            key
            tagLanguageDatas {
              id
              name
              tagLanguage {
                id
                code
              }
            }
          }
        }
      `

      const variables = {
        page: Number(this.currentPage),
        perPage: Number(this.perPage),
        name: this.filters.name,
        active: this.filters.active,
        regionIds: this.filters.regionIds,
        tagIds: this.filters.tagIds
      }

      if (this.$route.query.sort) variables.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) variables.sortDirection = this.$route.query.sort_direction

      try {
        const { data } = await client.query({ query, variables })

        this.brands = Object.freeze(data.brandsPage.nodes)
        this.totalRows = data.brandsPage.nodesCount
        this.regions = Object.freeze(data.regions)
        this.tags = Object.freeze(data.tags)
      } catch (error) {
        this.brandsError = error
        throw error
      } finally {
        this.dataLoading = false
      }
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.name) query.name = this.filters.name
      if (this.filters.active !== null) query.active = this.filters.active.toString()
      if (this.filters.regionIds.length > 0) query.regionIds = this.filters.regionIds
      if (this.filters.tagIds.length > 0) query.tagIds = this.filters.tagIds

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: function() {
      this.filters.name = this.$route.query.name
      this.filters.active = this.$route.query.active ? this.$route.query.active === 'true' : null

      if (this.$route.query.regionIds) {
        if (this.$route.query.regionIds instanceof Array) {
          this.filters.regionIds = this.$route.query.regionIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.regionIds = [parseInt(this.$route.query.regionIds)]
        }
      } else {
        this.filters.regionIds = []
      }

      if (this.$route.query.tagIds) {
        if (this.$route.query.tagIds instanceof Array) {
          this.filters.tagIds = this.$route.query.tagIds.map(id => parseInt(id))
        } else {
          this.filters.tagIds = [parseInt(this.$route.query.tagIds)]
        }
      } else {
        this.filters.tagIds = []
      }
    },
    // Get tag name for the user language
    getTagName: function(tag) {
      const languageData = tag.tagLanguageDatas.find(data => data.tagLanguage.code === i18n.language())
      return languageData.name
    }
  },
  created: function() {
    this.updateFiltersFromQuery()
    this.loadData()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.updateFiltersFromQuery()
      this.loadData()
    }
  }
}
</script>
