
<div>
  <transition name="fade" mode="out-in">
    <div v-if="validationDataSourceLoading" key="1" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else key="2">
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ validationDataSource.name }}</h5>
        <router-link v-if="$can('update', 'ValidationDataSource')" :to="{ name: 'edit_validation_data_source', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <!-- Date range picker -->
      <div class="mb-3 text-center">
        <date-range-picker v-model="dateRange" :ranges="ranges" />
      </div>

      <!-- Tabs -->
      <b-tabs content-class="mt-3">
        <!-- Retailers tab -->
        <b-tab title="Retailers" active>
          <!-- Column chart -->
          <highcharts :options="validationsByRetailerChartOptions" style="width: 100%; height: 400px;" class="mb-3 border p-3"></highcharts>

          <!-- Table -->
          <div class="position-relative">
            <!-- Table spinner -->
            <div v-if="validationJobsLoading" style="position: absolute; width: 100%; height: 100%;">
              <div style="position: sticky; top: 0; text-align: center;">
                <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-bordered" :aria-busy="validationJobsLoading">
                <thead>
                  <tr>
                    <th>Retailer</th>
                    <th class="table-col-shrink">Validations</th>
                    <th class="table-col-shrink text-center">Amount</th>
                  </tr>
                </thead>
                <template v-if="validationJobs.length === 0">
                  <tbody>
                    <tr>
                      <td colspan="3" class="text-center table-warning">
                        {{ $t('shared.warnings.no_validation_job') }}
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-else>
                  <tbody>
                    <tr v-for="({ retailer, validations }, retailerId) in validationsByRetailer" :key="retailerId">
                      <td>
                        <template v-if="retailer">
                          <region-flag :code="retailer.region.code" />
                          <template v-if="retailer.imgSmall.url">
                            <img :src="retailer.imgSmall.url" class="retailer-img-small" />
                          </template>
                          {{ retailer.name }}
                          <span class="badge badge-light">
                            {{ retailer.service }}
                          </span>
                        </template>
                        <template v-else>
                          <span class="text-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa" fill="currentColor"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"/></svg>
                            Missing retailer
                          </span>
                        </template>
                      </td>
                      <td class="text-right">
                        {{ validations.length }}
                      </td>
                      <td class="text-right">
                        {{ validations.reduce((acc, validation) => acc + parseFloat(validation.amount), 0) | price(retailer ? retailer.region : null) }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th>
                      <th class="text-right">{{ validationJobs.reduce((acc, validationJob) => acc + validationJob.validations.length, 0) }}</th>
                      <th class="text-right">-</th><!-- TODO: sums splitted by currency? -->
                    </tr>
                  </tfoot>
                </template>
              </table>
            </div>
          </div>
        </b-tab>

        <!-- Jobs tab -->
        <b-tab title="Jobs & status">
          <!-- Xrange chart -->
          <template v-if="showValidationJobsChart">
            <highcharts :options="validationJobsChartOptions" style="width: 100%; height: 100px;" class="mb-3 border p-3"></highcharts>
          </template>

          <!-- Column chart -->
          <highcharts :options="validationsByStatusChartOptions" style="width: 100%; height: 300px;" class="mb-3 border p-3"></highcharts>

          <!-- Table -->
          <div class="position-relative">
            <!-- Table spinner -->
            <div v-if="validationJobsLoading" style="position: absolute; width: 100%; height: 100%;">
              <div style="position: sticky; top: 0; text-align: center;">
                <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-bordered" :aria-busy="validationJobsLoading">
                <thead>
                  <tr>
                    <th rowspan="2" class="table-col-shrink text-center">#</th>
                    <th rowspan="2" class="table-col-shrink text-nowrap text-center">Date range</th>
                    <th rowspan="2" class="table-col-shrink">Status</th>
                    <th rowspan="2">Log</th>
                    <th rowspan="2" class="table-col-shrink">Retailer</th>
                    <th rowspan="2" class="table-col-shrink">Validations</th>
                    <th colspan="5" class="text-center">Validations by status</th>
                    <th rowspan="2" class="table-col-shrink">Validations in stats database</th>
                  </tr>
                  <tr>
                    <th class="table-col-shrink">Approved</th>
                    <th class="table-col-shrink">Pending</th>
                    <th class="table-col-shrink">Declined</th>
                    <th class="table-col-shrink">Deleted</th>
                    <th class="table-col-shrink">Undefined</th>
                  </tr>
                </thead>
                <tbody v-if="validationJobs.length === 0">
                  <tr>
                    <td colspan="12" class="text-center table-warning">
                      {{ $t('shared.warnings.no_validation_job') }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else v-for="validationJob in validationJobsWithGroupedRetailers" :key="validationJob.id">
                  <!-- Total -->
                  <tr>
                    <th :rowspan="validationJob.retailersCount + 1">{{ validationJob.id }}</th>
                    <td :rowspan="validationJob.retailersCount + 1">
                      <template v-if="validationJob.startDate && validationJob.endDate">
                        <small class="text-nowrap">
                          [{{ new Date(validationJob.startDate).toLocaleDateString(undefined, { timeZone: 'UTC' }) }},
                        </small>
                        <small class="text-nowrap">
                          {{ new Date(validationJob.endDate).toLocaleDateString(undefined, { timeZone: 'UTC' }) }}]
                        </small>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                    <td :rowspan="validationJob.retailersCount + 1" class="text-center">
                      <dot :active="validationJob.success" />
                    </td>
                    <td :rowspan="validationJob.retailersCount + 1"><code>{{ validationJob.log }}</code></td>
                    <td><b>Total</b></td>
                    <td class="text-right"><b>{{ validationJob.validations.length }}</b></td>
                    <td class="text-right text-success"><b>{{ validationJob.counts.approved }}</b></td>
                    <td class="text-right text-warning"><b>{{ validationJob.counts.pending }}</b></td>
                    <td class="text-right text-danger"><b>{{ validationJob.counts.declined }}</b></td>
                    <td class="text-right text-secondary"><b>{{ validationJob.counts.deleted }}</b></td>
                    <td class="text-right"><b>{{ validationJob.counts.undefined }}</b></td>
                    <td class="text-right text-primary"><b>{{ validationJob.counts.withEvent }}</b></td>
                  </tr>
                  <!-- Retailer -->
                  <tr v-for="({ validations, counts, retailer }, retailerId) in validationJob.validationsByRetailer" :key="retailerId">
                    <td class="text-nowrap">
                      <template v-if="retailer">
                        <region-flag :code="retailer.region.code" />
                        <template v-if="retailer.imgSmall.url">
                          <img :src="retailer.imgSmall.url" class="retailer-img-small" />
                        </template>
                        {{ retailer.name }}
                        <span class="badge badge-light">
                          {{ retailer.service }}
                        </span>
                      </template>
                      <template v-else>
                        <span class="text-danger">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa" fill="currentColor"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"/></svg>
                          Missing retailer
                        </span>
                      </template>
                    </td>
                    <td class="text-right"><b>{{ validations.length }}</b></td>
                    <td class="text-right text-success">{{ counts.approved }}</td>
                    <td class="text-right text-warning">{{ counts.pending }}</td>
                    <td class="text-right text-danger">{{ counts.declined }}</td>
                    <td class="text-right text-secondary">{{ counts.deleted }}</td>
                    <td class="text-right">{{ counts.undefined }}</td>
                    <td class="text-right text-primary"><b>{{ counts.withEvent }}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </transition>
</div>
