
<div>
  <transition name="fade" mode="out-in">
    <div v-if="tagLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="form-group row">
          <label
            for="inputKey"
            class="col-sm-2 col-form-label"
          >{{ $t('attributes.tag.key') }}</label>
          <div class="col-lg-4 col-sm-4 col-12">
            <input
              v-model="tag.key"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : tagSubmitError && tagSubmitError.key }"
              id="inputKey"
              :placeholder="$t('attributes.tag.key')"
            />
            <div
              v-if="tagSubmitError && tagSubmitError.key"
              class="invalid-feedback"
            >{{ tErrors('tag', 'key', tagSubmitError.key) }}</div>
          </div>
        </div>

        <!-- Tag language datas -->
        <div class="form-group row" v-for="(languageDataAttributes, index) in tag.tagLanguageDatasAttributes" :key="languageDataAttributes.tagLanguage.id">
          <label :for="`input-name-${languageDataAttributes.tagLanguage.code}`" class="col-sm-2 col-form-label">
            <div class="row">
              <div class="col-6">
                <template v-if="index === 0">
                  {{ $t('attributes.tag.name') }}
                </template>
              </div>
              <div class="col-6">
                <span class="badge badge-light">{{ languageDataAttributes.tagLanguage.code }}</span>
              </div>
            </div>
          </label>
          <div class="col-lg-4 col-sm-4 col-12">
            <input
              v-model="languageDataAttributes.name"
              type="text"
              class="form-control"
              :id="`input-name-${languageDataAttributes.tagLanguage.code}`"
              :placeholder="`${$t('attributes.tag.name')} ${languageDataAttributes.tagLanguage.code}`"
              :class="{ 'is-invalid' : tagSubmitError && tagSubmitError['tagLanguageDatas.name'] }"
            />
              <div v-if="tagSubmitError && tagSubmitError['tagLanguageDatas.name']" class="invalid-feedback">{{ tErrors('tag', 'language_data', tagSubmitError['tagLanguageDatas.name']) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label
            for="multiselect-brands"
            class="col-sm-2 col-form-label"
          >{{ $t('attributes.tag.brands') }}</label>
          <div class="col-lg-4 col-sm-4 col-12">
            <key-multiselect
              v-model="tag.brandIds"
              :options="brands"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :options-limit="10000"
              :loading="brandsLoading"
              id="multiselect-brands"
            >
              <template v-slot:option="{ option }">
                <region-flag :code="option.region.code" />
                {{ option.name }}
              </template>
              <template slot="selection" slot-scope="{ values }">
                <span v-if="values.length">{{ $tc('multiselect.selection', values.length) }}</span>
              </template>
            </key-multiselect>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="tagSubmitLoading">
            <md-spinner
              v-if="tagSubmitLoading"
              md-indeterminate
              :diameter="20"
              :stroke-width="5"
              class="btn-spinner"
            />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
