<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="validationDataSourceLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">

        <form @submit.prevent="submit">

          <div class="form-group row">
            <label for="inputName" class="col-sm-2 col-form-label">{{ $t('attributes.validation_data_source.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="validationDataSource.name" type="text" class="form-control" :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError.name }" id="inputName" :placeholder="$t('attributes.validation_data_source.name')">
              <div v-if="validationDataSourceSubmitError &&  validationDataSourceSubmitError.name" class="invalid-feedback">{{ tErrors('validation_data_source', 'name', validationDataSourceSubmitError.name) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputName" class="col-sm-2 col-form-label">{{ $t('attributes.validation_data_source.script_filename') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="validationDataSource.scriptFilename" type="text" class="form-control" :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError.scriptFilename }" id="inputName" :placeholder="$t('attributes.validation_data_source.script_filename')">
              <div v-if="validationDataSourceSubmitError && validationDataSourceSubmitError.scriptFilename" class="invalid-feedback">{{ tErrors('validation_data_source', 'script_filename', validationDataSourceSubmitError.scriptFilename) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputName" class="col-sm-2 col-form-label">{{ $t('attributes.validation_data_source.retailer_field') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="validationDataSource.retailerField" type="text" class="form-control" :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError.retailerField }" id="inputName" :placeholder="$t('attributes.validation_data_source.retailer_field')">
              <div v-if="validationDataSourceSubmitError && validationDataSourceSubmitError.retailerField" class="invalid-feedback">{{ tErrors('validation_data_source', 'retailer_field', validationDataSourceSubmitError.retailerField) }}</div>
            </div>
          </div>

          <hr>

          <h5 class="mb-3">Retailer mappings</h5>

          <div class="mb-2">
            <div v-if="validationDataSource.validationDataSourceRetailerMappings.length === 0" class="mb-2">
              No retailer values were defined yet.
            </div>
            <div v-else class="mb-2">
              <ul class="list-unstyled">
                <li v-for="(retailerMapping, index) in validationDataSource.validationDataSourceRetailerMappings" :key="index">
                  <div class="form-group row">
                    <div class="col-sm-4">
                      <key-multiselect
                        v-model="retailerMapping.retailerId"
                        :options="retailerOptions"
                        :disabled="retailerMapping._destroy"
                        label="label"
                        track-by="id"
                        :placeholder="$t('shared.placeholders.select')"
                        :show-labels="false"
                        :loading="retailersLoading"
                        :options-limit="20"
                        :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerId'] }"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <region-flag :code="option.region.code" />
                          {{ option.name }}
                          <span class="badge badge-light">{{ option.service }}</span>
                        </template>
                        <template slot="option" slot-scope="{ option }">
                          <region-flag :code="option.region.code" />
                          {{ option.name }}
                          <span class="badge badge-light">{{ option.service }}</span>
                        </template>
                      </key-multiselect>
                      <div v-if="validationDataSourceSubmitError && validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerId']" class="invalid-feedback">{{ tErrors('validation_data_source', 'retailer_id', validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerId']) }}</div>
                    </div>
                    <div class="col-sm-4">
                      <input v-model="retailerMapping.retailerValue" type="text" class="form-control" :class="{ 'is-invalid' : validationDataSourceSubmitError && validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerValue'] }" :placeholder="$t('attributes.validation_data_source.retailer_mapping_retailer_value')" :disabled="retailerMapping._destroy">
                      <div v-if="validationDataSourceSubmitError && validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerValue']" class="invalid-feedback">{{ tErrors('validation_data_source', 'retailer_value', validationDataSourceSubmitError['validationDataSourceRetailerMappings.retailerValue']) }}</div>
                    </div>
                    <div class="">
                      <template v-if="!retailerMapping._destroy">
                        <button @click.prevent="removeRetailerMapping(retailerMapping)" class="btn btn-danger">Remove retailer value</button>
                      </template>
                      <template v-else>
                        <button @click.prevent="restoreRetailerMapping(retailerMapping)" class="btn btn-success">Restore retailer value</button>
                      </template>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <button @click.prevent="addRetailerMapping" class="btn btn-success">Add a retailer value</button>
          </div>

          <hr>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="validationDataSourceSubmitLoading">
              <md-spinner v-if="validationDataSourceSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { MdSpinner, KeyMultiselect, RegionFlag },
  props: ['action'],
  data: function() {
    return {
      validationDataSource: {
        name: null,
        scriptFilename: null,
        retailerField: null,
        validationDataSourceRetailerMappings: []
      },
      validationDataSourceLoading: false,
      validationDataSourceError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      validationDataSourceSubmitLoading: false,
      validationDataSourceSubmitError: null
    }
  },
  computed: {
    retailerOptions: function() {
      return this.retailers.map(retailer => {
        return {
          ...retailer,
          label: [retailer.name, retailer.service, retailer.region.code].join(' - ')
        }
      })
    }
  },
  methods: {
    // Load data
    loadData: async function({ validationDataSource = true } = {}) {
      this.retailersLoading = true
      this.retailersError = null

      if (validationDataSource) {
        this.validationDataSourceError = null
        this.validationDataSourceLoading = true
      }

      const query = gql`
        query validationDataSourceForm ($validationDataSource: Boolean = true, $validationDataSourceId: Int = null){
          validationDataSource(id: $validationDataSourceId) @include(if: $validationDataSource) {
            id
            name
            scriptFilename
            retailerField
            validationDataSourceRetailerMappings {
              id
              retailerId
              retailerValue
            }
          }
          retailers {
            id
            name
            service
            region {
              code
            }
          }
        }
      `

      const variables = {
        validationDataSource,
        validationDataSourceId: validationDataSource ? Number(this.$route.params.id) : undefined
      }

      try {
        const { data } = await client.query({ query, variables })

        this.retailers = Object.freeze(data.retailers)

        if (validationDataSource) {
          this.validationDataSource = cloneDeep(data.validationDataSource)
        }
      } catch (error) {
        this.retailersError = this.validationDataSourceError = error
        throw error
      } finally {
        this.retailersLoading = false
        this.validationDataSourceLoading = false
      }
    },
    // Add a retailer mapping to the form
    addRetailerMapping: function() {
      this.validationDataSource.validationDataSourceRetailerMappings.push({ id: null, retailerId: null, retailerValue: null })
    },
    // Set a retailer mapping for deletion
    removeRetailerMapping: function(retailerMapping) {
      if (retailerMapping.id !== null) {
        this.$set(retailerMapping, '_destroy', true)
      } else {
        const index = this.validationDataSource.validationDataSourceRetailerMappings.indexOf(retailerMapping)
        this.validationDataSource.validationDataSourceRetailerMappings.splice(index, 1)
      }
    },
    // Restore a retailer mapping
    restoreRetailerMapping: function(retailerMapping) {
      this.$set(retailerMapping, '_destroy', false)
    },
    // Form submit
    submit: async function() {
      this.validationDataSourceSubmitLoading = true
      this.validationDataSourceSubmitError = null

      const variables = {
        input: {
          attributes: {
            name: this.validationDataSource.name,
            scriptFilename: this.validationDataSource.scriptFilename,
            retailerField: this.validationDataSource.retailerField,
            validationDataSourceRetailerMappingsAttributes: this.validationDataSource.validationDataSourceRetailerMappings.map(retailerMapping => ({
              id: retailerMapping.id,
              retailerId: retailerMapping.retailerId,
              retailerValue: retailerMapping.retailerValue,
              _destroy: retailerMapping._destroy
            }))
          }
        }
      }

      if (this.action === 'new') {
        const mutation = gql`
          mutation($input: CreateValidationDataSourceInput!) {
            createValidationDataSource(input: $input) {
              validationDataSource {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.createValidationDataSource.errors) {
            this.validationDataSourceSubmitError = data.createValidationDataSource.errors
          } else {
            this.$router.push({ name: 'validation_data_source', params: { id: data.createValidationDataSource.validationDataSource.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.validation_data_source.create'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.validationDataSourceSubmitError = error
          throw error
        } finally {
          this.validationDataSourceSubmitLoading = false
        }
      } else if (this.action === 'edit') {
        variables.input.id = this.$route.params.id

        const mutation = gql`
          mutation($input: UpdateValidationDataSourceInput!) {
            updateValidationDataSource(input: $input) {
              validationDataSource {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables })

          if (data.updateValidationDataSource.errors) {
            this.validationDataSourceSubmitError = data.updateValidationDataSource.errors
          } else {
            this.$router.push({ name: 'validation_data_sources' })
            this.$root.$bvToast.toast(this.$t('shared.success.validation_data_source.update'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        } catch (error) {
          this.validationDataSourceSubmitError = error
          throw error
        } finally {
          this.validationDataSourceSubmitLoading = false
        }
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ validationDataSource: false })
    }
  }
}
</script>
